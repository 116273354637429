import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {

    IAMWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    PaperSpacing: {
        display: 'flex',
        gap: '1vw',
        flexDirection: 'column',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
    },
    ProcessSelect: {
        width: '20vw !important',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        height: 'auto',
        padding: '5px',
    },

}});

export default useStyles;
