import React from 'react';
import { Typography, Box, FormControlLabel, TextField, Button } from '@mui/material';
import { ProductNameId } from '../..';
import { useForm } from '@tanstack/react-form';
import SearchIcon from '@mui/icons-material/Search';
import { ThemeProvider } from '@emotion/react';
import ProductsSearchBoxTheme from './ProductsSearchBoxTheme';
// import { Button } from '@mui/base/Button';

interface ProductsSearchBoxProps {
        products: ProductNameId[],
        filteredProducts: ProductNameId[],
        setFilteredProducts: React.Dispatch<React.SetStateAction<ProductNameId[]>>
}

interface ProductsSearchBoxForm {
        searchQuery: string
}
export default function ProductsSearchBox({ products, filteredProducts, setFilteredProducts }: ProductsSearchBoxProps) {

    const form = useForm<ProductsSearchBoxForm>({
        defaultValues: {
            searchQuery: '',
        },
        onSubmit: ({ value }) => { // filter by the search query, and sort by where the search query is found inside the name
            setFilteredProducts(products.filter((product)  => product.productName.toLowerCase()
                .includes(value.searchQuery.toLowerCase())).sort((a,b) =>a.productName.toLowerCase().indexOf(value.searchQuery.toLowerCase()) - 
            b.productName.toLowerCase().indexOf(value.searchQuery.toLowerCase())  ))
        },
    });
    return <>
        <ThemeProvider theme={ProductsSearchBoxTheme}>
            <form.Field
                name="searchQuery"
                children={(field) => {
                    return (
                        <FormControlLabel
                            control={
                                <TextField
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        form.handleSubmit();
                                    }}
                                    // onChange={(e) => {
                                    //     const value = Number(e.target.value);
                                    //     console.log('New xFontSize:', value);
                                    //     field.handleChange(value);
                                    // }}
                                    type="text"
                                    size="small"
                                    // sx={{
                                    //     width: '11rem',
                                    //     '& .MuiInputBase-input': {
                                    //         width: '11rem',
                                    //     },
                                    // }}
                                    sx={{
                                        padding: '0.2vw !important',
                                    }}
                                />
                            }
                            sx={{
                                height: '1.4vw',

                            }}
                            label={
                                <>
                                    <Button
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-around',
                                        }}
                                    >
                                        <SearchIcon /> Search
                                    </Button>
                                </>
                            }
                        />
                    );
                }}
            />
        </ThemeProvider>
    </>
};