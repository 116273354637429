/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@emotion/react';
import Dropdown from '../../../../../dropdowns/Dropdown';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult, PSILCASubcategoryResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import useStyles from './A4BarPSILCA.styles';
import { applyValueType } from '../../../../options/util/applyOptions';
import { palette1, palette2, palette3, palette4, processColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import GraphType from '../../../../options/components/GraphTypeOptions';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function A4PiePSILCA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [results, setResults] = useState<PSILCAResult[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-PSILCA' },
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults((calcResults));
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": processColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 
        0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        
        let processes: string[] = [];
        const matchingLCS = results[0]?.subcategories[0]?.indicators[0]
            ?.indicatorResults.find((item) => { return item.lcsName === `${selectedLCS}:${selectedLCSS}` });
        if (matchingLCS) {
            processes = matchingLCS.processResults.processes.map((item) => { return item.process });
        }

        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        // const totalProcesses = processes.length;
        const extendedPalette = generateExtendedPalette(selectedPalette);
        const datasets = processes.map((process, index) => {
            const colorIndex = getGraphColorIndex({
                label: process,
            }, extendedPalette);
            return {
                label: process,
                data: [
                    Math.round(
                        results
                            .reduce((subcategories: PSILCASubcategoryResult[], stakeholder) => {
                                return subcategories.concat(
                                    stakeholder.subcategories,
                                )
                            }, [])
                            .map((subcategory) => {
                                const val = subcategory.indicators
                                    .map((indicator) => {
                                        return indicator?.indicatorResults?.find(
                                            (lcs) => { return lcs.lcsName === `${selectedLCS}:${selectedLCSS}` },
                                        )?.processResults.processes.find(
                                            (result) => { return result.process === process },
                                        )?.processTotalSum || 0
                                    })
                                    .reduce((a, b) => { return a + b }, 0);

                                return val;
                            })
                            .reduce((a, b) => { return a + b }, 0) * 100,
                    ) / 100,
                ],
                backgroundColor: extendedPalette[colorIndex],
            };
        });

        chartData.datasets = [
            {
                data: datasets.map(dataset => dataset.data[0]),
                backgroundColor: datasets.map(dataset => dataset.backgroundColor),
                label: 'Cost Per Process',
            },
        ];

        chartData.labels = datasets.map(dataset => dataset.label);
        console.log(chartData);

        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedLCS, selectedLCSS, selectedValueType, formState.colorPalette, getGraphColorIndex, results]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options,false,'pie');
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        console.log(options);
        CreateChartTS(chartData, options, 'pie');
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={0} defaultResultIndex={3} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            type='pie'
                                            dimension='PSILCA'
                                            chart='A4'
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-right'
                                    >

                                        <ChartDownload chartData = {chartJSData} chartName={'Total weighted result: all stakeholders - Process'} chartType='Pie Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='process'
                                            graphType='pie'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A4PiePSILCA;
