import { ChartJSData, ChartJSOptions } from '../../../interface/ChartData';
import ChartAuto, { ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartAuto.register(ChartDataLabels);

interface CreateChartData {
  type: keyof ChartTypeRegistry;
  data: ChartJSData;
  options: Chart.ChartOptions;
  plugins:[]
}

const customCanvasBackgroundColor = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || '#99ffff';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    },
};
export const CreateChartTS = (
    chartJSData: ChartJSData ,
    chartOptions: ChartJSOptions | undefined,
    type: keyof ChartTypeRegistry = 'bar',
    canvasName? : string,
) => {
    let myChart: ChartAuto;
    const oldChart: ChartAuto | undefined = ChartAuto.getChart(canvasName || 'graph1Canvas');
    if (oldChart) {
        oldChart.destroy();
    }

    if(type === 'pie' && chartOptions){
        chartOptions.interaction = {};
    }

    const ctx = document.getElementById(canvasName || 'graph1Canvas') as HTMLCanvasElement;
    if ((ctx != null) && chartOptions) {
        const createChartData: CreateChartData = {
            type,
            data: {
                labels: chartJSData.labels,
                datasets: chartJSData.datasets,
            },
            options: chartOptions as Chart.ChartOptions,
            plugins:[customCanvasBackgroundColor],

        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        myChart = new ChartAuto(ctx, createChartData);
    }
};

