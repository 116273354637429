// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sdg-table img{
    width: 5vw;
}
div#SDGTable {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/components/charts/sdgtable/SDGTable.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".sdg-table img{\r\n    width: 5vw;\r\n}\r\ndiv#SDGTable {\r\n    width: -webkit-fill-available;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 1vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
