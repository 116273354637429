import { FormState } from "../components/forms/FormContext";
import { SelectFlow } from "../interface/Flow";
import { ProductDetailsData } from "../interface/Form";
import { SelectMethod } from "../interface/ImpactMethod";
import { Exchange, Flow, Process, SelectProcess } from "../interface/Process";
import { Product } from "../interface/Product";
import { SDG } from "../interface/SDG";
import { SocialStakeholder } from "../interface/Stakeholder";
import { UnitGroup } from "../interface/Unit";
import { getAccessTokenFromSessionStorage } from "./StorageHelper";

export const sendRequest = async <T>(
    url: string,
    method = "GET",
    body = "",
    headers = {}
) => {
    try {
        const response = await fetch(url, {
            method,
            body: method !== "GET" ? body : null,
            headers,
        });

        const responseData = (await response.json()) as T;

        if (!response.ok) {
            // throw new Error(responseData.message);
        }

        return responseData;
    } catch (err: any) {
    // Handle error as needed
        console.log(err);
        // Rethrow error if necessary
        throw err;
    }
};
interface ProductDeleteResponse {
  message: string;
}
export const requestProductDelete = async (
    productId: string
): Promise<ProductDeleteResponse> => {
    const token = getAccessTokenFromSessionStorage();

    const responseData = await sendRequest<ProductDeleteResponse>(
        `${process.env.REACT_APP_BACKEND_URL}/product/${productId}`,
        "DELETE",
        undefined,
        { Authorization: "Bearer " + token }
    );
    return responseData;
};

export const fetchUnitGroups = async (): Promise<UnitGroup[]> => {
    const token = getAccessTokenFromSessionStorage();

    const responseData = await sendRequest<UnitGroup[]>(
        `${process.env.REACT_APP_BACKEND_URL}/product/unit-groups/`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return responseData;
};

interface ProductResponse {
  product: Product;
}

export const fetchProduct = async (productId: string): Promise<Product> => {
    const token = getAccessTokenFromSessionStorage();

    const response: ProductResponse = await sendRequest<ProductResponse>(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/product/${productId}`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.product;
};

interface FetchProcessResponse {
  processes: SelectProcess[];
  entries: number;
}
interface FetchFlowResponse {
  processes: SelectFlow[];
  entries: number;
}

export const fetchEnvProcesses = async (): Promise<SelectProcess[]> => {
    const token = getAccessTokenFromSessionStorage();

    const response: FetchProcessResponse =
    await sendRequest<FetchProcessResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/processes/`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.processes;
};

interface FetchEnvProcessByPageAndCategoryData {
  page: number;
  key: string;
  category: number;
}
interface FetchByPageData {
  page: number;
  key: string;
}

export const fetchEnvProcessesByPageAndCategory = async (
    page: number,
    key: string,
    category: number
): Promise<FetchProcessResponse> => {
    const token = getAccessTokenFromSessionStorage();
    const requestData: FetchEnvProcessByPageAndCategoryData = {
        category,
        page,
        key,
    };

    const response: FetchProcessResponse =
    await sendRequest<FetchProcessResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/processes/page/category`,
        "POST",
        JSON.stringify(requestData),
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response;
};

export const fetchEnvProcessByPage = async (
    page: number,
    key: string
): Promise<FetchProcessResponse> => {
    const token = getAccessTokenFromSessionStorage();
    const requestData: FetchByPageData = {
        page,
        key,
    };
    const response: FetchProcessResponse =
    await sendRequest<FetchProcessResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/processes/page`,
        "POST",
        JSON.stringify(requestData),
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response;
};

export const fetchEnvFlowsByPageAndCategory = async (
    page: number,
    key: string,
    category: number
): Promise<FetchFlowResponse> => {
    const token = getAccessTokenFromSessionStorage();
    const requestData: FetchEnvProcessByPageAndCategoryData = {
        category,
        page,
        key,
    };

    const response: FetchFlowResponse = await sendRequest<FetchFlowResponse>(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/flows/page/category`,
        "POST",
        JSON.stringify(requestData),
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response;
};

export const fetchEnvFlowByPage = async (
    page: number,
    key: string,
    flowType: string
): Promise<FetchFlowResponse> => {
    const token = getAccessTokenFromSessionStorage();
    const requestData: FetchByPageData = {
        page,
        key,
    };
    const response: FetchFlowResponse = await sendRequest<FetchFlowResponse>(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/flows/${flowType}/page`,
        "POST",
        JSON.stringify(requestData),
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response;
};

export const fetchSocialProcesses = async (): Promise<SelectProcess[]> => {
    const token = getAccessTokenFromSessionStorage();

    const response: FetchProcessResponse =
    await sendRequest<FetchProcessResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/social/processes/`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.processes;
};

interface FetchFlowResponse {
  flows: SelectFlow[];
}

export const fetchFlows = async (): Promise<SelectFlow[]> => {
    const token = getAccessTokenFromSessionStorage();

    const response: FetchFlowResponse = await sendRequest<FetchFlowResponse>(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/flows/`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.flows;
};

interface FetchMethodResponse {
  methods: SelectMethod[];
}

export const fetchEnvMethods = async (): Promise<SelectMethod[]> => {
    const token = getAccessTokenFromSessionStorage();

    const response: FetchMethodResponse = await sendRequest<FetchMethodResponse>(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/methods/`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.methods;
};

export const fetchSocialMethods = async (): Promise<SelectMethod[]> => {
    const token = getAccessTokenFromSessionStorage();

    const response: FetchMethodResponse = await sendRequest<FetchMethodResponse>(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/social/methods/`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.methods;
};

interface CategoryFetchResponse {
  categories: string[];
}

export const fetchEnvProcessCategories = async (): Promise<string[]> => {
    const token = getAccessTokenFromSessionStorage();
    const response: CategoryFetchResponse =
    await sendRequest<CategoryFetchResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/processes/categories`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.categories;
};
export const fetchEnvFlowCategories = async (
    flowType: string
): Promise<string[]> => {
    const token = getAccessTokenFromSessionStorage();
    const response: CategoryFetchResponse =
    await sendRequest<CategoryFetchResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/environmental/flows/${flowType}/categories`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.categories;
};

export const fetchSocialProcessCategories = async (): Promise<string[]> => {
    const token = getAccessTokenFromSessionStorage();
    const response: CategoryFetchResponse =
    await sendRequest<CategoryFetchResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/social/processes/categories`,
        "GET",
        undefined,
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response.categories;
};

export const fetchSocialProcessesByPageAndCategory = async (
    page: number,
    key: string,
    category: number
): Promise<FetchProcessResponse> => {
    const token = getAccessTokenFromSessionStorage();
    const requestData: FetchEnvProcessByPageAndCategoryData = {
        category,
        page,
        key,
    };

    const response: FetchProcessResponse =
    await sendRequest<FetchProcessResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/social/processes/page/category`,
        "POST",
        JSON.stringify(requestData),
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response;
};

export const fetchSocialProcessByPage = async (
    page: number,
    key: string
): Promise<FetchProcessResponse> => {
    const token = getAccessTokenFromSessionStorage();
    const requestData: FetchByPageData = {
        page,
        key,
    };
    const response: FetchProcessResponse =
    await sendRequest<FetchProcessResponse>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_BACKEND_URL}/social/processes/page`,
        "POST",
        JSON.stringify(requestData),
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return response;
};

interface FetchSDGResponse {
  sdgs: SDG[];
}

export const fetchEnvSDGs = async () => {
    const token = getAccessTokenFromSessionStorage();

    const responseData: FetchSDGResponse = await sendRequest<FetchSDGResponse>(
        `${process.env.REACT_APP_BACKEND_URL}/environmental/sdg`,
        "GET",
        undefined,
        { Authorization: "Bearer " + token }
    );
    return responseData.sdgs;
};
export const fetchSocialSDGs = async () => {
    const token = getAccessTokenFromSessionStorage();

    const responseData: FetchSDGResponse = await sendRequest<FetchSDGResponse>(
        `${process.env.REACT_APP_BACKEND_URL}/social/sdg`,
        "GET",
        undefined,
        { Authorization: "Bearer " + token }
    );
    console.log(responseData);
    return responseData.sdgs;
};



export const fetchProcessFlowDetails = async (pid : string,dimension:string) => {
    const token = getAccessTokenFromSessionStorage();
    const responseData: Process = await sendRequest<Process>(
        `${process.env.REACT_APP_BACKEND_URL}/${dimension.toLowerCase()}/processes/${pid}/details`,
        "GET",
        undefined,
        { Authorization: "Bearer " + token }
    );
    return responseData;
};
export const fetchFlowDetails = async (fid : string,dimension:string) => {
    const token = getAccessTokenFromSessionStorage();
    const responseData: Flow = await sendRequest<Flow>(
        `${process.env.REACT_APP_BACKEND_URL}/${dimension.toLowerCase()}/flows/${fid}`,
        "GET",
        undefined,
        { Authorization: "Bearer " + token }
    );
    return responseData;
};

interface FetchSocialStakeholdersResponse{
    stakeholders:SocialStakeholder[],
}
export const fetchSocialStakeholders = async () : Promise<SocialStakeholder[]> => {
    const token = getAccessTokenFromSessionStorage();
    const responseData: FetchSocialStakeholdersResponse = await sendRequest<FetchSocialStakeholdersResponse>(
        `${process.env.REACT_APP_BACKEND_URL}/social/stakeholders`,
        "GET",
        undefined,
        { Authorization: "Bearer " + token }
    );
    return responseData.stakeholders;
};

