import React from 'react';
import {
    AppBar, Box, Toolbar, Typography, IconButton, Button,
} from '@mui/material';
import useStyles from './Navbar.style';
import logo from '../../assets/tossa.png';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ProductTitle from '../charts/options/components/ProductTitle';
import { setAccessTokenInSessionStorage } from '../../helper/StorageHelper';
import { useNavigate } from '@tanstack/react-router';
import { Product } from '../../interface/Product';

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void,
    product?: Product;
}

function Navbar(props: Props) {
    const classes = useStyles();
    const { open, setOpen, product } = props;

    const navigate = useNavigate();

    const handleChange = () => {
        setOpen(!open);
    };

    return (
        <AppBar className={classes.Header}>
            <Toolbar className={classes.toolbar}>
                <Box className={classes.headingAndLogo}>
                    <IconButton className={classes.menuIconBtn} onClick={handleChange}>
                        {open ? <MenuOpenOutlinedIcon /> : <MenuOutlinedIcon />}
                    </IconButton>
                    {/* <img src={logo} alt="Tossa Logo" /> */}
                </Box>
                {/* <ProductTitle /> */}
                {/* {product ? (
                    <Typography component="h2" variant="h2" className="product-header">
                        {product.productName}
                    </Typography>
                ) : (
                    ''
                )} */}
                <Button
                    type='button'
                    onClick={() => {
                        setAccessTokenInSessionStorage('');
                        navigate({ to: '/login' })
                            .then((val) => { })
                            .catch((err) => { });
                    }}
                    sx={{ 
                        color: 'white',
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: '0.8vw',
                    }}
                >
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
