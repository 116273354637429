import React from "react";

import "./LoadingSpinner.css";

interface LoadingSpinnerProps{
  key?:string,
  asOverlay?:boolean,
  small?:boolean,

}
const LoadingSpinner = (props : LoadingSpinnerProps) => {
    return (
        <div
            style={{ display: "flex", justifyContent: "center" }}
            key={props.key}
            className={`${props.asOverlay && "loading-spinner__overlay"}`}
        >
            <div
                className={`lds-dual-ring ${props.small && "lds-dual-ring__small"}`}
            ></div>
        </div>
    );
};

export default LoadingSpinner;
