import { makeStyles } from '@mui/styles';
import { tableCellClasses } from '@mui/material/TableCell';

const useStyles = makeStyles(() => ({
    IAMWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    ProcessTopWrapper: {
        display: 'grid',
        width: '100%',  // Changed from -webkit-fill-available to 100%
        gridTemplateColumns: '1fr auto',
        justifyItems: 'center',
    },
    InputTableCell: {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004021',
            color: 'white',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        width: '20rem',
        '&:last-of-type': {
            width: '5rem',
        },
    },
    InputTableRow: {
        padding: '5px',
        fontSize: '12px',
        color: '#707070',
        '&:nth-of-type(odd)': {
            backgroundColor: '#F3F3F3',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#E6E6E6',
        },
    },
    ProcessBottomWrapper: {
        display: 'flex',
        columnGap: '1vw',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '1rem',
    },
    TableContainerWrap: {
        maxHeight: '40vw',
        maxWidth: '90vw',
    },
}));

export default useStyles;
