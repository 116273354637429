import { createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';

const GraphLink = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                    color: 'black',
                    textDecoration: 'underline',
                    fontSize: '0.7vw',
                    fontWeight: 'bold',
                    padding: 0,
                    // '@media (max-width: 1680px)': {
                    //     fontSize: '14px',
                    // },
                },
            },
        },
    },

});

export default GraphLink;
