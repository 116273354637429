/* eslint-disable @typescript-eslint/no-throw-literal */
import React from 'react';
import { getAccessTokenFromSessionStorage } from '../helper/StorageHelper';
import {
    createRoute,
    redirect,
} from '@tanstack/react-router';
import { generateIndexRoute } from './CreateRoute';

import ProductLayout from '../screens/Product';
import { Product } from '../interface/Product';
import { sendRequest } from '../helper/fetchUtil';
import D4EnvSDGTable from '../components/charts/environmental/MidpointResult/D4EnvSDGTable'
import D2BarChart from '../components/charts/environmental/MidpointResult/D2BarChart';
import D3BarChart from '../components/charts/environmental/MidpointResult/D3BarChart';
import D4BarChart from '../components/charts/environmental/MidpointResult/D4BarChart';
import E1BarChart from '../components/charts/environmental/InventoryResult/E1BarChart';
import E2BarChart from '../components/charts/environmental/InventoryResult/E2BarChart';
import E3BarChart from '../components/charts/environmental/InventoryResult/E3BarChart';
import E4BarChart from '../components/charts/environmental/InventoryResult/E4BarChart';
import B1BarChart from '../components/charts/environmental/WeightedSingleIndex/Normalized/B1BarChart';
import B2BarChart from '../components/charts/environmental/WeightedSingleIndex/Normalized/B2BarChart';
import B3BarChart from '../components/charts/environmental/WeightedSingleIndex/Normalized/B3BarChart';
import B4BarChart from '../components/charts/environmental/WeightedSingleIndex/Normalized/B4BarChart';
import C2BarChart from '../components/charts/environmental/NormalizedResult/C2BarChart';
import C3BarChart from '../components/charts/environmental/NormalizedResult/C3BarChart';
import C4BarChart from '../components/charts/environmental/NormalizedResult/C4BarChart';
import C1BarChart from '../components/charts/environmental/NormalizedResult/C1BarChart';
import A1BarChart from '../components/charts/environmental/WeightedSingleIndex/DamageType/A1BarChart';
import A2BarPSILCA from '../components/charts/social/psilca/allstakeholders/A2BarPSILCA';
import A3BarPSILCA from '../components/charts/social/psilca/allstakeholders/A3BarPSILCA';
import A4BarPSILCA from '../components/charts/social/psilca/allstakeholders/A4BarPSILCA';
import A4PiePSILCA from '../components/charts/social/psilca/allstakeholders/A4PiePSILCA';
import A4StackedPSILCA from '../components/charts/social/psilca/allstakeholders/A4StackedPSILCA';
import D1BarPSILCA from '../components/charts/social/psilca/indicator/D1BarPSILCA';
import D2BarPSILCA from '../components/charts/social/psilca/indicator/D2BarPSILCA';
import D3BarPSILCA from '../components/charts/social/psilca/indicator/D3BarPSILCA';
import D4BarPSILCA from '../components/charts/social/psilca/indicator/D4BarPSILCA';
import B1PiePSILCA from '../components/charts/social/psilca/stakeholder/B1PiePSILCA';
import B1TreePSILCA from '../components/charts/social/psilca/stakeholder/B1TreePSILCA';
import B2BarPSILCA from '../components/charts/social/psilca/stakeholder/B2BarPSILCA';
import B3BarPSILCA from '../components/charts/social/psilca/stakeholder/B3BarPSILCA';
import B4PSILCACharts from '../components/charts/social/psilca/stakeholder/B4PSILCACharts';
import C1BarPSILCA from '../components/charts/social/psilca/subcategory/C1BarPSILCA';
import C2BarPSILCA from '../components/charts/social/psilca/subcategory/C2BarPSILCA';
import C3BarPSILCA from '../components/charts/social/psilca/subcategory/C3BarPSILCA';
import C4BarPSILCA from '../components/charts/social/psilca/subcategory/C4BarPSILCA';
import A1BarPSILCA from '../components/charts/social/psilca/allstakeholders/A1BarPSILCA';
import A2BarChart from '../components/charts/environmental/WeightedSingleIndex/DamageType/A2BarChart';
import A3BarChart from '../components/charts/environmental/WeightedSingleIndex/DamageType/A3BarChart';
import A4BarChart from '../components/charts/environmental/WeightedSingleIndex/DamageType/A4BarChart';
import D1BarChart from '../components/charts/environmental/MidpointResult/D1BarChart';
import A1BarEco from '../components/charts/economic/TotalCost/A1BarEco';
import A2BarEco from '../components/charts/economic/TotalCost/A2BarEco';
import A3BarEco from '../components/charts/economic/TotalCost/A3BarEco';
import A4BarEco from '../components/charts/economic/TotalCost/A4BarEco';
import A2PieEco from '../components/charts/economic/TotalCost/A2PieEco';
import A3PieEco from '../components/charts/economic/TotalCost/A3PieEco';
import A2StackedEco from '../components/charts/economic/TotalCost/A2StackedEco';
import A3StackedEco from '../components/charts/economic/TotalCost/A3StackedEco';
import A4PieEco from '../components/charts/economic/TotalCost/A4PieEco';
import C4EnvSDGTable from '../components/charts/environmental/NormalizedResult/C4EnvSDGTable';
import D1EnvSDGTable from '../components/charts/environmental/MidpointResult/D1EnvSDGTable';
import C1EnvSDGTable from '../components/charts/environmental/NormalizedResult/C1EnvSDGTable';
import A1BarSPA from '../components/charts/social/spa/allstakeholders/A1BarSPA';
import A2BarSPA from '../components/charts/social/spa/allstakeholders/A2BarSPA';
import A3BarSPA from '../components/charts/social/spa/allstakeholders/A3BarSPA';
import A4StackedBarSPA from '../components/charts/social/spa/allstakeholders/A4StackedBarSPA';
import A4BarSPA from '../components/charts/social/spa/allstakeholders/A4BarSPA';
import A4PieSPA from '../components/charts/social/spa/allstakeholders/A4PieSPA';
import B1TreeSPA from '../components/charts/social/spa/stakeholder/B1TreeSPA';
import B2BarSPA from '../components/charts/social/spa/stakeholder/B2BarSPA';
import B1PieSPA from '../components/charts/social/spa/stakeholder/B1PieSPA';
import B4SPACharts from '../components/charts/social/spa/stakeholder/B4SPACharts';
import B3BarSPA from '../components/charts/social/spa/stakeholder/B3BarSPA';
import C1BarSPA from '../components/charts/social/spa/subcategory/C1BarSPA';
import C1StackedBarSPA from '../components/charts/social/spa/subcategory/C1StackedBarSPA';
import C2BarSPA from '../components/charts/social/spa/subcategory/C2BarSPA';
import C3BarSPA from '../components/charts/social/spa/subcategory/C3BarSPA';
import C4SPACharts from '../components/charts/social/spa/subcategory/C4SPACharts';
import C4Bar1SPA from '../components/charts/social/spa/subcategory/C4Bar1SPA';
import C4RadarSPA from '../components/charts/social/spa/subcategory/C4RadarSPA';
import C4SunburstSPA from '../components/charts/social/spa/subcategory/C4SunburstSPA';
import C1SDGBarSPA from '../components/charts/social/spa/subcategory/C1SDGBarSPA';
import C1SDGStackedBarSPA from '../components/charts/social/spa/subcategory/C1SDGStackedBarSPA';
import C4SDGSunburstSPA from '../components/charts/social/spa/subcategory/C4SDGSunburstSPA';
import C4SPASDGTable from '../components/charts/social/spa/subcategory/C4SPASDGTable';

interface ProductResponse {
    product: Product
}

function productRoutes() {
    /*
        For every new route we have to create the new index route (dashboardIndexRoute) and
        route component (dashboardRoute). and pass it to routes array as dashboard route is configured
        Ref: https://tanstack.com/router/latest/docs/framework/react/guide/code-based-routing
    */

    let product: Product;
    const productIndexRoute = generateIndexRoute('product');

    const fetchProduct = async (productId: string) => {
        const response: ProductResponse = await sendRequest<ProductResponse>(
            `${process.env.REACT_APP_BACKEND_URL}/product/${productId}`,
            'GET',
            undefined,
            { 'Content-Type': 'application/json' },
        );
        product = response.product;
    };

    const productRoute = createRoute({
        beforeLoad: async ({ params }) => {
            if (getAccessTokenFromSessionStorage() === null) {
                throw redirect({
                    to: '/login',
                });
            }
            await fetchProduct(params.productId);
        },
        loader: (context) => { return context },
        getParentRoute: () => { return productIndexRoute },
        path: '/$productId',
        component: (context) => { return <ProductLayout product={product} /> },
    });

    const D1BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/D1/Bar',
        component: () => { return <D1BarChart product={product} /> },
    });
    const D1EnvSDGTableRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/D1/SDG/Table',
        component: () => { return <D1EnvSDGTable product={product} /> },
    });
    const D2BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/D2/Bar',
        component: () => { return <D2BarChart product={product} /> },
    });
    const D3BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/D3/Bar',
        component: () => { return <D3BarChart product={product} /> },
    });
    const D4BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/D4/Bar',
        component: () => { return <D4BarChart product={product} /> },
    });
    const E1BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/E1/Bar',
        component: () => { return <E1BarChart product={product} /> },
    });
    const E2BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/E2/Bar',
        component: () => { return <E2BarChart product={product} /> },
    });
    const E3BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/E3/Bar',
        component: () => { return <E3BarChart product={product} /> },
    });
    const E4BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/E4/Bar',
        component: () => { return <E4BarChart product={product} /> },
    });
    const B1BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/B1/Bar',
        component: () => { return <B1BarChart product={product} /> },
    });
    const B2BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/B2/Bar',
        component: () => { return <B2BarChart product={product} /> },
    });
    const B3BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/B3/Bar',
        component: () => { return <B3BarChart product={product} /> },
    });
    const B4BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/B4/Bar',
        component: () => { return <B4BarChart product={product} /> },
    });
    const A1BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/A1/Bar',
        component: () => { return <A1BarChart product={product} /> },
    });
    const A2BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/A2/Bar',
        component: () => { return <A2BarChart product={product} /> },
    });
    const A3BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/A3/Bar',
        component: () => { return <A3BarChart product={product} /> },
    });
    const A4BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/A4/Bar',
        component: () => { return <A4BarChart product={product} /> },
    });
    const C1BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/C1/Bar',
        component: () => { return <C1BarChart product={product} /> },
    });
    const C1EnvSDGTableRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/C1/SDG/Table',
        component: () => { return <C1EnvSDGTable product={product} /> },
    });
    const C2BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/C2/Bar',
        component: () => { return <C2BarChart product={product} /> },
    });
    const C3BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/C3/Bar',
        component: () => { return <C3BarChart product={product} /> },
    });
    const C4BarChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/C4/Bar',
        component: () => { return <C4BarChart product={product} /> },
    });

    const A1BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/A1/Bar',
        component: () => { return <A1BarPSILCA product={product} /> },
    });
    const A2BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/A2/Bar',
        component: () => { return <A2BarPSILCA product={product} /> },
    });
    const A3BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/A3/Bar',
        component: () => { return <A3BarPSILCA product={product} /> },
    });
    const A4BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/A4/Bar',
        component: () => { return <A4BarPSILCA product={product} /> },
    });
    const A4StackedPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/A4/StackedBar',
        component: () => { return <A4StackedPSILCA product={product} /> },
    });
    const A4PiePSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/A4/Pie',
        component: () => { return <A4PiePSILCA product={product} /> },
    });

    const B1TreePSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/B1/Treemap',
        component: () => { return <B1TreePSILCA product={product} /> },
    });
    const B1PiePSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/B1/Pie',
        component: () => { return <B1PiePSILCA product={product} /> },
    });
    const B2BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/B2/Bar',
        component: () => { return <B2BarPSILCA product={product} /> },
    });
    const B3BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/B3/Bar',
        component: () => { return <B3BarPSILCA product={product} /> },
    });
    const B4PSILCAChartsRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/B4/Charts',
        component: () => { return <B4PSILCACharts product={product} /> },
    });
    const C1BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/C1/Bar',
        component: () => { return <C1BarPSILCA product={product} /> },
    });
    const C2BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/C2/Bar',
        component: () => { return <C2BarPSILCA product={product} /> },
    });
    const C3BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/C3/Bar',
        component: () => { return <C3BarPSILCA product={product} /> },
    });
    const C4BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/C4/Bar',
        component: () => { return <C4BarPSILCA product={product} /> },
    });
    const D1BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/D1/Bar',
        component: () => { return <D1BarPSILCA product={product} /> },
    });
    const D2BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/D2/Bar',
        component: () => { return <D2BarPSILCA product={product} /> },
    });
    const D3BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/D3/Bar',
        component: () => { return <D3BarPSILCA product={product} /> },
    });
    const D4BarPSILCARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/PSILCA/D4/Bar',
        component: () => { return <D4BarPSILCA product={product} /> },
    });

    const A1BarEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A1/Bar',
        component: () => { return <A1BarEco product={product} /> },
    });
    const A2BarEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A2/Bar',
        component: () => { return <A2BarEco product={product} /> },
    });
    const A2PieEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A2/Pie',
        component: () => { return <A2PieEco product={product} /> },
    });
    const A2StackedEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A2/StackedBar',
        component: () => { return <A2StackedEco product={product} /> },
    });
    const A3BarEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A3/Bar',
        component: () => { return <A3BarEco product={product} /> },
    });
    const A3PieEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A3/Pie',
        component: () => { return <A3PieEco product={product} /> },
    });
    const A3StackedEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A3/StackedBar',
        component: () => { return <A3StackedEco product={product} /> },
    });
    const A4BarEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A4/Bar',
        component: () => { return <A4BarEco product={product} /> },
    });
    const A4PieEcoRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Economic/A4/Pie',
        component: () => { return <A4PieEco product={product} /> },
    });
    const C4SDGLinkChartRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/C4/SDG/Table',
        component: () => { return <C4EnvSDGTable product={product} /> },
    });
    const D4EnvSDGTableRoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Environmental/D4/SDG/Table',
        component: () => { return <D4EnvSDGTable product={product} /> },
    });

    const A1BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/A1/Bar',
        component: () => { return <A1BarSPA product={product} /> },
    });

    const A2BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/A2/Bar',
        component: () => { return <A2BarSPA product={product} /> },
    });
    const A3BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/A3/Bar',
        component: () => { return <A3BarSPA product={product} /> },
    });
    const A4BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/A4/Bar',
        component: () => { return <A4BarSPA product={product} /> },
    });  const A4StackedBarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/A4/StackedBar',
        component: () => { return <A4StackedBarSPA product={product} /> },
    });  const A4PieSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/A4/Pie',
        component: () => { return <A4PieSPA product={product} /> },
    });

    const B1TreeSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/B1/Treemap',
        component: () => { return <B1TreeSPA product={product} /> },
    });
    const B1PieSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/B1/Pie',
        component: () => { return <B1PieSPA product={product} /> },
    });
    const B2BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/B2/Bar',
        component: () => { return <B2BarSPA product={product} /> },
    });
    const B3BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/B3/Bar',
        component: () => { return <B3BarSPA product={product} /> },
    });
  

    const B4ChartsSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/B4/Charts',
        component: () => { return <B4SPACharts product={product} /> },
    });
    const C1BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C1/Bar',
        component: () => { return <C1BarSPA product={product} /> },
    });
    const C1SDGBarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C1/SDG/Bar',
        component: () => { return <C1SDGBarSPA product={product} /> },
    });
    const C1SDGStackedBarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C1/SDG/Stacked',
        component: () => { return <C1SDGStackedBarSPA product={product} /> },
    });
    const C1StackedBarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C1/Stacked',
        component: () => { return <C1StackedBarSPA product={product} /> },
    });
    
    const C2BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C2/Bar',
        component: () => { return <C2BarSPA product={product} /> },
    });
    const C3BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C3/Bar',
        component: () => { return <C3BarSPA product={product} /> },
    });
    const C4ChartsSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C4/',
        component: () => { return <C4SPACharts product={product} /> },
    });
    const C4BarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return C4ChartsSPARoute },
        path: '/Bar',
        component: () => { return <C4Bar1SPA product={product} /> },
    });
    const C4RadarSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return C4ChartsSPARoute },
        path: '/Radar',
        component: () => { return <C4RadarSPA product={product} /> },
    });
    const C4SunburstSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return C4ChartsSPARoute },
        path: '/Sunburst',
        component: () => { return <C4SunburstSPA product={product} /> },
    });
    const C4SDGSunburstSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C4/SDG/Sunburst',
        component: () => { return <C4SDGSunburstSPA product={product} /> },
    });
    const C4SDGTableSPARoute = createRoute({
        beforeLoad: ({ context }) => { return context },
        loader: (val) => { return val },
        getParentRoute: () => { return productRoute },
        path: '/Social/SPA/C4/SDG/Table',
        component: () => { return <C4SPASDGTable product={product} /> },
    });
    C4ChartsSPARoute.addChildren([C4BarSPARoute,C4RadarSPARoute,C4SunburstSPARoute]);
    
    productRoute.addChildren([
        D1BarChartRoute,
        D1EnvSDGTableRoute,
        D4EnvSDGTableRoute,
        D2BarChartRoute,
        D3BarChartRoute,
        D4BarChartRoute,
        E1BarChartRoute,
        E2BarChartRoute,
        E3BarChartRoute,
        E4BarChartRoute,
        B1BarChartRoute,
        B2BarChartRoute,
        B3BarChartRoute,
        B4BarChartRoute,
        A1BarChartRoute,
        A2BarChartRoute,
        A3BarChartRoute,
        A4BarChartRoute,
        C1BarChartRoute,
        C1EnvSDGTableRoute,
        C2BarChartRoute,
        C3BarChartRoute,
        C4BarChartRoute,
        A1BarPSILCARoute,
        A2BarPSILCARoute,
        A3BarPSILCARoute,
        A4BarPSILCARoute,
        A4StackedPSILCARoute,
        A4PiePSILCARoute,
        B1TreePSILCARoute,
        B1PiePSILCARoute,
        B2BarPSILCARoute,
        B3BarPSILCARoute,
        B4PSILCAChartsRoute,
        C1BarPSILCARoute,
        C2BarPSILCARoute,
        C3BarPSILCARoute,
        C4BarPSILCARoute,
        D1BarPSILCARoute,
        D2BarPSILCARoute,
        D3BarPSILCARoute,
        D4BarPSILCARoute,

        A1BarEcoRoute,
        A2BarEcoRoute,
        A2PieEcoRoute,
        A2StackedEcoRoute,
        A3BarEcoRoute,
        A3PieEcoRoute,
        A3StackedEcoRoute,
        A4BarEcoRoute,
        A4PieEcoRoute,
        C4SDGLinkChartRoute,
        A1BarSPARoute,
        A2BarSPARoute,
        A3BarSPARoute,
        A4BarSPARoute,
        A4StackedBarSPARoute,
        A4PieSPARoute,
        B1TreeSPARoute,
        B1PieSPARoute,
        B2BarSPARoute,
        B3BarSPARoute,
        B4ChartsSPARoute,

        C1BarSPARoute,
        C1SDGBarSPARoute,
        C1SDGStackedBarSPARoute,
        C1StackedBarSPARoute,
        C2BarSPARoute,
        C3BarSPARoute,
        C4ChartsSPARoute,
        C4SDGTableSPARoute,
        C4SDGSunburstSPARoute,
    ]);
    productIndexRoute.addChildren([productRoute]);

    return productIndexRoute;
}

export default productRoutes;
