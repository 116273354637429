import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './C4Bar1SPA.styles';
import { Bar } from 'react-chartjs-2';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { SPAPSILCAPriorityResult, Product, SPALCSProcessResult, SPALifeCycleResult, SPAResult, SPAStakeholderResult } from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { palette1, palette2, palette3, palette4, processColors, productPerformanceColor, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingSet, applyWeight, weighingMethods } from '../../../../../../helper/weighingMethod';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ComparisonCompanySector from '../../../../options/components/ComparisonCompanySector';
import GraphType from '../../../../options/components/GraphTypeOptions';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function C4Bar1SPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('All Stakeholders');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');
    const [results, setResults] = useState<SPAResult>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults((calcResults));
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(
            ["All Stakeholders"].concat(
                calcResults.lifeCycleResults[0].processResults[0].PSILCAPriorities.map(
                    (item) => {
                        return item.category.split(":")[0]
                    }
                ).reduce((list : string[], stakeholder) =>{
                    if(!list.find(item => item === stakeholder)){
                        list.push(stakeholder);
                    }
                    return list;
                } ,[])
            )
        ); 
    }, [product]);


    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": processColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        };
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Score";
        }

        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        const extendedPalette = generateExtendedPalette(selectedPalette);
        const psilcaLifeCycle = results.lifeCycleResults.find((lcs) => lcs.lcsName === `${selectedLCS}:${selectedLCSS}`);
        const stakeholders = results.stakeholderResults;

        let process;
        if (psilcaLifeCycle) {
            process = psilcaLifeCycle.processResults.find(
                (item) => item.processName === selectedProcess
            );

            const stakeholder = selectedStakeholder;



            psilcaLifeCycle.processResults.forEach((item, index) => {
                chartData.datasets.push({
                    label: item.processName,
                    data: item.PSILCAResults.sort(
                        (a, b) => a.category.localeCompare(b.category)
                    ).map((item2) => item2.score),
                    backgroundColor:
                        selectedPalette[
                            index
                            // getGraphColorIndex({
                            //     label: item.processName,
                            //     identifier: spaLifeCycle.lcsName,
                            // })
                        ], //colors[index], //random color from array
                    borderColor: "000000",
                });

                if (!(chartData.labels.length > 0)) {
                    chartData.labels = item.PSILCAResults.filter(
                        (result) => {
                            if (
                                stakeholder !== "All Stakeholders" &&
                                !!stakeholder
                            ) {
                                return (
                                    result.category.split(":")[0] === stakeholder
                                );
                            } else {
                                return true;
                            }
                        }
                    )
                        .sort(
                            (a, b) =>  a.category.localeCompare(b.category)
                        )
                        .map((item2) => item2.category);
                }
            });

            if (chartData.datasets.length === 1) {
                const indexes : number[] = [];
                chartData.datasets[0].data =
                    chartData.datasets[0].data.filter((value, index) => {
                        if (value > 0) {
                            indexes.push(index);
                            return true;
                        } else {
                            return false;
                        }
                    });
                chartData.labels = chartData.labels.filter(
                    (item, index) => {
                        return indexes.find((item) => item === index);
                    }
                );
            }

            // else if (graph1Type === "Radar Chart") {
            //     if (process) {
            //         const stakeholder = graph1Stakeholder;

            //         spaLifeCycle.processResults.forEach((item, index) => {
            //             if (item.processName === process.processName) {

            //                 const colorIndex = getGraphColorIndex({
            //                     label: process.processName,
            //                     identifier: spaLifeCycle.lcsName,
            //                 });

            //                 chartData.datasets.push({
            //                     label: process.processName,
            //                     data: process.PSILCAResults.filter((result) => {
            //                         if (
            //                             stakeholder !== "All Stakeholders" &&
            //             !!stakeholder
            //                         ) {
            //                             return (
            //                                 result.category.split(":")[0] === stakeholder
            //                             );
            //                         } else {
            //                             return true;
            //                         }
            //                     })
            //                         .sort(
            //                             (a, b) =>
            //                                 "" + a.category.localeCompare(b.category)
            //                         )
            //                         .map((item2) => item2.score),
            //                     backgroundColor:
            //           currentSocialTransparentPalette[colorIndex], //"rgb(65, 190, 132, 0.3)", //random color from array

            //                     //transparentColors[Math.floor(Math.random() * 3)], //random color from array
            //                     borderColor: currentPalette[colorIndex],
            //                 });
            //             }
            //         });
            //         if (!(chartData.labels.length > 0)) {
            //             chartData.labels = process.PSILCAResults.filter(
            //                 (result) => {
            //                     if (
            //                         stakeholder !== "All Stakeholders" &&
            //           !!stakeholder
            //                     ) {
            //                         return (
            //                             result.category.split(":")[0] === stakeholder
            //                         );
            //                     } else {
            //                         return true;
            //                     }
            //                 }
            //             )
            //                 .sort(
            //                     (a, b) => "" + a.category.localeCompare(b.category)
            //                 )
            //                 .map((item2) => item2.category);
            //         }
            //         const indexes = [];
            //         chartData.datasets[0].data =
            //     chartData.datasets[0].data.filter((value, index) => {
            //         if (value > 0) {
            //             return true;
            //         } else {
            //             indexes.push(index);

            //             return false;
            //         }
            //     });
            //         chartData.labels = chartData.labels.filter(
            //             (item, index) => {
            //                 return !indexes.find((item) => item === index);
            //             }
            //         );
            //     }
            // }
            // else if (graph1Type === "Sunburst Graph") {
            //     const myChart = Sunburst();

            //     //TEMP FIX. sunburst needs a process but there are issues w/ graph1lcs and graphlcss updates
            //     let sunburstData;
            //     if (process) {
            //         sunburstData = {
            //             name: process.processName,
            //             children: [
            //                 ...new Set(
            //                     process.PSILCAResults.map(
            //                         (item) => item.category.split(":")[0]
            //                     )
            //                 ),
            //             ].map((item) => {
            //                 return {
            //                     name: item,
            //                     children: process.PSILCAResults.filter(
            //                         (item2) => item2.category.split(":")[0] === item
            //                     ).map((item3) => {
            //                         return {
            //                             name: item3.category.split(":")[1],
            //                             value: item3.score,
            //                         };
            //                     }),
            //                 };
            //             }),
            //         };
            //     }

            //     const sunburst = document.getElementById("sunburst");
            //     sunburst.innerHTML = "";

            //     chartData = sunburstData;
            //     myChart
            //         .data(sunburstData)
            //         .color((d, parent) => {
            //             let color;
            //             if (d.value) {
            //                 switch (d.value) {
            //                 case 1:
            //                     color = "#42a147";
            //                     break;
            //                 case 2:
            //                     color = "#fdc30a";
            //                     break;
            //                 case 3:
            //                     color = "#f47d00";
            //                     break;
            //                 case 4:
            //                     color = "#d22f2e";
            //                     break;
            //                 default:
            //                     color = "White";
            //                     break;
            //                 }
            //                 return color;
            //             } else {
            //                 return "#E6E6E6";
            //             }
            //         })
            //         .strokeColor(["white"])(sunburst)
            //         .size((d, parent) => {
            //             if (d.value) {
            //                 return 10;
            //             }
            //         })
            //         .label((d) => {
            //             if (d.name.length > 15) {
            //                 if (!d.value) {
            //                     return d.name.substring(0, 13) + "...";
            //                 } else {
            //                     return d.name.substring(0, 17) + "...";
            //                 }
            //             } else {
            //                 return d.name;
            //             }
            //         })
            //         .labelOrientation("auto")
            //         .handleNonFittingLabel((label, availablePx) => {
            //             return label;
            //         })
            //         .radiusScaleExponent(2.5);
            // }
            //Graph 2 Subcategory-level, per process

            // if (sampsilcaComparison) {
            //     const datasets = [
            //         {
            //             label: "Company Performance",
            //             data: [],
            //             backgroundColor: "#C467BA",
            //             //backgroundColor: colors[Math.floor(Math.random() * 4)], //random color from array
            //             borderColor: "000000",
            //         },
            //         {
            //             label: "Sector Performance",
            //             data: [],
            //             backgroundColor: "#1A5B3D",
            //             //backgroundColor: colors[Math.floor(Math.random() * 4)], //random color from array
            //             borderColor: "000000",
            //         },
            //     ];

            //     results.stakeholderResults.forEach((item) => {
            //         item.SAMPriorities.forEach((item2) => {
            //             datasets[0].data.push(item2.scores.sam);
            //             datasets[1].data.push(item2.scores.psilca);
            //         });
            //         graph2Data.labels = graph2Data.labels.concat(
            //             item.SAMPriorities.map((item) => item.category)
            //         );
            //     });

            //     graph2Data.datasets = datasets;
            // } else {
            //     let stakeholders = results.stakeholderResults;
            //     const stakeholder = stakeholders.find(
            //         (item) => item.name === graph2Stakeholder
            //     );

            //     if (stakeholder) {
            //         stakeholders = [stakeholder];
            //     }
            //     graph2Data.datasets.push({
            //         label: "Company Performance",
            //         data: stakeholders
            //             .map((stakeholder) => {
            //                 return stakeholder.SAMResults.filter((item) =>
            //                     item.score ? true : false
            //                 ).map((item) => item.score);
            //             })
            //             .reduce(
            //                 (data, stakeholder) => data.concat(stakeholder),
            //                 []
            //             ),
            //         backgroundColor: "#C467BA", //colors[Math.floor(Math.random() * 4)], //random color from array
            //         borderColor: "000000",
            //     });
            //     graph2Data.labels = stakeholders.reduce(
            //         (categories, stakeholder) => {
            //             return categories.concat(
            //                 stakeholder.SAMResults.filter((item) =>
            //                     item.score ? true : false
            //                 ).map((item) => item.category)
            //             );
            //         },
            //         []
            //     );
            // }
        } else {
            // chartData.datasets = ca;cs
            //WIP
        }


        setChartJSData(chartData);
        setChartOptions(options);
    }, [ selectedStakeholder, getGraphColorIndex, formState.colorPalette, selectedLCS, selectedLCSS, selectedProcess]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        console.log(options);
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Box component='div' className='results-options-top'>
                            <FormControl
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '3rem',
                                    alignItems: 'stretch',

                                }}
                            >
                                {/* <SPAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={3} /> */}
                            </FormControl>
                        </Box>
                        <Box component='div' className='results-options-bottom'>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                    className='bottom-options side-left two-graphs'
                                >
                                    <GraphType
                                        chart='C4'
                                        dimension='SPA'
                                    />
                                    <LCSDropdowns
                                        product={product}
                                        selectedLCS={selectedLCS}
                                        setSelectedLCS={setSelectedLCS}
                                        selectedLCSS={selectedLCSS}
                                        setSelectedLCSS={setSelectedLCSS}
                                        selectedProcess={selectedProcess}
                                        setSelectedProcess={setSelectedProcess}
                                        dimension="Social"
                                    />
                                    <Dropdown
                                        options={stakeholderOptions}
                                        valueSetter={setSelectedStakeholder}
                                        value={selectedStakeholder}

                                    />
                                    {/* <Dropdown
                                        options={['Raw Values', '% Per Subcategory']}
                                        valueSetter={setSelectedValueType}
                                        value={selectedValueType}

                                    /> */}

                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        '@media (max-width: 1680px)': {
                                            gap: '1vw',
                                        },
                                        width: 'min-content',
                                    }}
                                    className='bottom-options side-right'
                                >
                                    <ChartDownload chartData={chartJSData}
                                        chartName={'Subcategory level result - Product System'}
                                        chartType='Bar Graph' />
                                    <GraphSetting
                                        chartOptions={chartOptions}
                                        setApplyGraphSettings={setApplyGraphSettings}
                                        triggerChartUpdate={triggerChartUpdate}
                                        chartData={chartJSData}
                                        chartColorType='performance'
                                        graphType='barindicator'
                                    />

                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />

                <Box className={classes.chartSec}>
                    <canvas id='graph1Canvas' className='graph-canvas' />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C4Bar1SPA;
