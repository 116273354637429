/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './SDGTable.styles';
// import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './SDGTable.css';
import CircleIcon from '@mui/icons-material/Circle';

import { Bar } from 'react-chartjs-2';
import { EnvironmentalResult, Product } from '../../../interface/Product';

import sdgGoal01 from '../../../uploads/images/sdg-icons/E-WEB-Goal-01.png';
import sdgGoal02 from '../../../uploads/images/sdg-icons/E-WEB-Goal-02.png';
import sdgGoal03 from '../../../uploads/images/sdg-icons/E-WEB-Goal-03.png';
import sdgGoal04 from '../../../uploads/images/sdg-icons/E-WEB-Goal-04.png';
import sdgGoal05 from '../../../uploads/images/sdg-icons/E-WEB-Goal-05.png';
import sdgGoal06 from '../../../uploads/images/sdg-icons/E-WEB-Goal-06.png';
import sdgGoal07 from '../../../uploads/images/sdg-icons/E-WEB-Goal-07.png';
import sdgGoal08 from '../../../uploads/images/sdg-icons/E-WEB-Goal-08.png';
import sdgGoal09 from '../../../uploads/images/sdg-icons/E-WEB-Goal-09.png';
import sdgGoal10 from '../../../uploads/images/sdg-icons/E-WEB-Goal-10.png';
import sdgGoal11 from '../../../uploads/images/sdg-icons/E-WEB-Goal-11.png';
import sdgGoal12 from '../../../uploads/images/sdg-icons/E-WEB-Goal-12.png';
import sdgGoal13 from '../../../uploads/images/sdg-icons/E-WEB-Goal-13.png';
import sdgGoal14 from '../../../uploads/images/sdg-icons/E-WEB-Goal-14.png';
import sdgGoal15 from '../../../uploads/images/sdg-icons/E-WEB-Goal-15.png';
import sdgGoal16 from '../../../uploads/images/sdg-icons/E-WEB-Goal-16.png';
import sdgGoal17 from '../../../uploads/images/sdg-icons/E-WEB-Goal-17.png';
import SDGLinkageTheme from '../style/SDGLinkageTheme';
import { Typography } from '@mui/material';
import { palette1, palette2, palette3, palette4, sdgColor } from '../../../helper/colors';
import { SDGCorrelation } from '../../../interface/SDG';

interface ChartData {
    product: Product;
}

interface SdgGoal {
    goal: string;
    type: 'Direct' | 'Indirect' | string;
}

interface SDGConnection {
    categoryname: string;
    envimpact: number;
    unit: string;
    sdggoals: SdgGoal[];
}
export interface SDGCategory {
    name: string,
    val: number,
    unit: string,
    correlation: SDGCorrelation,

}
export interface SDGResult {

    name: string;
    categories: SDGCategory[];

}

const connections: SDGConnection[] = [
    {
        categoryname: 'Category Test',
        envimpact: 200,
        unit: 'kg',
        sdggoals: [
            { goal: 'SDG1', type: 'Direct' },
            { goal: 'SDG2', type: 'Indirect' },
            { goal: 'SDG3', type: 'Direct' },
            { goal: 'SDG4', type: 'Indirect' },
            { goal: 'SDG5', type: 'Direct' },
            { goal: 'SDG6', type: 'Direct' },
            { goal: 'SDG7', type: 'None' },
            { goal: 'SDG8', type: 'Indirect' },
            { goal: 'SDG9', type: 'Direct' },
            { goal: 'SDG10', type: 'Indirect' },
            { goal: 'SDG11', type: 'Indirect' },
            { goal: 'SDG12', type: 'None' },
            { goal: 'SDG13', type: 'Indirect' },
            { goal: 'SDG14', type: 'None' },
            { goal: 'SDG15', type: 'Indirect' },
            { goal: 'SDG16', type: 'Direct' },
            { goal: 'SDG17', type: 'Indirect' },
        ],
    },
];

const sdgImages = [
    { sdgNo: 'SDG1', image: sdgGoal01 },
    { sdgNo: 'SDG2', image: sdgGoal02 },
    { sdgNo: 'SDG3', image: sdgGoal03 },
    { sdgNo: 'SDG4', image: sdgGoal04 },
    { sdgNo: 'SDG5', image: sdgGoal05 },
    { sdgNo: 'SDG6', image: sdgGoal06 },
    { sdgNo: 'SDG7', image: sdgGoal07 },
    { sdgNo: 'SDG8', image: sdgGoal08 },
    { sdgNo: 'SDG9', image: sdgGoal09 },
    { sdgNo: 'SDG10', image: sdgGoal10 },
    { sdgNo: 'SDG11', image: sdgGoal11 },
    { sdgNo: 'SDG12', image: sdgGoal12 },
    { sdgNo: 'SDG13', image: sdgGoal13 },
    { sdgNo: 'SDG14', image: sdgGoal14 },
    { sdgNo: 'SDG15', image: sdgGoal15 },
    { sdgNo: 'SDG16', image: sdgGoal16 },
    { sdgNo: 'SDG17', image: sdgGoal17 },
];

function getColor(type: string): string {
    if (type === 'Direct') {
        return '#6ADBD7';
    }
    if (type === 'Indirect') {
        return '#a3a3a3';
    }
    return '#cbced9';
}

const TableHeaderCellStyled = styled(TableCell)(({ theme }) => {
    return {
        minWidth: '100px',
        color: 'white',
        backgroundColor: '#056939',
        fontSize: '0.7vw',
    }
});

const TableBodyCellStyled = styled(TableCell)(({ theme }) => {
    return {
        minWidth: '5vw',
        padding: '1vw',
        backgroundColor: '#D9EFDF',
        fontSize: '0.7vw',
        // '@media (max-width: 1680px)': {
        //     padding: '10px',
        // },
    }
});

const SDGLegendWrap = styled(Box)(({ theme }) => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '1vw',
    }
});

const SDGLegendBox = styled(Box)(({ theme }) => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
    }
});

const TableHeaderCellImg = styled(TableCell)(({ theme }) => {
    return {
        padding: '1px',
        lineHeight: '10px',
        border: 0,
        minWidth: '5vw',
    }
});

function SDGTable({ currentSDGs, colorPalette }: { currentSDGs: SDGResult[], colorPalette: string[] }) {
    const classes = useStyles();

    // const findColorPaletteByName = (name: string): string[] => {
    //     const palettes = {
    //         "SDG Colors": sdgColor,
    //         Sunset: palette1,
    //         Multicolored: palette2,
    //         Warm: palette3,
    //         Cold: palette4,
    //     } as Record<string, string[]>;
    //     return palettes[name] || palettes["SDG Colors"];
    // };

    const getColor = (type: string): string => {
        switch (type) {
        case 'Direct':
            return colorPalette[0];
        case 'Indirect':
            return colorPalette[1];
        case 'None':
        default:
            return colorPalette[2];
        }
    };
    useEffect(() => {
        console.log("Color Palette in SDGTable:", colorPalette);
    }, [colorPalette]);

    // function formatValue(val: number): string {
    //     return Math.round(val).toLocaleString(); 
    // }

    function formatValue(val: number, decimalPlaces = 2): string {
        return val.toLocaleString(undefined, {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    }

    return (
        <div id="SDGTable">
            <ThemeProvider theme={SDGLinkageTheme}>
                <SDGLegendWrap>
                    <SDGLegendBox>
                        <CircleIcon sx={{ color: getColor('Direct'), fontSize: '1.2vw' }} />
                        <Typography >Direct</Typography>
                    </SDGLegendBox>
                    <SDGLegendBox>
                        <CircleIcon sx={{ color: getColor('Indirect'), fontSize: '1.2vw' }} />
                        <Typography >Indirect</Typography>
                    </SDGLegendBox>
                    <SDGLegendBox>
                        <CircleIcon sx={{ color: getColor('None'), fontSize: '1.2vw' }} />
                        <Typography >No Connection</Typography>
                    </SDGLegendBox>
                </SDGLegendWrap>
                <TableContainer sx={{
                    overflowX: 'auto',
                    overflowY: 'auto',
                    maxHeight: '57vh',
                }}>
                    <Table aria-label='simple table' className='sdg-table'>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCellStyled>Categories</TableHeaderCellStyled>
                                <TableHeaderCellStyled align='center' sx={{ lineHeight: '1vw' }}>
                                    Environmental Impact
                                </TableHeaderCellStyled>
                                <TableHeaderCellStyled>Unit</TableHeaderCellStyled>
                                {sdgImages.map((sdg) => {
                                    return (
                                        <TableHeaderCellImg key={`${sdg.sdgNo}-sdgicon`}
                                        >
                                            <img src={sdg.image} alt={sdg.sdgNo} />
                                        </TableHeaderCellImg>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {currentSDGs.map((sdg) => {
                            return <div><h1>{sdg.name}</h1><div>{sdg.categories.map((category) => <h1>{category.name}</h1>)}</div ></div>;
                        })} */}

                            {currentSDGs.length > 0 && currentSDGs[0].categories.map((category, index) => {
                                return (
                                    <TableRow key={category.name}>
                                        <TableBodyCellStyled>
                                            {category.name}
                                        </TableBodyCellStyled>
                                        <TableBodyCellStyled align='center'>
                                            {formatValue(category.val, 8)}
                                        </TableBodyCellStyled>
                                        <TableBodyCellStyled align='center'>
                                            {category.unit}
                                        </TableBodyCellStyled>
                                        {currentSDGs.map((sdg) => {
                                            return (
                                                <TableBodyCellStyled
                                                    key={`${index}-${category.name}-${sdg.name}`}
                                                    align='center'
                                                    sx={{
                                                        backgroundColor: getColor(sdg.categories[index].correlation),
                                                        padding: 0, minWidth: '5vw', width: '-webkit-fill-available',
                                                    }}
                                                />
                                            )
                                        })}

                                    </TableRow>
                                )
                            })}



                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        </div>

    );
}

export default SDGTable;

