import { tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    IAMWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    PaperSpacing: {
        display: 'flex',
        gap: '1vw',
        flexDirection: 'column',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
    },
    InputTableCell: {
        fontSize: '0.7vw !important',
        border: '0 !important',
        padding: '0 !important',
        textAlign: 'center',
        '&:last-of-type': {
            width: '5rem',
        },
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004021',
            color: 'white',
            height: '3.5vw',
        },
        [`&.${tableCellClasses.body}`]: {},
    },
    InputTableRow: {
        fontSize: '0.7vw',
        color: '#707070',
        padding: '0 !important',
        '&:nth-of-type(odd)': {
            backgroundColor: '#F3F3F3',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#E6E6E6',
        },
        border: 0,
    },
    SmallerDeleteButton: {
        minHeight: '1vw !important',
        minWidth: '1vw !important',
        marginTop: '0 !important',
        marginRight: '0 !important',
        padding: '0.2vw !important',
        border: '0 !important',
        borderRadius: '50px !important',
        boxShadow: 'none !important',
        fontSize: '0.7vw !important',
        width: 'auto !important',
        backgroundColor: '#B85959',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#EFC4C4',
            boxShadow: 'none',
        },
        '@media (max-width: 1680px)': {
            padding: '2px',
        },
    },
    setIndicatorBt: {
        fontSize: '0.7vw !important',
        background: 0,
        textDecoration: 'underline',
        fontStyle: 'italic',
    },

}));

export default useStyles;
