import React from 'react';
import { Typography, Box, FormControlLabel, TextField, Button } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import SearchIcon from '@mui/icons-material/Search';
import { ThemeProvider } from '@emotion/react';
import { Process, Exchange } from '../../../../../interface/Process';
// import { Button } from '@mui/base/Button';

interface EnvProcessFlowListSearchProps {
    loadedProcess: Process,
    filteredExchanges: Exchange[],
    setFilteredExchanges: React.Dispatch<React.SetStateAction<Exchange[]>>,
    itemsPerPage: number,
    setRecords: React.Dispatch<React.SetStateAction<number>>,
}

interface EnvProcessFlowListSearchForm {
    searchQuery: string
}
export default function EnvProcessFlowListSearch({ loadedProcess, filteredExchanges,
    setFilteredExchanges, setRecords, itemsPerPage }: EnvProcessFlowListSearchProps) {

    const form = useForm<EnvProcessFlowListSearchForm>({
        defaultValues: {
            searchQuery: '',
        },
        onSubmit: ({ value }) => { // filter by the search query, and sort by where the search query is found inside the name

            if (loadedProcess) {
                setFilteredExchanges(loadedProcess?.exchanges.filter((exchange) => exchange.flowName.toLowerCase()
                    .includes(value.searchQuery.toLowerCase())).sort((a, b) => a.flowName.toLowerCase().indexOf(value.searchQuery.toLowerCase()) -
                        b.flowName.toLowerCase().indexOf(value.searchQuery.toLowerCase())))
                setRecords(itemsPerPage);
            }

        },
    });
    return <>

        <form.Field
            name="searchQuery"
            children={(field) => {
                return (
                    <FormControlLabel
                        sx={{
                            margin: '1vw',
                        }}
                        control={
                            <TextField
                                id={field.name}
                                className={field.name}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => {
                                    field.handleChange(e.target.value);
                                }}

                                type="text"
                                size="small"
                            />
                        }
                        label={
                            <>
                                <Button
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                        "& .MuiSvgIcon-root": {
                                            width: "1em",
                                            height: "1em",
                                            fontSize: "1.2vw",
                                        },
                                    }}
                                    onClick={() => { form.handleSubmit() }}
                                >
                                    <SearchIcon
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                width: "1em",
                                                height: "1em",
                                                fontSize: "1.2vw",
                                            },
                                        }}
                                    /> Search
                                </Button>
                            </>
                        }

                    />
                );
            }}
        />
    </>
};