import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import useStyles from './ConfirmProductDelete.styles';

interface ConfirmProductDeleteProps {
    productToDelete:string
    setShow:React.Dispatch<React.SetStateAction<boolean>>
    deleteProductHandler : () => void
}
export default function ConfirmProductDelete({deleteProductHandler, setShow} : ConfirmProductDeleteProps){

    const classes = useStyles();


    useEffect(() => {
        document.body.style.overflow = 'hidden';

        // Cleanup function to restore scrolling
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    
    return<>
        <Box className={classes.deleteProductPopup}>
            <Typography component='h2' variant='h2' className={classes.header}>Delete this product?</Typography>
            <Box className={classes.buttonSet}>
                <button onClick={() =>{
                    deleteProductHandler();
                    setShow(false);
                }}
                className={classes.miniButtons}
                >Confirm</button>
                <button onClick={() =>{
                    setShow(false);

                }}
                className={classes.miniButtons}
                >Cancel</button>
            </Box>
        </Box>
    </>
};