import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useState, useMemo, useEffect, useCallback } from 'react';
import deepClone from '../../../../../helper/deepClone';
import {
    ChartJSData,
    ChartJSOptions,
} from '../../../../../interface/ChartData';
import {
    Product,
    EnvironmentalResult,
    EnvResultSubstage,
} from '../../../../../interface/Product';
import Dropdown from '../../../../dropdowns/Dropdown';
import ImpactCategoryDropdown from '../../../../dropdowns/ImpactCategoryDropdown';
import { initialOptions } from '../../../options/InitialOptionValues';
import { applyValueType } from '../../../options/util/applyOptions';
import { CreateChartTS } from '../../../util/CreateChartTS';
import useStyles from './D2BarChart.styles';
import { Link } from '@tanstack/react-router';
import ResultsTheme from '../../../style/ResultsTheme';
import ChartDownload from '../../../options/components/ChartDownload';
import ProductComparison from '../../../options/components/Comparison';
import GraphSetting from '../../../options/components/GraphSettings';
import SDGLinkage from '../../../options/components/SDGLinkage';
import EnvChartNavigation from '../../../../dropdowns/BaseOptions/EnvChartNavigation';
import { lifeCycleColors, palette1, palette2, palette3, palette4 } from '../../../../../helper/colors';
import { useFormContext } from '../../../../forms/FormContext';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function D2BarChart(props: ChartData) {
    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);

    let unit: string; // TEMP

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'ENVIRONMENTAL' },
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults((calcResults));
    }, [product]);

    const getData = (lcs: EnvironmentalResult, lcss: EnvResultSubstage, category: string): number => {
        if (results) {
            const matchingStage = results.find((item) => { return item.name === lcs.name }) as EnvironmentalResult;
            if (matchingStage) {
                const matchingSubstage = matchingStage.subStages.find(
                    (item) => { return item.name === lcss.name },
                );

                if (matchingSubstage) {
                    const selectedCategory = selectedCategories[0];
                    let matchingCategory;
                    if (category) {
                        matchingCategory = matchingSubstage.results.find(
                            (item) => { return item.impactCategory.name === category },
                        );
                    } else {
                        matchingCategory = matchingSubstage.results.find(
                            (item) => { return item.impactCategory.name === selectedCategory },
                        );
                    }

                    if (matchingCategory) {
                        if (!unit) {
                            unit = matchingCategory.impactCategory.refUnit;
                        }
                        return matchingCategory.values.reduce((total , process) => total += process.value , 0);
                    }
                }
            }
        }
        return 0;
    };

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "LCS Colors": lifeCycleColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["LCS Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) *
            0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        const extendedPalette = generateExtendedPalette(selectedPalette);

        chartData.datasets = results.map((lcs, index) => {
            return {
                label: lcs.name,
                data:
                    // categoryDropdownType === 'Radio'
                    //     ? [
                    //         lcs.subStages.reduce((total, lcss) => {
                    //             return total + getData(lcs, lcss);
                    //         }, 0),
                    //     ]
                    //     :
                    selectedCategories.map((category) => {
                        return lcs.subStages.reduce((total, lcss) => {
                            const totalVal: number = total + getData(lcs, lcss, category);

                            return totalVal;
                        }, 0)
                    }),

                // backgroundColor: lifeCycleColors[colorIndex++], //colors[index],
                // backgroundColor: currentPaletteLifeCycle[colorIndex++], //colors[index],
                // borderColor: '000000',
                backgroundColor: extendedPalette[index],

            }
        });
        if (options.scales?.y?.title) {
            options.scales.y.title.text = unit;

        }
        chartData.labels = selectedCategories;
        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedCategories, getGraphColorIndex, formState.colorPalette]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

    // if (
    //     selectedValueType === 'Raw Values'
    // ) {
    //     options.scales.y.max = 100;
    //     options.scales.y.min = 0;
    // } else if (selectedValueType === '% Per Lifecycle Stage') {
    //     const totals = chartJSData.datasets[0].data.map((data, index) => chartJSData.datasets.reduce(
    //         (total, dataset) => total + Math.abs(dataset.data[index]),
    //         0,
    //     ), []);
    //     chartJSData.datasets = chartJSData.datasets.map((dataset) => ({
    //         ...dataset,
    //         data: dataset.data.map((item, index) => Math.abs(autoRound((item / totals[index]) * 100, 3))),
    //     }));
    //     options.scales.y.title.text = '%';
    //     options.scales.y.max = 100;

    //     // Rounds the numbers to a certain digit.
    //     options.plugins.tooltip.callbacks.label = (yData) => autoRound(yData.raw, 2).toFixed(8);
    // }

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) => { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        } CreateChartTS(chartData, options);
    };

    useEffect(() => {

        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >

                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={3}
                                        defaultResultIndex={1}
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', '% Per Lifecycle Stage']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />
                                        <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                            defaultBaseIndex={3}
                                            defaultResultIndex={2}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData} chartName={'Midpoint result: Impact category level - Lifecycle'} chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='process'
                                            chartData={chartJSData}
                                            chartColorType='lcs'
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default D2BarChart;
