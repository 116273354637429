import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: '100%',
        height: '63vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}});

export default useStyles;
