import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './C4Bar2SPA.styles';
import { Bar } from 'react-chartjs-2';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import deepClone from '../../../../../../helper/deepClone';
import { ChartDataSet, ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { SPAPSILCAPriorityResult, Product, SPALCSProcessResult, SPALifeCycleResult, SPAResult, SPAStakeholderResult } from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { palette1, palette2, palette3, palette4, productPerformanceColor, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { applyWeight, weighingMethods } from '../../../../../../helper/weighingMethod';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ComparisonCompanySector from '../../../../options/components/ComparisonCompanySector';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function C4Bar2SPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');
    const [results, setResults] = useState<SPAResult>([]);
    const [companySectorComparison, setCompanySectorComparison] = useState(false);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults((calcResults));
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(
            ["All Stakeholders"].concat(
                calcResults.lifeCycleResults[0].processResults[0].PSILCAPriorities.map(
                    (item) => {
                        return item.category.split(":")[0]
                    }
                ).reduce((list : string[], stakeholder) =>{
                    if(!list.find(item => item === stakeholder)){
                        list.push(stakeholder);
                    }
                    return list;
                } ,[])
            )
        );     }, [product]);


    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Performance Colors": productPerformanceColor,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        };
        return palettes[name] || palettes["Performance Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }

        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        const extendedPalette = generateExtendedPalette(selectedPalette);
        const spaLifeCycle = results.lifeCycleResults.find((lcs) => lcs.lcsName === results.SPAProcess.lifeCycleStage);
        let stakeholders = results.stakeholderResults;

        if (companySectorComparison) {
            const datasets : ChartDataSet[] = [
                {
                    label: "Company Performance",
                    data: [] ,
                    backgroundColor: "#C467BA",
                    //backgroundColor: colors[Math.floor(Math.random() * 4)], //random color from array
                    borderColor: "000000",
                },
                {
                    label: "Sector Performance",
                    data: [],
                    backgroundColor: "#1A5B3D",
                    //backgroundColor: colors[Math.floor(Math.random() * 4)], //random color from array
                    borderColor: "000000",
                },
            ];
            console.log(results.stakeholderResults)
            results.stakeholderResults.forEach((item) => {
                item.SPAPriorities.forEach((item2) => {
                    datasets[0].data.push(item2.scores.spa);
                    datasets[1].data.push(item2.scores.psilca);
                });
                chartData.labels = chartData.labels.concat(
                    item.SPAPriorities.map((item) => item.category)
                );
            });

            chartData.datasets = datasets;
        } else {

            const stakeholder = stakeholders.find(
                (item) => item.name === selectedStakeholder
            );

            if (stakeholder) {
                stakeholders = [stakeholder];
            }
            chartData.datasets.push({
                label: "Company Performance",
                data: stakeholders
                    .map((stakeholder) => {
                        return stakeholder.SPAResults.filter((item) =>
                            item.score ? true : false
                        ).map((item) => item.score);
                    })
                    .reduce(
                        (data, stakeholder) => data.concat(stakeholder),
                        []
                    ),
                backgroundColor: "#C467BA", //colors[Math.floor(Math.random() * 4)], //random color from array
                borderColor: "000000",
            });
            chartData.labels = stakeholders.reduce(
                (categories: string[], stakeholder) => {
                    return categories.concat(
                        stakeholder.SPAResults.filter((item) =>
                            item.score ? true : false
                        ).map((item) => item.category)
                    );
                },
                []
            );
        }




        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedStakeholder, getGraphColorIndex, formState.colorPalette, companySectorComparison]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options, 'bar', 'graph2Canvas');
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                    width:'-webkit-fill-available',
                }}
            >
                <Box component='div' className='results-wrapper'
                >
                    <Box component='div' className='results-options'>
                        <Box component='div' className='results-options-top'>
                            <FormControl
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '3rem',
                                    alignItems: 'stretch',

                                }}
                            >
                                {/* <SPAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={3} /> */}
                                {/* <ProductComparison /> */}
                            </FormControl>
                        </Box>
                        <Box component='div' className='results-options-bottom'>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '1rem',  // Adjust the gap between dropdowns
                                        alignItems: 'stretch', // Align all dropdowns vertically
                                        flexWrap: 'wrap', // 
                                        width: '-webkit-fill-available',
                                    }}
                                    className='bottom-options side-left two-graphs'
                                >
                                    <ComparisonCompanySector
                                        companySectorComparison={companySectorComparison}
                                        setCompanySectorComparison={setCompanySectorComparison} />
                                    <Dropdown
                                        options={stakeholderOptions}
                                        valueSetter={setSelectedStakeholder}
                                        value={selectedStakeholder}

                                    />


                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        '@media (max-width: 1680px)': {
                                            gap: '1vw',
                                        },
                                        width: 'min-content',
                                    }}
                                    className='bottom-options side-right'
                                >


                                    <ChartDownload chartData={chartJSData}
                                        chartName={'Subcategory level result - Product System'}
                                        chartType='Bar Graph' />
                                    <GraphSetting
                                        chartOptions={chartOptions}
                                        setApplyGraphSettings={setApplyGraphSettings}
                                        triggerChartUpdate={triggerChartUpdate}
                                        chartData={chartJSData}
                                        chartColorType='performance'
                                        graphType='barindicator'
                                    />

                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />

                <Box className={classes.chartSec}>
                    <canvas id='graph2Canvas' className='graph-canvas' />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C4Bar2SPA;
