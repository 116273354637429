import tableCellClasses from '@mui/material/TableCell/tableCellClasses';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    impactInformationWrap: {
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
    informationIcon: {},
    impactMethodTable: {
        border: '1px solid',
        padding: '0.2rem',
        boxShadow: '0 3px 8px 0 rgb(0 0 0 / 50%)',
        borderColor: '#707070',
        backgroundColor: 'white',
    },

    ButtonWrapper: {
        width: 'auto',
        color: '#004021',
        '&:hover': {
            color: '#89b8a2',
        },
        padding: '0 !important',
    },
    InputTableCell: {
        width: '-webkit-fill-available',
        fontSize: '0.7vw !important',
        lineHeight: '1.1vw!important',
        padding: '5px!important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004021',
            color: 'white',
            height: '3.5vw',
        },
        [`&.${tableCellClasses.body}`]: {
            height: '5vh',
        },
        '@media (max-width: 1680px)': {
            padding: '5px',
            // fontSize: 10,
            // lineHeight: '15px',
        },
    },
}});

export default useStyles;
