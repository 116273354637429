/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import GeneralTheme from '../../style/GeneralTheme';
import './EconomicInputs.css';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import Dropdown from '../../dropdowns/Dropdown';
import { useNavigate } from '@tanstack/react-router';
import { useFormContext } from '../../forms/FormContext';
import { useForm } from '@tanstack/react-form';
import {
    EnvProcessInputLCS,
    EnvironmentalInputsForm,
} from '../../../interface/Form';
import {
    LifeCycleStage,
    lifeCycleStages,
} from '../../../helper/lifeCycleStage';
import { fetchEnvMethods } from '../../../helper/fetchUtil';
import { SelectMethod } from '../../../interface/ImpactMethod';
import EnvInputProcessList from '../EnvironmentalInputs/components/EnvInputProcessList';
import CreateProductHeader from '../../results/components/createproductheader';

import { CreateProductNavButtons } from '../components/CreateProductNavButtons';

interface EconomicInputsProps {}

export const EconomicInputs = (props: EconomicInputsProps) => {
    const [selectableLCS, setSelectableLCS] = useState<string[]>([]);
    const { formState, setFormState } = useFormContext();

    const [selectableLCSS, setSelectableLCSS] = useState<string[]>([]);

    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedStages, setSelectedStages] = useState<LifeCycleStage[]>([]);

    const form = useForm<EnvironmentalInputsForm>({
        defaultValues: { //uses the same form for convenience (might need to change this)
            envInputProcesses: formState.envInputProcesses || [],
            envInputEmissions: formState.envInputEmissions || [],
            envOutputEmissions: formState.envOutputEmissions || [],
            envOutputWaste: formState.envOutputWaste || [],
            currentLCS: formState.selectedLCS
                ? (formState.selectedLCS as string[])[0].split(":")[0]
                : "Product Stage",
            currentLCSS: formState.selectedLCS
                ? (formState.selectedLCS as string[])[0].split(":")[1]
                : "Raw Material Supply", // TODO use this way of getting default LCS in social as well
            envAssessmentMethod: (formState.envAssessmentMethod as string) || "None",
            envNWSet: (formState.envNWSet as string) || "",
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            console.log(value);
            setFormState({
                ...formState,
                envInputProcesses: value.envInputProcesses,
            });
        },
    });

    const selectLCSHandler = (lcs: string, _stages?: LifeCycleStage[]) => {
        const stages = _stages || selectedStages;
        const stage = stages.find((_stage) => {return _stage.name === lcs});
        if (stage) {
            setSelectableLCSS(stage.substages.map((substage) => {return substage.name}));
            setSelectedLCSS(stage.substages[0].name);
            form.setFieldValue('currentLCSS', stage.substages[0].name);
        }
    };

    const navigate = useNavigate();
    const showValues = () => {};

    const fetchSelectedLCS = () => {
        if (formState && formState.selectedLCS) {
            const stages: LifeCycleStage[] = [];
            (formState.selectedLCS as string[])?.forEach((lcs: string) => {
                const matchingLCS = stages.find(
                    (stage) => {return stage.name === lcs.split(':')[0]}
                );
                if (matchingLCS) {
                    matchingLCS.substages.push({ name: lcs.split(':')[1] });
                } else {
                    stages.push({
                        name: lcs.split(':')[0],
                        substages: [{ name: lcs.split(':')[1] }],
                    });
                }
            });
            setSelectedStages(stages);
            if (stages[0]) {
                form.setFieldValue('currentLCS', stages[0].name);
                setSelectedLCS(stages[0].name);
                setSelectableLCS(stages.map((stage) => {return stage.name}));
                selectLCSHandler(stages[0].name, stages);
            }
        } else {
            console.log('CONTEXT UNAVAILABLE');
            navigate({ to: '../details' })
                .then((val) => {})
                .catch((err) => {});
        }
    };

    const loadEnvInputs = () => {
        let stages: EnvProcessInputLCS[] = [];
        if (formState && formState.envInputProcesses) {
            stages = formState.envInputProcesses as EnvProcessInputLCS[];
            form.setFieldValue('envInputProcesses', stages);
        }
        return stages;
    };

    useEffect(() => {
        fetchSelectedLCS();
        loadEnvInputs();
    }, []);

    return (
        <Box>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // form
                    //     .handleSubmit()
                    //     .then(() => { })
                    //     .catch((err) => { });
                }}
                className='inputs-form-env'
            >
                <CreateProductHeader formState={formState} currentPage='economic' />

                <ThemeProvider theme={GeneralTheme}>
                    <button type='button' onClick={showValues}
                        style={{ position: 'absolute' }}

                    >
            TEST
                    </button>
                    <button
                        type='button'
                        onClick={() => {
                            console.log(formState);
                        }}
                        style={{ position: 'absolute' }}
                    >
            PRINT CONTEXT
                    </button>

                    <FormControl>
                        <Container
                            className='economic-modal-content'
                            maxWidth={false}
                            sx={{
                                maxWidth: '100%',
                                // height: '93vh',
                                height: 'auto',
                                // padding: '1rem',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1vw',
                                    width: '-webkit-fill-available',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '1vw',
                                        width: '-webkit-fill-available',
                                    }}
                                >
                                    <Paper
                                        square
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'auto 1fr',
                                            justifyItems: 'center',
                                            width: '-webkit-fill-available',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography component='h2' variant='h2'>
                      Lifecycle Stages
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '2rem',
                                                width: '-webkit-fill-available',
                                                justifyContent: 'center',
                                                marginRight: '12vw',
                                            }}
                                        >
                                            <form.Field
                                                name='currentLCS'
                                                children={(field) => {return (
                                                    <Dropdown
                                                        options={selectableLCS}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            selectLCSHandler(e.target.value);
                                                            setSelectedLCS(e.target.value);
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        label='Lifecycle Stage'
                                                        noDefaults
                                                    />
                                                )}}
                                            />
                                            <form.Field
                                                name='currentLCSS'
                                                children={(field) => {return (
                                                    <Dropdown
                                                        options={selectableLCSS}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            setSelectedLCSS(e.target.value);
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        label='Sublifecycle Stage'
                                                        noDefaults
                                                    />
                                                )}}
                                            />
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '2rem',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            borderRadius: '0',
                                            width: '-webkit-fill-available',
                                            height: '60vh',
                                        }}
                                    >
                                        <Paper
                                            square
                                            sx={{
                                                display: 'flex',
                                                gap: '1vw',
                                                flexDirection: 'column',
                                                width: '-webkit-fill-available',
                                                height: '-webkit-fill-available',
                                            }}
                                        >
                                            <Typography component='h2' variant='h2'>
                        Inputs
                                            </Typography>
                                            <EnvInputProcessList
                                                envForm={form}
                                                lifeCycleStage={
                                                    selectedLCS && selectedLCSS
                                                        ? `${selectedLCS}:${selectedLCSS}`
                                                        : ''
                                                }
                                            />
                                        </Paper>
                                    </Box>
                                </Box>
                                <CreateProductNavButtons
                                    form={form}
                                    formState={formState}
                                    currentPage='economic'
                                />
                            </Box>
                        </Container>
                    </FormControl>
                </ThemeProvider>
            </form>
        </Box>
    );
};

export default EconomicInputs;
