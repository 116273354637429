/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useContext, useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "@tanstack/react-router";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CottageIcon from "@mui/icons-material/Cottage";
import InventoryIcon from "@mui/icons-material/Inventory";
import PendingIcon from "@mui/icons-material/Pending";
import { Padding } from "@mui/icons-material";
import useStyles from "./ProductListItem.styles";
import { requestProductDelete } from "../../../helper/fetchUtil";
import { Product } from "../../../interface/Product";
import ConfirmProductDelete from "./ConfirmProductDelete";
import LoopIcon from '@mui/icons-material/Loop';

interface ProductNameId {
    _id: string;
    productName: string;
}

interface ProductListItem {
    productName: string;
    id: string;
    setProducts: React.Dispatch<React.SetStateAction<ProductNameId[]>>;
    products: ProductNameId[];
}

const ProductListItem: React.FC<ProductListItem> = (props) => {
    const navigate = useNavigate();
    const { productName, id, setProducts, products } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const deleteProductHandler = () => {
        const deleteProduct = async () => {
            setIsDeleting(true);
            setShowConfirmDelete(false);
            requestProductDelete(id)
                .then(() => {
                    setIsDeleting(false);

                    setProducts(
                        products.filter((item) => {
                            return item._id !== id;
                        })
                    );
                })
                .catch(() => { });
        };

        try {
            deleteProduct();
        } catch (err) {
            console.log(err);
        }
        // setShowConfirmDeleteProductModal(false);
    };
    const productitemtheme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: "12px",
                        fontFamily: "Roboto, sans-serif",
                        fontWeight: "bold",
                        color: "#707070",
                        padding: "1px",
                        "&:hover": {
                            backgroundColor: "rgb(0 0 0 / 25%)",
                        },
                    },
                },
            },
        },
    });

    const classes = useStyles();

    const [isShown, setIsShown] = useState<number | null>(null); // Use number or null based on your logic

    const handleClick = (index: number) => {
        if (isShown === index) {
            setIsShown(null); // Reset if already shown
        } else {
            setIsShown(index);
        }
    };

    return (
        <ListItemButton className={classes.ProductListItem}>

            {showConfirmDelete && (
                <ConfirmProductDelete
                    productToDelete={id}
                    setShow={setShowConfirmDelete}
                    deleteProductHandler={deleteProductHandler}
                />
            )}
            <Link
                to="/product/$pid"
                params={{ pid: id }}
                style={{ textDecoration: "none" }}
            >
                <ListItemText
                    primary={productName}
                    className="product-list-item__name"
                />
            </Link>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {/* <MoreVertIcon onClick={handleClick}  key={index} /> */}

                {/* {isShown && ( */}
                <Collapse
                    in={isShown !== null}
                    timeout="auto"
                    unmountOnExit
                    className="popup-adjustments"
                >
                    <ThemeProvider theme={productitemtheme}>
                        <Box className="product-item-wrap">
                            <Box
                                className="product-item-options-popup"
                                sx={{
                                    border: '1px solid #707070',
                                    /* border-radius: 8px; */
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    height: '1.5vw',
                                    // display: 'flex',
                                    alignItems: 'center',
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '50%',
                                        right: '-0.5vw',
                                        width: '0',
                                        height: '0',
                                        borderTop: '0.5vw solid transparent', 
                                        borderBottom: '0.5vw solid transparent', 
                                        borderLeft: '0.5vw solid #707070', 
                                        transform: 'translateY(-50%)',
                                    },
                                }}
                            >
                                <Button
                                    className="product-option product-list-item__copy"
                                    // onClick={() => {
                                    //   showConfirmEditProductModalHandler(item._id);
                                    // }}
                                    sx={{
                                        fontSize: '0.7vw',
                                        minWidth: '3vw',
                                        maxWidth: '3vw',
                                    }}
                                >
                                    Copy
                                </Button>

                                <Button className="product-option product-list-item__edit" onClick={() => {
                                    navigate({ to: `/createproduct/edit/${id}/details` })
                                        .then((val) => { })
                                        .catch((err) => { });
                                }}
                                sx={{
                                    fontSize: '0.7vw',
                                    minWidth: '3vw',
                                    maxWidth: '3vw',
                                }}
                                >
                                    Edit
                                </Button>
                            </Box>
                        </Box>
                    </ThemeProvider>
                </Collapse>
                {/* )} */}

                <IconButton
                    aria-label="more options"
                    onClick={() => {
                        return handleClick(1);
                    }}
                    sx={{
                        "@media (max-width: 1680px)": {
                            width: "20px",
                        },
                    }}
                >
                    <MoreVertIcon />
                </IconButton>

                <ListItemIcon
                    className="product-list-item__delete"
                    onClick={() => {
                        setShowConfirmDelete(true);
                    }}
                >
                    {isDeleting ? (
                        <Box>
                            <LoopIcon  className={classes.deleteLoadingSpinner} />
                        </Box>
                    ) : (
                        <ClearIcon
                            sx={{
                                color: "#C90E0E",
                                "&:hover": {
                                    transition: "0.3s",
                                    color: "#DB8686",
                                },
                                "@media (max-width: 1680px)": {
                                    width: "20px",
                                },
                            }}
                        />
                    )}
                </ListItemIcon>
            </Box>
        </ListItemButton>
    );
};

export default ProductListItem;
