import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './E4BarChart.styles';

import { Bar } from 'react-chartjs-2';
import {
    EnvironmentalResult,
    FactorResult,
    Product,
} from '../../../../../interface/Product';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import { Accumulator } from '../../../../../interface/Accumulator';
import ImpactCategoryDropdown from '../../../../dropdowns/ImpactCategoryDropdown';
import ChartOptions from '../../../options/ChartOptions';
import { Link } from '@tanstack/react-router';
import { initialOptions } from '../../../options/InitialOptionValues';
import deepClone from '../../../../../helper/deepClone';
import {
    ChartDataSet,
    ChartJSData,
    ChartJSOptions,
} from '../../../../../interface/ChartData';
import { applyValueType } from '../../../options/util/applyOptions';
import { CreateChartTS } from '../../../util/CreateChartTS';
import Dropdown from '../../../../dropdowns/Dropdown';
import ResultsTheme from '../../../style/ResultsTheme';
import ChartDownload from '../../../options/components/ChartDownload';
import GraphSetting from '../../../options/components/GraphSettings';
import SDGLinkage from '../../../options/components/SDGLinkage';
import ProductComparison from '../../../options/components/Comparison';
import EnvChartNavigation from '../../../../dropdowns/BaseOptions/EnvChartNavigation';
import LCSDropdowns from '../../../../dropdowns/LCSDropdowns';
import { elementaryColors, palette1, palette2, palette3, palette4 } from '../../../../../helper/colors';
import { useFormContext } from '../../../../forms/FormContext';
import { ColorIndexData } from '../E1BarChart';

interface FlowData {
    flow: string;
    data: number[];
}

interface CategoryFlows {
    category: string;
    flows: FlowData[];
}

interface ChartData {
    product: Product;
}

function E4BarChart(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedFilterOption, setSelectedFilterOption] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectableProcesses, setSelectableProcesses] = useState<string[]>([]);
    const [selectedProcess, setSelectedProcess] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'ENVIRONMENTAL' },
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults((calcResults));
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Elementary Colors": elementaryColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["Elementary Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        if (!palette || palette.length === 0) {
            console.warn('Palette is undefined or empty. Using default fallback colors.');
            palette = ['#FF0000', '#00FF00', '#0000FF']; // Example fallback colors
        }
    
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
    
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        let top = 0;
        switch (selectedFilterOption) {
        case 'Top 2 Contributions':
            top += 2;
            break;
        case 'Top 5 Contributions':
            top += 5;
            break;
        default:
            break;
        }
        let unit;
        const lifeCycle = results.find(
            (item) => { return item.name === selectedLCS },
        );
        if (lifeCycle) {
            const subStage = lifeCycle.subStages.find(
                (item) => { return item.name === selectedLCSS },
            );

            if (subStage) {
                const flows = selectedCategories.reduce((flowList: CategoryFlows[], category) => {
                    flowList.push({
                        category,
                        flows: subStage.results.reduce((list: FlowData[], impactCategory) => {
                            if (impactCategory.impactCategory.name === category) {
                                impactCategory.values.forEach((process) => {
                                    process.factorResults.forEach((flow) => {
                                        list.find((item) => { return item.flow === flow.flow });
                                        if (!list.find((item) => { return item.flow === flow.flow })) {
                                            list.push({
                                                flow: flow.flow,
                                                data: selectedCategories.map((category2) => {
                                                    const matchingCategory = subStage.results.find(
                                                        (item) => { return item.impactCategory.name === category2 },
                                                    );
                                                    if (matchingCategory) {
                                                        unit = matchingCategory.impactCategory.refUnit;
                                                        const matchingProcess = matchingCategory.values.find(
                                                            (item) => {
                                                                return item.endProductName
                                                                    === selectedProcess || item.processName === selectedProcess
                                                            },
                                                        );
                                                        if (matchingProcess) {
                                                            const val = matchingProcess.factorResults.reduce(
                                                                (factorResultTotal, factorResult) => {
                                                                    if (factorResult.flow === flow.flow) {
                                                                        factorResultTotal
                                                                            += factorResult.result;
                                                                    }
                                                                    return factorResultTotal;
                                                                },
                                                                0,
                                                            );

                                                            return val;
                                                        }
                                                        return 0;
                                                    }
                                                    return 0;
                                                }),
                                            });
                                        }
                                    });
                                });
                            }
                            return list
                                .sort((a, b) => { return b.data[0] - a.data[0] })
                                .filter((flow, index) => { return index < top });
                        }, []),
                    });
                    return flowList;
                }, []);

                chartData.labels = selectedCategories;

                const selectedPalette = findColorPaletteByName(formState.colorPalette);
                const extendedPalette = generateExtendedPalette(selectedPalette);

                chartData.datasets = flows.reduce((flowList: ChartDataSet[], category) => {
                    category.flows.forEach((flow, index) => {

                        const colorIndex = getGraphColorIndex({
                            label: flow.flow,
                            // identifier: `${productName}:${category.category}`,
                        });

                        flowList.push({
                            label: flow.flow,
                            data: flow.data,
                            backgroundColor: extendedPalette[colorIndex],
                            // borderColor: '000000',
                            // backgroundColor:
                            //     // processColors[
                            //     //   getGraphColorIndex({
                            //     //     label: flow.flow,
                            //     //     identifier: `${lifeCycle.name}`,
                            //     //   })
                            //     // ],
                            //     currentPaletteElementary[
                            //     getGraphColorIndex({
                            //         label: flow.flow,
                            //         // identifier: `${lifeCycle.name}`,
                            //         // identifier: `${lifeCycle.name}:${subStage.name}:${formState.inputs.graph1Process.value}`,
                            //         identifier: `${loadedProduct.productName}:${category.category}`,
                            //     })
                            //     ],
                        });
                    });
                    return flowList;
                }, []);
            }
        }

        console.log(chartData);
        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedFilterOption, selectedCategories, selectedLCS, selectedLCSS, selectedProcess, getGraphColorIndex, results, formState.colorPalette]);
    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        } CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={4}
                                        defaultResultIndex={3}
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', '% Per Flow']}
                                            valueSetter={setSelectedValueType}
                                            label='Value Type'
                                            value={selectedValueType}

                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            selectedProcess={selectedProcess}
                                            setSelectedProcess={setSelectedProcess}
                                        />
                                        <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                            chartData={chartJSData}
                                            chartColorType='elementary'
                                        />
                                        <Dropdown
                                            options={['Top 2 Contributions', 'Top 5 Contributions']}
                                            valueSetter={setSelectedFilterOption}
                                            value={selectedFilterOption}
                                            label='Contributions'
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData = {chartJSData} chartName={'Inventory result: Elementary flow level - Process'} chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='elementary'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default E4BarChart;
