/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
    InputLabel,
    Box,
    Typography,
    Button,
    FormGroup,
    MenuItem,
    Select,
    Paper,
    styled,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Portal } from '@mui/base/Portal';
import ClearIcon from '@mui/icons-material/Clear';
import { ThemeProvider } from '@emotion/react';
import ProcessPopupTheme from '../../../../style/components/ProcessPopupTheme';
import ExitButton from '../../../../style/components/ExitButton';
import { FormControl } from '@mui/base/FormControl';
import {
    fetchFlows,
    fetchEnvProcesses,
    fetchEnvProcessCategories,
    fetchEnvProcessesByPageAndCategory,
    fetchEnvProcessByPage,
    fetchSocialProcessCategories,
    fetchSocialProcessesByPageAndCategory,
    fetchSocialProcessByPage,
} from '../../../../../helper/fetchUtil';
import { SelectProcess } from '../../../../../interface/Process';
import {
    EnvInputProcess,
    EnvironmentalInputsForm,
} from '../../../../../interface/Form';
import { UnitGroup } from '../../../../../interface/Unit';
import { useForm } from '@tanstack/react-form';
import Dropdown from '../../../../dropdowns/Dropdown';
import SearchIcon from '@mui/icons-material/Search';

import useStyles from './SelectSocialProcessPopup.styles';
import '../../SocialInputs.css';
import ProcessFlowDetails from '../../../EnvironmentalInputs/components/ProcessFlowDetails';

interface SelectEnvironmentalProcessPopupProps {
    openProcessSelect: (event: React.MouseEvent<HTMLElement>) => void;
    selectHandler: (process: SelectProcess) => void;
}

interface CategorySelectForm {
    category1: string;
    category2: string;
    searchKey: string;
    useCategorySearch: boolean;
}
function SelectEnvironmentalProcessPopup(
    props: SelectEnvironmentalProcessPopupProps
) {
    const { selectHandler, openProcessSelect } = props;
    const [openProcessFlow, setOpenProcessFlow] = useState(false);
    const [loadedProcesses, setLoadedProcesses] = useState<SelectProcess[]>([]);
    const [loading, setLoading] = useState(true); // New state for loading
    const [loadedCategories, setLoadedCategories] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number[]>([1]);

    const [selectableCategory1, setSelectableCategory1] = useState<string[]>([]);
    const [selectableCategory2, setSelectableCategory2] = useState<string[]>([]);
    const [selectedProcessDetails, setSelectedProcessDetails] = useState<SelectProcess>();




    const classes = useStyles();

    const selectProcessHandler = (
        event: React.MouseEvent<HTMLElement>,
        process: SelectProcess
    ) => {
        openProcessSelect(event);
        selectHandler(process);
    };

    const handleEnvProcessFlowClick = () => {
        setOpenProcessFlow((prev) => { return !prev });
    };

    const handleEnvProcessFlowClickOff = () => {
        setOpenProcessFlow(false);
    };

    /* This will be moved to the Theme page soon */

    // useEffect(() => {
    //     if (!(loadedProcesses.length > 0)) {
    //         fetchEnvProcesses()
    //             .then((val) => {
    //                 setLoadedProcesses(val);
    //                 setLoading(false);
    //             })
    //             .catch((err) => {
    //                 console.error('Failed to fetch processes:', err);
    //                 setLoading(false);
    //             });
    //     }
    // }, []);
    const form = useForm<CategorySelectForm>({
        defaultValues: {
            category1: '',
            category2: '',
            searchKey: '',
            useCategorySearch: true,
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            console.log(value);
        },
    });

    useEffect(() => {
        fetchSocialProcessCategories()
            .then((val) => {
                setLoadedCategories(val);
                processCategories(val);
                submitProcessSearch(1, val);
            })
            .catch(() => { });
    }, []);

    const processCategories = (categories: string[]) => {
        const c1 = getUniqueCategories(0, categories);
        setSelectableCategory1(c1);
        selectC1Handler(c1[0], categories);
    };

    const getSubcategories = (
        parentCategory: string,
        parentIndex: number,
        subCategoryIndex: number,
        categories: string[]
    ): string[] => {
        const listToReturn: string[] = [];
        categories.forEach((category) => {
            const categorySplit = category.split('/');

            if (
                categorySplit[parentIndex] === parentCategory &&
                !listToReturn.find((item) => { return item === categorySplit[subCategoryIndex] })
            ) {
                listToReturn.push(categorySplit[subCategoryIndex]);
            }
        });
        return listToReturn;
    };

    // const selectC3Handler = (c3: string, categories: string[]) => {
    //     form.setFieldValue('category3', c3);

    //     const c4 = getSubcategories(c3, 2, 3, categories);
    //     setSelectableCategory4(c4);
    //     selectC4Handler(c4[0], categories);
    //     setSelectedC3(c3);
    // };
    // const selectC4Handler = (c4: string, categories: string[]) => {
    //     form.setFieldValue('category4', c4);
    //     setSelectedC4(c4);
    //     setCurrentPage(1);
    //     submitProcessSearch(1);
    // };

    const fetchVals = () => {
        console.log(form.state.values);
        console.log(totalPages);
    };

    const getUniqueCategories = (
        index: number,
        categories: string[]
    ): string[] => {
        const seen: Record<string, boolean> = {};
        return categories
            .map((category) => { return category.split('/')[index] })
            .filter((category) => {
                if (seen[category]) {
                    return false;
                }
                seen[category] = true;
                return true;
            });
    };

    const submitProcessSearch = (page?: number, categories?: string[]) => {
        const _categories = categories || loadedCategories;
        if (form.getFieldValue('useCategorySearch')) {
            const c1 = form.state.values.category1;
            const c2 = form.state.values.category2;

            const key = form.state.values.searchKey;
            setLoading(true);
            const matchingCategory = _categories?.find((item) => { return item.includes(`${c1}/${c2}`) }
            )
            if (matchingCategory) {
                const categoryToSearch = matchingCategory.split('/')[matchingCategory.split('/').length - 1];
                fetchSocialProcessesByPageAndCategory(page || currentPage, key, Number(categoryToSearch))
                    .then((data) => {
                        setLoading(false);
                        setLoadedProcesses(data.processes);
                        setTotalPages(Array(Math.ceil(data.entries / 100)).fill(0));
                    })
                    .catch(() => { });
            }

        } else {
            const key = form.state.values.searchKey;
            setLoading(true);

            fetchSocialProcessByPage(page || currentPage, key)
                .then((data) => {
                    setLoading(false);
                    setLoadedProcesses(data.processes);
                    setTotalPages(Array(Math.ceil(data.entries / 100)).fill(0));
                })
                .catch(() => { });
        }

    }; const selectC2Handler = (c2: string, categories: string[]) => {
        form.setFieldValue('category2', c2);
        submitProcessSearch(1);

    };
    const selectC1Handler = (c1: string, categories: string[]) => {
        form.getFieldInfo('category1').instance?.handleChange(c1);
        const c2 = getSubcategories(c1, 0, 1, categories);
        setSelectableCategory2(c2);
        selectC2Handler(c2[0], categories);
    };

    return (
        <ThemeProvider theme={ProcessPopupTheme}>
            <ClickAwayListener onClickAway={handleEnvProcessFlowClickOff}>
                <FormGroup>
                    <Box
                        className={`select-process-popup__parent ${classes.SelectProcessPopupParent}`}
                    >
                        <Paper square>
                            <button onClick={fetchVals}
                                style={{ position: 'absolute' }}

                            >TEST</button>
                            <Box
                                className={`select-process-popup__content ${classes.SelectProcessPopupContent}`}
                            >
                                <Box className={classes.ProcessTopWrapper}>
                                    <Typography
                                        className='select-process-popup__title'
                                        component='h2'
                                        variant='h2'
                                        sx={{ marginTop: '1rem' }}
                                    >
                                        Select Process
                                    </Typography>
                                    <ThemeProvider theme={ExitButton}>
                                        <Button
                                            className='social-process-exit-button'
                                            onClick={openProcessSelect}
                                            sx={{ marginRight: '0rem', marginTop: '0rem' }}
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </ThemeProvider>
                                </Box>

                                <Box className={classes.ProcessMiddleWrapper}>
                                    <Box className={classes.DropdownWrapperProcess} sx={{ width: '-webkit-fill-available' }}>
                                        <form.Field
                                            name='category1'
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableCategory1}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            selectC1Handler(e.target.value, loadedCategories);
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        
                                                    />
                                                )
                                            }}
                                        />
                                        <form.Field
                                            name='category2'
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableCategory2}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            selectC2Handler(e.target.value, loadedCategories);
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                    />
                                                )
                                            }}
                                        />
                                        <Box sx={{ display: 'flex', gap: '5px', width: 'max-content', height: '-webkit-fill-available' }}>
                                            <form.Field
                                                name='useCategorySearch'
                                                children={(field) => {
                                                    return (
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    checked={field.state.value}
                                                                    onChange={(e) => {
                                                                        field.handleChange(Boolean(e.target.checked));
                                                                        setCurrentPage(1);
                                                                        submitProcessSearch(1)
                                                                    }}
                                                                    sx={{
                                                                        padding: '2px',
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                        fontSize: '0.8vw',
                                                                    }}
                                                                />
                                                            )}
                                                            label='Use Categories'
                                                            className={classes.CategoryBox}
                                                            sx={{
                                                                padding: '10px',
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                            }}
                                                        />
                                                    )
                                                }}
                                            />
                                            <Box sx={{ display: 'flex' }} className={classes.ProcessSearchBar}>
                                                <form.Field
                                                    name='searchKey'
                                                    children={(field) => {
                                                        return (
                                                            <TextField
                                                                id={field.name}
                                                                className={field.name}
                                                                value={field.state.value}
                                                                onBlur={field.handleBlur}
                                                                onChange={(e) => { return field.handleChange(e.target.value) }}
                                                                // placeholder='Search for process'

                                                                sx={{
                                                                    width: '11vw',
                                                                    // padding: '5px',
                                                                    '& .MuiInputBase-input': {
                                                                        width: '11vw',
                                                                        padding: '0.45vw',
                                                                    },
                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                        border: 'none',
                                                                    },
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                />
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        setCurrentPage(1);
                                                        submitProcessSearch(1);
                                                    }}
                                                    className={classes.BackgroundBorderRemove}
                                                >
                                                    <SearchIcon
                                                        sx={{ fontSize: '1.2vw' }}
                                                    />
                                                </button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={`processes ${classes.ProcessBottomWrapper}`}>
                                    {openProcessFlow && selectedProcessDetails && (
                                        <Portal disablePortal>
                                            <Box
                                                sx={{
                                                    position: 'fixed !important',
                                                    transform: 'none !important',
                                                    top: '0 !important',
                                                    left: '0 !important',
                                                    zIndex: 1300,
                                                    width: '100%',
                                                    height: '-webkit-fill-available',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                }}
                                            >
                                                <ProcessFlowDetails
                                                    handleEnvProcessFlowClick={
                                                        handleEnvProcessFlowClick
                                                    }
                                                    handleEnvProcessFlowClickOff={
                                                        handleEnvProcessFlowClickOff
                                                    }
                                                    processId={selectedProcessDetails.id}
                                                    dimension = 'social'
                                                />
                                            </Box>
                                        </Portal>
                                    )}
                                    {loading ? (
                                        <Typography sx={{ fontSize: '0.7vw' }}>Loading processes...</Typography>
                                    ) : loadedProcesses && loadedProcesses.length > 0 ? (
                                        loadedProcesses
                                            .filter((process, index) => { return index <= 100 })
                                            .map((process, index) => {
                                                return (
                                                    <Box
                                                        key={process.name + index.toString()}
                                                        className={classes.BoxListItem}
                                                    >
                                                        <Typography
                                                            className='select-process'
                                                            variant='body1'
                                                        >
                                                            {process.name}
                                                        </Typography>
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => {
                                                                setSelectedProcessDetails(process);
                                                                handleEnvProcessFlowClick()
                                                            }} className={classes.ButtonEditProcess}
                                                        >
                                                            Details
                                                        </Button>
                                                        <Button
                                                            variant='contained'
                                                            onClick={(e) => {
                                                                selectProcessHandler(e, process);
                                                            }}
                                                            className={classes.ButtonEditProcess}
                                                        >
                                                            Select
                                                        </Button>
                                                    </Box>
                                                )
                                            })
                                    ) : (
                                        <Typography>No processes available</Typography>
                                    )}
                                </Box>
                                <Box
                                    className='process-page-nums'
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '5px',
                                        width: '-webkit-fill-available',
                                        flexErap: 'wrap',
                                        padding: '5px',
                                        paddingTop: '2px',
                                        paddingBottom: '10px',
                                    }}
                                >


                                    <Pagination
                                        count={totalPages.length}
                                        page={currentPage}
                                        onChange={(event, page) => {
                                            setCurrentPage(page);
                                            submitProcessSearch(page);
                                        }}
                                        className={classes.PopupPageNumbers}
                                        variant='outlined'
                                        shape='rounded'
                                        size='small'
                                    />
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </FormGroup>
            </ClickAwayListener>
        </ThemeProvider>
    );
}

export default SelectEnvironmentalProcessPopup;
