import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import GraphLink from "../../../../style/components/GraphLink";
import GraphSettingsTheme from "../GraphSettings/GraphSettings";
import Popper from "@mui/material/Popper";
import {
    Typography,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    TextField,
    colors,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import Dropdown from "../../../../dropdowns/Dropdown";
import DropdownTheme from "../../../../style/components/DropdownTheme";
import "../GraphSettings/GraphSettings.css";
import ExitButton from "../../../../style/components/ExitButton";
import { useFormContext } from "../../../../forms/FormContext";
import {
    lifeCycleColors,
    sublifeCycleColors,
    elementaryColors,
    processColors,
    palette1,
    palette2,
    palette3,
    palette4,
    treemapRGB,
    productPerformanceColor,
    sdgColor,
} from "../../../../../helper/colors";
import {
    ChartJSData,
    ChartJSOptions,
} from "../../../../../interface/ChartData";
import { useForm } from "@tanstack/react-form";
import "./GraphSettingsOptions.css";
import { Chart } from "chart.js";
import deepClone from "../../../../../helper/deepClone";

interface GraphSettingsOptionsProps {
  graphSettingHandleClick: () => void;
  chartOptions: ChartJSOptions | undefined;
  setApplyGraphSettings: React.Dispatch<
    React.SetStateAction<
      | ((
          options: ChartJSOptions,
          chartData: ChartJSData
        ) => {
          options: ChartJSOptions;
          chartData: ChartJSData;
        })
      | undefined
    >
  >;
  triggerChartUpdate: () => void;
  chartColorType?: string;
  chartData: ChartJSData;
  isSettingsVisible: boolean;
  setIsSettingsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  noColors?: boolean;
  graphType?: string;
}

interface GraphSettingsForm {
  valueFormat: string;
  xFontFamily: string;
  xFontWeight: string;
  xFontSize: number;
  yFontFamily: string;
  yFontWeight: string;
  yFontSize: number;
  datalabelFontFamily: string;
  datalabelFontWeight: string;
  datalabelFontSize: number;
  datalabelDisplay: boolean;
  colorPalette: string;
  legendFontFamily: string;
  legendFontWeight: string;
  legendFontSize: number;
  scaleType: string;
  noColors: boolean;
}

function getDynamicFontSize(baseFontSize) {
    const scaleFactor = window.innerWidth / 1920; // Assuming 1920px is the base width for scaling
    return Math.max(10, Math.round(baseFontSize * scaleFactor));
}

function GraphSettingsOptions(props: GraphSettingsOptionsProps) {
    const {
        graphSettingHandleClick,
        setApplyGraphSettings,
        triggerChartUpdate,
        chartColorType,
        isSettingsVisible,
        setIsSettingsVisible,
        noColors: propNoColors, // Renamed to avoid confusion
        graphType,
        // labelSettings = {
        //     fontFamily: "Arial",
        //     fontWeight: "500",
        //     fontSize: 14,
        // },
        // setLabelSettings,
    } = props;

    const { formState, setFormState } = useFormContext();

    // Set options here
    const [selectableValueFormat, setSelectableValueFormat] = useState<string[]>([
        "Decimal",
        "Exponential",
    ]);
    const [selectableXFontFamily, setSelectableXFontFamily] = useState<string[]>([
        "Roboto",
        "Times New Roman",
        "Montserrat",
        "Arial",
    ]);
    const [selectableXFontWeight, setSelectableXFontWeight] = useState<string[]>([
        "500",
        "300",
        "700",
        "900",
    ]);
    const [selectableYFontFamily, setSelectableYFontFamily] = useState<string[]>([
        "Roboto",
        "Times New Roman",
        "Montserrat",
        "Arial",
    ]);
    const [selectableYFontWeight, setSelectableYFontWeight] = useState<string[]>([
        "500",
        "300",
        "700",
        "900",
    ]);
    const [selectableDatalabelFontFamily, setSelectableDatalabelFontFamily] =
    useState<string[]>(["Roboto", "Times New Roman", "Montserrat", "Arial"]);
    const [selectableDatalabelFontWeight, setSelectableDatalabelFontWeight] =
    useState<string[]>(["500", "300", "700", "900"]);
    const [selectableColorPaletteValues, setSelectableColorPaletteValues] =
    useState<string[][]>([
        processColors,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPaletteLifeCycleValues,
        setSelectableColorPaletteLifeCycleValues,
    ] = useState<string[][]>([
        lifeCycleColors,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPaletteSubLifeCycleValues,
        setSelectableColorPaletteSubLifeCycleValues,
    ] = useState<string[][]>([
        sublifeCycleColors,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPaletteStakeholderValues,
        setSelectableColorPaletteStakeholderValues,
    ] = useState<string[][]>([
        treemapRGB,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPaletteElementaryValues,
        setSelectableColorPaletteElementaryValues,
    ] = useState<string[][]>([
        elementaryColors,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPalettePerformanceValues,
        setSelectableColorPalettePerformanceValues,
    ] = useState<string[][]>([
        productPerformanceColor,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [selectableColorPaletteSDGValues, setSelectableColorPaletteSDGValues] =
    useState<string[][]>([sdgColor, palette1, palette2, palette3, palette4]);
    const [selectableColorPaletteNames, setSelectableColorPaletteNames] =
    useState<string[]>([
        "Main Color",
        "Sunset",
        "Multicolored",
        "Warm",
        "Cold",
    ]);
    const [selectableLegendFontFamily, setSelectableLegendFontFamily] = useState<
    string[]
  >(["Roboto", "Times New Roman", "Montserrat", "Arial"]);
    const [selectableLegendFontWeight, setSelectableLegendFontWeight] = useState<
    string[]
  >(["500", "300", "700", "900"]);
    const [selectableScaleType, setSelectableScaleType] = useState<string[]>([
        "linear",
        "logarithmic",
    ]);

    // Set defaults here
    const graphSettingsFormDefaults: GraphSettingsForm = {
        valueFormat: formState?.valueFormat || "Decimal",
        xFontFamily: formState.xFontFamily || selectableXFontFamily[0],
        xFontWeight: formState.xFontWeight || selectableXFontWeight[0],
        xFontSize: formState.xFontSize || 16,
        yFontFamily: formState.yFontFamily || selectableYFontFamily[0],
        yFontWeight: formState.yFontWeight || selectableYFontWeight[0],
        yFontSize: formState.yFontSize || 16,
        datalabelFontFamily:
      formState.datalabelFontFamily || selectableDatalabelFontFamily[0],
        datalabelFontWeight:
      formState.datalabelFontWeight || selectableDatalabelFontWeight[0],
        datalabelFontSize: formState.datalabelFontSize || 12,
        datalabelDisplay: formState.datalabelDisplay || true,
        colorPalette: formState.colorPalette || selectableColorPaletteNames[0],
        noColors:
      formState.noColors !== undefined
          ? formState.noColors
          : propNoColors || false,
        legendFontFamily:
      formState.legendFontFamily || selectableLegendFontFamily[0],
        legendFontWeight:
      formState.legendFontWeight || selectableLegendFontWeight[0],
        legendFontSize: formState.legendFontSize || 16,
        scaleType: formState.scaleType || "linear",
    };

    const form = useForm<GraphSettingsForm>({
        defaultValues: graphSettingsFormDefaults,
        onSubmit: ({ value }) => {
            setFormState({
                ...value,
            });
        },
    });

    const findColorPaletteByName = (name: string): string[] => {
        const paletteIndex = selectableColorPaletteNames.indexOf(name);
        if (chartColorType === "lcs") {
            return (
                selectableColorPaletteLifeCycleValues[paletteIndex] ||
        selectableColorPaletteLifeCycleValues[0]
            );
        } else if (chartColorType === "lcss") {
            return (
                selectableColorPaletteSubLifeCycleValues[paletteIndex] ||
        selectableColorPaletteSubLifeCycleValues[0]
            );
        } else if (chartColorType === "process") {
            return (
                selectableColorPaletteValues[paletteIndex] ||
        selectableColorPaletteValues[0]
            );
        } else if (chartColorType === "elementary") {
            return (
                selectableColorPaletteElementaryValues[paletteIndex] ||
        selectableColorPaletteElementaryValues[0]
            );
        } else if (chartColorType === "treemap") {
            return (
                selectableColorPaletteStakeholderValues[paletteIndex] ||
        selectableColorPaletteStakeholderValues[0]
            );
        } else if (chartColorType === "performance") {
            return (
                selectableColorPalettePerformanceValues[paletteIndex] ||
        selectableColorPalettePerformanceValues[0]
            );
        } else if (chartColorType === "sdg") {
            return (
                selectableColorPaletteSDGValues[paletteIndex] ||
        selectableColorPaletteSDGValues[0]
            );
        } else {
            return (
                selectableColorPaletteValues[paletteIndex] ||
        selectableColorPaletteValues[1]
            );
        }
    };

    useEffect(() => {
        if (chartColorType === "lcs") {
            setSelectableColorPaletteNames([
                "LCS Colors",
                "Sunset",
                "Multicolored",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "lcss") {
            setSelectableColorPaletteNames([
                "LCSS Colors",
                "Sunset",
                "Multicolored",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "process") {
            setSelectableColorPaletteNames([
                "Process Colors",
                "Sunset",
                "Multicolored",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "elementary") {
            setSelectableColorPaletteNames([
                "Elementary Colors",
                "Sunset",
                "Multicolored",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "treemap") {
            setSelectableColorPaletteNames([
                "Stakeholder Colors",
                "Sunset",
                "Multicolored",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "performance") {
            setSelectableColorPaletteNames([
                "Performance Color",
                "Sunset",
                "Multicolored",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "sdg") {
            setSelectableColorPaletteNames([
                "SDG Color",
                "Sunset",
                "Multicolored",
                "Warm",
                "Cold",
            ]);
        } else {
            setSelectableColorPaletteNames([
                "Sunset",
                "Multicolored",
                "Warm",
                "Cold",
            ]);
        }
    }, [chartColorType]);

    const getColorPalette = (): string[] => {
        let palette: string[] = [];
        if (chartColorType === "lcs") {
            palette = lifeCycleColors;
        } else if (chartColorType === "lcss") {
            palette = sublifeCycleColors;
        } else if (chartColorType === "process") {
            palette = processColors;
        } else if (chartColorType === "elementary") {
            palette = elementaryColors;
        } else if (chartColorType === "treemap") {
            palette = treemapRGB;
        } else if (chartColorType === "performance") {
            palette = productPerformanceColor;
        } else if (chartColorType === "sdg") {
            palette = sdgColor;
        } else {
            palette = findColorPaletteByName(form.state.values.colorPalette);
        }
        palette = findColorPaletteByName(form.state.values.colorPalette);

        return palette;
    };

    const applyColorPalette = (chartData: ChartJSData): ChartJSData => {
        if (form.state.values.noColors) {
            return chartData;
        }
        const palette = getColorPalette();
        return {
            ...chartData,
            datasets: chartData.datasets.map((dataset, index) => ({
                ...dataset,
                backgroundColor: palette[index % palette.length],
            })),
        };
    };

    const handleSettingsClose = () => {
        setIsSettingsVisible(false);
    };

    const [notation, setNotation] = useState("Decimal");

    // const applyGraphSettings = (options: ChartJSOptions, chartData: ChartJSData): {
    //     options: ChartJSOptions;
    //     chartData: ChartJSData;
    // } => {
    //     const newChartOptions = {
    //         ...options,
    //         scales: {
    //             ...options?.scales,
    //             x: {
    //                 ...options?.scales?.x,
    //                 ticks: {
    //                     ...options?.scales?.x?.ticks,
    //                     font: {
    //                         ...options?.scales?.x?.ticks?.font,
    //                         family: form.state.values.xFontFamily,
    //                         weight: form.state.values.xFontWeight,
    //                         size: form.state.values.xFontSize,
    //                     },
    //                 },
    //             },
    //             y: {
    //                 ...options?.scales?.y,
    //                 ticks: {
    //                     ...options?.scales?.y?.ticks,
    //                     font: {
    //                         ...options?.scales?.y?.ticks?.font,
    //                         family: form.state.values.yFontFamily,
    //                         weight: form.state.values.yFontWeight,
    //                         size: form.state.values.yFontSize,
    //                     },
    //                     callback: function (value) {
    //                         const format = form.state.values.valueFormat || 'Decimal';
    //                         return format === 'Decimal' ? value : value.toExponential();
    //                     },
    //                 },
    //             },
    //         },
    //         plugins: {
    //             ...options?.plugins,
    //             legend: {
    //                 ...options?.plugins?.legend,
    //                 labels: {
    //                     ...options?.plugins?.legend?.labels,
    //                     font: {
    //                         ...options?.plugins?.legend?.labels?.font,
    //                         size: form.state.values.legendFontSize,
    //                         family: form.state.values.legendFontFamily,
    //                         weight: form.state.values.legendFontWeight,
    //                     },
    //                 },
    //             },
    //             datalabels: {
    //                 ...options?.datalabels,
    //                 font: {
    //                     ...options?.datalabels?.font,
    //                     size: form.state.values.datalabelFontSize,
    //                     family: form.state.values.datalabelFontFamily,
    //                     weight: form.state.values.datalabelFontWeight,
    //                 },
    //             },
    //         },
    //     };
    //     console.log(newChartOptions);

    //     const newChartData = applyColorPalette(chartData);

    //     return {
    //         options: newChartOptions,
    //         chartData: newChartData,
    //     };
    // };

    function vwToPixels(vw, maxBoxWidth) {
        const width =
      Math.max(
          document.documentElement.clientWidth || 1,
          window.innerWidth || 1
      ) *
      (vw / 100);
        return Math.min(width, maxBoxWidth);
    }

    const toVisualScaleType = (value: string): string => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const toInternalScaleType = (value: string): string => {
        return value.toLowerCase(); // Convert back to lowercase
    };

    const applyGraphSettings = (
        options: ChartJSOptions,
        chartData: ChartJSData
    ): {
    options: ChartJSOptions;
    chartData: ChartJSData;
  } => {
        const isRadial = graphType === "radar" || graphType === "polarArea";
        // function getContrastingColor(backgroundColor: string | string[]): string {

        //     const color = Array.isArray(backgroundColor) ? backgroundColor[0] : backgroundColor;
        
        //     const rgb = color.match(/\d+/g)?.map(Number);
        
        //     if (!rgb || rgb.length < 3) return "black"; 
        
        //     const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
        //     return brightness > 200 ? "black" : "white"; 
        // }
        function hexToRgb(hex) {
            let r = 0, g = 0, b = 0;
            if (hex.length === 4) {
                r = parseInt(hex[1] + hex[1], 16);
                g = parseInt(hex[2] + hex[2], 16);
                b = parseInt(hex[3] + hex[3], 16);
            } else if (hex.length === 7) {
                r = parseInt(hex[1] + hex[2], 16);
                g = parseInt(hex[3] + hex[4], 16);
                b = parseInt(hex[5] + hex[6], 16);
            }
            return [r, g, b];
        }
        
        function getContrastingColor(backgroundColor: string[]) {
            let rgb;
        
            const color = Array.isArray(backgroundColor) ? backgroundColor[0] : backgroundColor;
        
            if (color.startsWith('#')) {
                rgb = hexToRgb(color);
            } else if (color.startsWith('rgb')) {
                rgb = color.match(/\d+/g).map(Number);
            } else {
                console.error("Unknown color format:", color);
                return 'black';
            }
        
            const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
        
            if (graphType === 'radar') {
                return brightness > 50 ? 'black' : '#f7f7f7';

            } else {
                return brightness > 180 ? 'black' : '#f7f7f7';
            }
        }
        

        const newChartOptions = {
            ...options,
            scales: {
                ...options?.scales,
                x: {
                    ...options?.scales?.x,
                    ticks: {
                        ...options?.scales?.x?.ticks,
                        font: {
                            ...options?.scales?.x?.ticks?.font,
                            family: form.state.values.xFontFamily,
                            weight: form.state.values.xFontWeight,
                            size: getDynamicFontSize(form.state.values.xFontSize),
                        },
                        display: !(graphType === 'pie' || graphType === 'radar'),
                    },
                    border: {
                        ...options?.scales?.x?.border,
                        display: !(graphType === 'pie' || graphType === 'radar'),
                    },
                    grid: {
                        ...options?.scales?.x?.grid,
                        display: !(graphType === 'pie' || graphType === 'radar'),

                    },
                },
                y: {
                    ...options?.scales?.y,
                    ticks: {
                        ...options?.scales?.y?.ticks,
                        font: {
                            ...options?.scales?.y?.ticks?.font,
                            family: form.state.values.yFontFamily,
                            weight: form.state.values.yFontWeight,
                            size: getDynamicFontSize(form.state.values.yFontSize),
                        },
                        callback: function (value) {
                            const format = form.state.values.valueFormat || "Decimal";
                            return format === "Decimal"
                                ? value.toLocaleString()
                                : value.toExponential();
                        },
                        display: !(graphType === 'pie' || graphType === 'radar'),
                        formatter: function (value, context) {
                            if (value === null || value === undefined) {
                                return "";
                            }
                            const maxThresholdValue = Math.max(
                                ...chartData.datasets.flatMap((dataset) => dataset.data)
                            );
                            const thresholdPercentage = 0.15;
                            const totalThreshold = maxThresholdValue * thresholdPercentage;
                            const format = form.state.values.valueFormat || "Decimal";
                            if (value < totalThreshold) {
                                return null;
                            }
                            if (format === "Exponential") {
                                return value.toExponential(2);
                            } else {
                                return value.toFixed(2).toLocaleString();
                            }
                        },
                    },
                    title: {
                        ...options?.scales?.y?.title,
                        display: !(graphType === 'pie' || graphType === 'radar'),
                        font: {
                            ...options?.scales?.y?.title?.font,
                            family: form.state.values.yFontFamily,
                            weight: form.state.values.yFontWeight,
                            size: getDynamicFontSize(form.state.values.yFontSize),
                        },
                    },
                    type: form.state.values.scaleType,
                    grid: {
                        ...options?.scales?.y?.grid,
                        display: !(graphType === 'pie' || graphType === 'radar'),
                    },
                    border: {
                        ...options?.scales?.x?.border,
                        display: !(graphType === 'pie' || graphType === 'radar'),
                    },
                },
            },
            plugins: {
                ...options?.plugins,
                legend: {
                    ...options?.plugins?.legend,
                    labels: {
                        ...options?.plugins?.legend?.labels,
                        font: {
                            size: getDynamicFontSize(form.state.values.legendFontSize), // Dynamically scale the font size
                            family: form.state.values.legendFontFamily,
                            weight: form.state.values.legendFontWeight,
                        },
                        boxWidth: vwToPixels(3, 20),
                    },
                },
                datalabels: {
                    ...options?.plugins?.datalabels,

                    // ...options?.datalabels,
                    display: form.state.values.datalabelDisplay,
                    // textStrokeColor: "white",
                    // textStrokeWidth: 2,
                    color: (context: any) => {

                        if (graphType === 'pie') {
                            const backgroundColor = context.dataset.backgroundColor[context.dataIndex];
                            return getContrastingColor(backgroundColor);
                        } else {
                            const dataset = context.dataset;
                            const backgroundColor = dataset.backgroundColor;
                            return getContrastingColor(backgroundColor); 
                        }
                    },
                    font: {
                        size: getDynamicFontSize(form.state.values.datalabelFontSize), // Dynamically scale the font size
                        family: form.state.values.datalabelFontFamily,
                        weight: form.state.values.datalabelFontWeight,
                    },
                
                    // anchor: graphType === 'pie' || graphType === 'stacked' ? 'center' : 'end',
                    anchor: 'center',
                    align: 'center',  
                    clip: false,
                    rotation: 0,
                    formatter: function (value, context) {
                        if (value === null || value === undefined) {
                            return "";
                        }

                        const maxThresholdValue = Math.max(
                            ...chartData.datasets.flatMap((dataset) => dataset.data)
                        );
                        const thresholdPercentage = 0.15;
                        const totalThreshold = maxThresholdValue * thresholdPercentage;
                        const format = form.state.values.valueFormat || "Decimal";
                        if (value < totalThreshold) {
                            return null;
                        }
                        if (format === "Exponential") {
                            return value.toExponential(2);
                        } else {
                            return value.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            });
                        }
                    },
                    textShadowBlur: 5,
                    textShadowColor: 'rgba(0, 0, 0, 1)',
                },
            },
        };

        const newChartData = applyColorPalette(chartData);

        return {
            options: newChartOptions,
            chartData: newChartData,
            datalabelFontFamily: form.state.values.datalabelFontFamily,
        };
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         const chart = Chart.getChart("graph1Canvas");
    //         if (chart) {
    //             chart.options.scales.x.ticks.font.size = getDynamicFontSize(form.state.values.xFontSize);
    //             chart.options.scales.y.ticks.font.size = getDynamicFontSize(form.state.values.yFontSize);
    //             chart.options.plugins.legend.labels.font.size = getDynamicFontSize(form.state.values.legendFontSize);
    //             chart.options.plugins.datalabels.font.size = getDynamicFontSize(form.state.values.datalabelFontSize);
    //             chart.update();
    //         }
    //     };

    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, [form.state.values.xFontSize, form.state.values.yFontSize, form.state.values.legendFontSize, form.state.values.datalabelFontSize]);

    useEffect(() => {
        if (setApplyGraphSettings) {
            setApplyGraphSettings(() => applyGraphSettings);
        }
    }, [setApplyGraphSettings, chartColorType, form.state.values.colorPalette]);

    useEffect(() => {
        if (!formState) {
            // Set defaults here
            setFormState(graphSettingsFormDefaults);
        }
    }, []);

    const handleFontFamilyChange = (e) => {
        setLabelSettings((prev) => ({ ...prev, fontFamily: e.target.value }));
        triggerChartUpdate();
    };

    const handleFontWeightChange = (e) => {
        setLabelSettings((prev) => ({ ...prev, fontWeight: e.target.value }));
        triggerChartUpdate();
    };

    const handleFontSizeChange = (e) => {
        setLabelSettings((prev) => ({ ...prev, fontSize: Number(e.target.value) }));
        triggerChartUpdate();
    };

    const handleFontColorChange = (e) => {
        setLabelSettings((prev) => ({ ...prev, color: e.target.value }));
        triggerChartUpdate();
    };

    return (
        <ThemeProvider theme={GraphSettingsTheme}>
            <Box
                sx={{
                    width: "14vw",
                    height: "-webkit-fill-available",
                    backgroundColor: "white",
                    borderLeft: "2px solid #32463E",
                    padding: "1rem",
                    position: "fixed",
                    right: 0,
                    top: "3vw",
                    zIndex: 100,
                    overflowY: "scroll",
                    display: isSettingsVisible ? "block" : "none",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        component="h2"
                        variant="h2"
                        onClick={() => {
                            console.log(formState);
                        }}
                        sx={{
                            textAlign: "left",
                            "@media (max-width: 1680px)": {
                                marginLeft: "-0.2vw",
                            },
                        }}
                    >
            Graph Settings
                    </Typography>
                    <ThemeProvider theme={ExitButton}>
                        <Button
                            variant="contained"
                            onClick={handleSettingsClose}
                            // onClick={isSettingsVisible}
                            className="button-closer"
                        >
                            <CloseIcon />
                        </Button>
                    </ThemeProvider>
                </Box>
                <ThemeProvider theme={DropdownTheme}>
                    <Box>
                        <Stack sx={{ marginBottom: "1vh" }} direction="column" spacing={2}>
                            <Typography component="h3" variant="h3">
                Value Notation
                            </Typography>
                            <Divider />
                            <Typography component="h4" variant="h4">
                Format
                            </Typography>
                            <FormControl
                                sx={{
                                    width: "-webkit-fill-available",
                                    "@media (max-width: 1680px)": {
                                        width: "-webkit-fill-available",
                                    },
                                }}
                                size="small"
                            >
                                <form.Field
                                    name="valueFormat"
                                    children={(field) => {
                                        return (
                                            <Dropdown
                                                options={selectableValueFormat}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                    triggerChartUpdate();
                                                    form.handleSubmit();
                                                }}
                                                id={field.name}
                                                className={field.name}
                                                value={field.state.value}
                                                onBlur={field.handleBlur}
                                                noDefaults
                                            />
                                        );
                                    }}
                                />
                            </FormControl>
                        </Stack>
                        {graphType !== "treemap" && graphType !== "pie" && (
                            <div className="temp-wrap-stackbox">
                                <Stack
                                    sx={{ marginBottom: "1vh" }}
                                    direction="column"
                                    spacing={2}
                                >
                                    <Typography component="h3" variant="h3">
                    Axis X
                                    </Typography>
                                    <Divider />
                                    <Typography component="h4" variant="h4">
                    Font
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="xFontFamily"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableXFontFamily}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Family"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="xFontWeight"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableXFontWeight}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Weight"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "5vw",
                                            "@media (max-width: 1680px)": {
                                                width: "3vw",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="xFontSize"
                                            children={(field) => {
                                                return (
                                                    <TextField
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => {
                                                            field.handleChange(Number(e.target.value));
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        // onChange={(e) => {
                                                        //     const value = Number(e.target.value);
                                                        //     console.log('New xFontSize:', value);
                                                        //     field.handleChange(value);
                                                        // }}
                                                        label="Size"
                                                        type="number"
                                                        size="small"
                                                        // sx={{
                                                        //     width: '11rem',
                                                        //     '& .MuiInputBase-input': {
                                                        //         width: '11rem',
                                                        //     },
                                                        // }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack
                                    sx={{ marginBottom: "1vh" }}
                                    direction="column"
                                    spacing={2}
                                >
                                    <Typography component="h3" variant="h3">
                    Axis Y
                                    </Typography>
                                    <Divider />
                                    <Typography component="h4" variant="h4">
                    Font
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="yFontFamily"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableYFontFamily}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Family"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="yFontWeight"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableYFontWeight}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Weight"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "5vw",
                                            "@media (max-width: 1680px)": {
                                                width: "3vw",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="yFontSize"
                                            children={(field) => {
                                                return (
                                                    <TextField
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => {
                                                            field.handleChange(Number(e.target.value));
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        required
                                                        label="Size"
                                                        type="number"
                                                        defaultValue={1}
                                                        size="small"
                                                        // sx={{
                                                        //     width: '11rem',
                                                        //     '& .MuiInputBase-input': {
                                                        //         width: '11rem',
                                                        //     },
                                                        // }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                            </div>
                        )}
                        <Stack sx={{ marginBottom: "1vh" }} direction="column" spacing={2}>
                            <Typography component="h3" variant="h3">
                Datalabels
                            </Typography>
                            <Divider />
                            {/*{graphType !== "treemap" && ( */}
                            <Box
                                sx={{
                                    width: "-webkit-fill-available",
                                    "@media (max-width: 1680px)": {
                                        width: "-webkit-fill-available",
                                    },
                                    textAlign: 'left',

                                }}
                            >
                                <Typography component="h4" variant="h4">
                    Font
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.state.values.datalabelDisplay}
                                            onChange={(e) => {
                                                form.setFieldValue(
                                                    "datalabelDisplay",
                                                    e.target.checked
                                                );
                                                triggerChartUpdate();
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="Display Datalabels"
                                />
                                <FormControl
                                    sx={{
                                        width: "-webkit-fill-available",
                                        "@media (max-width: 1680px)": {
                                            width: "-webkit-fill-available",
                                        },
                                    }}
                                    size="small"
                                >
                                    <form.Field
                                        name="datalabelFontFamily"
                                        children={(field) => {
                                            return (
                                                <Dropdown
                                                    options={selectableDatalabelFontFamily}
                                                    onChange={(e) => {
                                                        field.handleChange(e.target.value);
                                                        triggerChartUpdate();
                                                        form.handleSubmit();
                                                    }}
                                                    id={field.name}
                                                    className={field.name}
                                                    value={field.state.value}
                                                    onBlur={field.handleBlur}
                                                    noDefaults
                                                    label="Family"
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        width: "-webkit-fill-available",
                                        "@media (max-width: 1680px)": {
                                            width: "-webkit-fill-available",
                                        },
                                    }}
                                    size="small"
                                >
                                    <form.Field
                                        name="datalabelFontWeight"
                                        children={(field) => {
                                            return (
                                                <Dropdown
                                                    options={selectableDatalabelFontWeight}
                                                    onChange={(e) => {
                                                        field.handleChange(e.target.value);
                                                        triggerChartUpdate();
                                                        form.handleSubmit();
                                                    }}
                                                    id={field.name}
                                                    className={field.name}
                                                    value={field.state.value}
                                                    onBlur={field.handleBlur}
                                                    noDefaults
                                                    label="Weight"
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    className="css-weznp9-MuiFormControl-root"
                                    sx={{
                                        width: "5vw",
                                        marginTop:'1.2vh',
                                        "@media (max-width: 1680px)": {
                                            width: "3vw",
                                        },
                                    }}
                                    size="small"
                                >
                                    <form.Field
                                        name="datalabelFontSize"
                                        children={(field) => {
                                            return (
                                                <TextField
                                                    id={field.name}
                                                    className={field.name}
                                                    value={field.state.value}
                                                    onBlur={field.handleBlur}
                                                    onChange={(e) => {
                                                        field.handleChange(Number(e.target.value));
                                                        triggerChartUpdate();
                                                        form.handleSubmit();
                                                    }}
                                                    required
                                                    label="Size"
                                                    type="number"
                                                    defaultValue={1}
                                                    size="small"
                                                    // sx={{
                                                    //     width: '11rem',
                                                    //     '& .MuiInputBase-input': {
                                                    //         width: '11rem',
                                                    //     },
                                                    // }}
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            {/* )} */}

                        </Stack>
                        {graphType !== "treemap" && chartColorType !== "treemap" && (
                            <div className="temp-wrap-stackbox">
                                <Stack
                                    sx={{ marginBottom: "1vh" }}
                                    direction="column"
                                    spacing={2}
                                >
                                    <Typography component="h3" variant="h3">
                    Colors
                                    </Typography>
                                    <Divider />
                                    <Typography component="h4" variant="h4">
                    Palette
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="colorPalette"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableColorPaletteNames}
                                                        onChange={({ target: { value } }) => {
                                                            try {
                                                                field.handleChange(value);
                                                                triggerChartUpdate();
                                                                form.handleSubmit();
                                                            } catch (error) {
                                                                console.error(
                                                                    "Error updating chart or submitting form:",
                                                                    error
                                                                );
                                                            }
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Palette"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        {/* <label style={{fontSize: '0.7vw'}}>Lock Original Colors</label>
                                <input
                                    type="checkbox"
                                    checked={form.state.values.noColors}
                                    onChange={(e) => {
                                        form.setFieldValue('noColors', e.target.checked);
                                        form.handleSubmit();
                                    }}
                                /> */}
                                        {/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.state.values.noColors}
                                            onChange={(e) => {
                                                form.setFieldValue('noColors', e.target.checked);
                                                if (e.target.checked) {
                                                    form.setFieldValue('colorPalette', 'Process Colors', 'Elementary Colors', 'Stakeholder Colors', 'Performance Colors');
                                                }
                                                triggerChartUpdate();
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="Lock Original Colors"
                                /> */}
                                    </FormControl>
                                </Stack>

                                <Stack
                                    sx={{ marginBottom: "1vh" }}
                                    direction="column"
                                    spacing={2}
                                >
                                    <Typography component="h3" variant="h3">
                    Legend
                                    </Typography>
                                    <Divider />
                                    <Typography component="h4" variant="h4">
                    Font
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="legendFontFamily"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableLegendFontFamily}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Family"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="legendFontWeight"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableLegendFontWeight}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Weight"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "5vw",
                                            "@media (max-width: 1680px)": {
                                                width: "3vw",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="legendFontSize"
                                            children={(field) => {
                                                return (
                                                    <TextField
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => {
                                                            field.handleChange(Number(e.target.value));
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        required
                                                        label="Size"
                                                        type="number"
                                                        defaultValue={1}
                                                        size="small"
                                                        // sx={{
                                                        //     width: '11rem',
                                                        //     '& .MuiInputBase-input': {
                                                        //         width: '11rem',
                                                        //     },
                                                        // }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                            </div>
                        )}
                        {graphType !== "treemap" && graphType !== "pie" && (
                            <Stack
                                sx={{ marginBottom: "1vh" }}
                                direction="column"
                                spacing={2}
                            >
                                <Typography component="h3" variant="h3">
                  Scales
                                </Typography>
                                <Divider />
                                <Typography component="h4" variant="h4">
                  Type
                                </Typography>
                                <FormControl
                                    sx={{
                                        width: "-webkit-fill-available",
                                        "@media (max-width: 1680px)": {
                                            width: "-webkit-fill-available",
                                        },
                                    }}
                                    size="small"
                                >
                                    <form.Field
                                        name="scaleType"
                                        children={(field) => {
                                            return (
                                                <Dropdown
                                                    options={["Linear", "Logarithmic"]}
                                                    onChange={(e) => {
                                                        field.handleChange(
                                                            toInternalScaleType(e.target.value)
                                                        );
                                                        triggerChartUpdate();
                                                        form.handleSubmit();
                                                    }}
                                                    id={field.name}
                                                    className={field.name}
                                                    value={toVisualScaleType(field.state.value)}
                                                    onBlur={field.handleBlur}
                                                    noDefaults
                                                    label="Scale Type"
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Stack>
                        )}
                    </Box>
                </ThemeProvider>
            </Box>
        </ThemeProvider>
    );
}

export default GraphSettingsOptions;
