import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './C1BarSPA.styles';
import { Bar } from 'react-chartjs-2';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { SPAPSILCAPriorityResult, Product, SPALCSProcessResult, SPALifeCycleResult, SPAResult, SPAStakeholderResult } from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { palette1, palette2, palette3, palette4, productPerformanceColor, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { applyWeight, weighingMethods } from '../../../../../../helper/weighingMethod';
import SDGLinkage from '../../../../options/components/SDGLinkage';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function C1BarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();

    const [results, setResults] = useState<SPAResult>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults((calcResults));
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(['All Stakeholders'].concat(calcResults.stakeholderResults.map((item) => { return item.name })));
    }, [product]);


    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Performance Colors": productPerformanceColor,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        };
        return palettes[name] || palettes["Performance Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Weighted Score";
        }

        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        const extendedPalette = generateExtendedPalette(selectedPalette);
        const spaLifeCycle = results.lifeCycleResults.find((lcs) => lcs.lcsName === results.SPAProcess.lifeCycleStage);
        let stakeholders = results.stakeholderResults;

        const processes = results.lifeCycleResults.reduce(
            (items : SPAPSILCAPriorityResult[], lifeCycle) => {
                return items.concat(lifeCycle.processResults[0].PSILCAPriorities);
            },
            []
        );
        let stakeholderResults: SPAStakeholderResult[] = [];
        if (selectedStakeholder !== "All Stakeholders") {
            stakeholders = stakeholders.filter(
                (item) => item.name === selectedStakeholder
            );
            stakeholderResults = stakeholders.map((item) => {
                return {
                    ...item,
                    SPAPriorities: item.SPAPriorities.filter(
                        (item) =>
                            item.category.split(":")[0] === selectedStakeholder
                    ),
                    SPAResults: item.SPAResults.filter(
                        (item) =>
                            item.category.split(":")[0] === selectedStakeholder
                    ),
                };
            });
        }

        const samProcessResults = stakeholders.reduce(
            (scores: {name:string,val:number}[], result) => {
                return scores.concat(
                    result.SPAPriorities.map((item) => {
                        return {
                            name: item.category,
                            val: applyWeight(item, "sam",currentWeighingSet),
                        };
                    }, 0)
                );
            },
            []
        );
        chartData.datasets = [
            {
                label: product.productName,
                data: stakeholders.reduce((data : number[], process) => {
                    const items = process.SPAPriorities.sort(
                        (a, b) =>  a.category.localeCompare(b.category)
                    );
                    if (data.length === 0) {
                        data = items.map((item2) => applyWeight(item2, 'psilca' , currentWeighingSet));
                    } else {
                        console.log(items);
                        data = data.map(
                            (item, index) => item + (items[index] ? applyWeight(items[index] , 'psilca',currentWeighingSet) : 0)
                        );
                    }
                    return data;
                }, []),
                backgroundColor: "#1B5D82", //colors[0], //random color from array
                borderColor: "000000",
            },
        ];

        if (selectedStakeholder !== "All Stakeholders") {
            chartData.labels = processes.filter(
                (item) =>
                    item.category.split(":")[0] === selectedStakeholder
            ).map((process) => process.category);
        }else{
            chartData.labels = processes
                .sort((a, b) =>  a.category.localeCompare(b.category))
                .map((item2) => item2.category);
        }
        
        

        chartData.datasets[0].data = chartData.labels.map(
            (item, index) => {
                const result = samProcessResults.find(
                    (processResult) => processResult.name === item
                );
                if (result) {
                    return chartData.datasets[0].data[index] + result.val;
                } else {
                    return chartData.datasets[0].data[index];
                }
            }
        );



        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedStakeholder, getGraphColorIndex, formState.colorPalette]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options, false , 'pie');
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={0} />
                                    <SDGLinkage
                                        dimension='SPA'
                                        setting='C1'
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={stakeholderOptions}
                                            valueSetter={setSelectedStakeholder}
                                            value={selectedStakeholder}

                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Product System']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData}
                                            chartName={'Subcategory level result - Product System'} 
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='performance'
                                            graphType='barindicator'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C1BarSPA;
