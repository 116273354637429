// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring__small {
  width: 16px !important;
  height: 16px !important;
}


.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #707070;
  border-color: #707070 transparent #707070 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.lds-dual-ring__small:after {
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  margin: 1px;
  border-radius: 50%;
  border: 4px solid #707070;
  border-color: #707070 transparent #707070 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/LoadingSpinner/LoadingSpinner.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;;AAGA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;EACX,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,qDAAqD;EACrD,6CAA6C;AAC/C;AACA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;EACX,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,qDAAqD;EACrD,6CAA6C;AAC/C;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".lds-dual-ring {\r\n  display: inline-block;\r\n  width: 64px;\r\n  height: 64px;\r\n}\r\n\r\n.lds-dual-ring__small {\r\n  width: 16px !important;\r\n  height: 16px !important;\r\n}\r\n\r\n\r\n.lds-dual-ring:after {\r\n  content: \" \";\r\n  display: block;\r\n  width: 46px;\r\n  height: 46px;\r\n  margin: 1px;\r\n  border-radius: 50%;\r\n  border: 5px solid #707070;\r\n  border-color: #707070 transparent #707070 transparent;\r\n  animation: lds-dual-ring 1.2s linear infinite;\r\n}\r\n.lds-dual-ring__small:after {\r\n  content: \" \";\r\n  display: block;\r\n  width: 12px;\r\n  height: 12px;\r\n  margin: 1px;\r\n  border-radius: 50%;\r\n  border: 4px solid #707070;\r\n  border-color: #707070 transparent #707070 transparent;\r\n  animation: lds-dual-ring 1.2s linear infinite;\r\n}\r\n\r\n.loading-spinner__overlay {\r\n  height: 100%;\r\n  width: 100%;\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  background: rgba(255, 255, 255, 0.9);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 1000;\r\n}\r\n\r\n@keyframes lds-dual-ring {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
