import { FormApi } from '@tanstack/react-form';
import deepClone from '../helper/deepClone';
import { FormState } from '../components/forms/FormContext';
import { ProductLCSData } from './Product';
import { SelectProcess } from './Process';

export interface ProductDetailsForm {
  productName: string;
  productDesc: string;
  analysisUnit: string;
  analysisUnitGroup: string;
  analysisUnitQTY: number;
  envDim: boolean;
  socialDim: boolean;
  ecoDim: boolean;
  'Product Stage:Raw Material Supply': boolean;
  'Product Stage:Transport': boolean;
  'Product Stage:Manufacturing': boolean;
  'Construction Stage:Transport': boolean;
  'Construction Stage:Construction - Installation process': boolean;
  'Use Stage:Use': boolean;
  'Use Stage:Maintenance': boolean;
  'Use Stage:Repair': boolean;
  'Use Stage:Replacement': boolean;
  'Use Stage:Refurbishment': boolean;
  'Use Stage:Operational energy use': boolean;
  'Use Stage:Operational water use': boolean;
  'End Of Life Stage:Deconstruction': boolean;
  'End Of Life Stage:Transport to end of life': boolean;
  'End Of Life Stage:Waste processing': boolean;
  'End Of Life Stage:Disposal': boolean;
  'Benefits and Loads Beyond the Life Cycle:Reuse, Recovery or Recycling potential': boolean;
  currentLCS: string;
  currentLCSS: string;
}

export interface EnvInputProcess {
  index: number;
  endProductName: string;
  processName: string;
  processId: string;
  processQuantity: number;
  processUnit: string;
  processUnitGroup: string | undefined;
  processComment: string;
  category: number,
}

export interface SocialInputProcess {
  index: number;
  endProductName: string;
  processName: string;
  processId: string;
  processQuantity: number;
  processComment: string;
  category: number,

}

export interface EnvFlow {
  index: number;
  endProductName: string;
  flowId: string;
  flowName: string;
  flowQuantity: number;
  flowUnit?: string;
  flowUnitGroup: string;
  flowComment: string;
  category: number,

}
export interface EnvironmentalInputsForm {
  envInputProcesses: EnvProcessInputLCS[];
  envInputEmissions: EnvFlowInputLCS[];
  envOutputEmissions: EnvFlowInputLCS[];
  envOutputWaste: EnvFlowInputLCS[];
  currentLCS: string;
  currentLCSS: string;
  envAssessmentMethod: string;
  envNWSet: string;
}

export interface EconomicInputsForm {
  ecoInputProcesses: EnvProcessInputLCS[];
  currentLCS: string;
  currentLCSS: string;
}

export interface SocialInputsForm {
  socialInputProcesses: SocialInputsLCS[];
  currentLCS: string;
  currentLCSS: string;
  socialAssessmentMethod: string;
  socialSPAWeighingMethod: string;
  socialSPAWeighingSet: string;
}

export interface SocialInputsLCS {
  lcs: string;
  values: SocialInputProcess[];
}

export interface EnvProcessInputLCS {
  lcs: string;
  values: EnvInputProcess[];
}
export interface EnvFlowInputLCS {
  lcs: string;
  values: EnvFlow[];
}

export interface LifeCycleStageForm {
  'Product Stage:Raw Material Supply': boolean;
  'Product Stage:Transport': boolean;
  'Product Stage:Manufacturing': boolean;
  'Construction Stage:Transport': boolean;
  'Construction Stage:Construction - Installation process': boolean;
  'Use Stage:Use': boolean;
  'Use Stage:Maintenance': boolean;
  'Use Stage:Repair': boolean;
  'Use Stage:Replacement': boolean;
  'Use Stage:Refurbishment': boolean;
  'Use Stage:Operational energy use': boolean;
  'Use Stage:Operational water use': boolean;
  'End Of Life Stage:Deconstruction': boolean;
  'End Of Life Stage:Transport to end of life': boolean;
  'End Of Life Stage:Waste processing': boolean;
  'End Of Life Stage:Disposal': boolean;
  'Benefits and Loads Beyond the Life Cycle:Reuse, Recovery or Recycling potential': boolean;
}

export const defaultLCSFormValues: LifeCycleStageForm = deepClone({
    'Product Stage:Raw Material Supply': false,
    'Product Stage:Transport': false,
    'Product Stage:Manufacturing': false,
    'Construction Stage:Transport': false,
    'Construction Stage:Construction - Installation process': false,
    'Use Stage:Use': false,
    'Use Stage:Maintenance': false,
    'Use Stage:Repair': false,
    'Use Stage:Replacement': false,
    'Use Stage:Refurbishment': false,
    'Use Stage:Operational energy use': false,
    'Use Stage:Operational water use': false,
    'End Of Life Stage:Deconstruction': false,
    'End Of Life Stage:Transport to end of life': false,
    'End Of Life Stage:Waste processing': false,
    'End Of Life Stage:Disposal': false,
    'Benefits and Loads Beyond the Life Cycle:Reuse, Recovery or Recycling potential':
    false,
});

export const loadValuesFromFormContext = <T> (form : FormApi<T>, formState : FormState, keys : string[]) =>{
    keys.forEach((key) =>{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const val = formState[key];
        if (val) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            form.state.values[key as keyof T] = val;
        }
    });
};

export interface ProductDetailsData{
  productName: string,
  productDesc:string,
  modeling?:string,
  dimensions:{
    environmentalDim:boolean,
    socialDim:boolean,
    economicDim:boolean,
  },
  unitOfAnalysis:{
    unit:string,
    unitGroup:string,
    unitQTY:number,
  },
  lifeCycleStages:ProductLCSData[],
  environmentalData?:{
    assessmentMethod:{
      name:string,
      id:string,
    }
    nwSet:string,
  },
  socialData?:{
    weighingMethod?:{
      name:string,
      set:string,
    }

  },
  economicData?:{

  }
}

export interface EnvCalcData{

}

