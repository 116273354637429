import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LCSDropdown from "./LCSDropdown";
import { ProductDetailsForm, defaultLCSFormValues } from "../../../../interface/Form";
import { useFormContext } from "../../../forms/FormContext";
import { Product } from "../../../../interface/Product";
import { useRouterState } from "@tanstack/react-router";
import './LifeCycleStagesDropdowns.css'

interface Field {
    name: string;
    state: {
        value: boolean;
    };
    handleChange: (value: boolean) => void;
}

function LifeCycleStagesDropdowns(props: { form: any; productToEdit: Product | undefined }) {
    const { form, productToEdit } = props;
    const [openDropdownsStages, setOpenDropdownsStages] = useState<number[]>([0, 1, 2, 3, 4]);
    const { formState } = useFormContext();
    const route = useRouterState();

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectAllCheckedMap, setSelectAllCheckedMap] = useState<Record<number, boolean>>({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
    });

    const [checkboxStates, setCheckboxStates] = useState<Record<string, boolean>>({
        "Product Stage:Raw Material Supply": false,
        "Product Stage:Transport": false,
        "Product Stage:Manufacturing": false,
        "Construction Stage:Transport": false,
        "Construction Stage:Construction - Installation process": false,
        "Use Stage:Use": false,
        "Use Stage:Maintenance": false,
        "Use Stage:Repair": false,
        "Use Stage:Replacement": false,
        "Use Stage:Refurbishment": false,
        "Use Stage:Operational energy use": false,
        "Use Stage:Operational water use": false,
        "End Of Life Stage:Deconstruction": false,
        "End Of Life Stage:Transport to end of life": false,
        "End Of Life Stage:Waste processing": false,
        "End Of Life Stage:Disposal": false,
        "Benefits and Loads Beyond the Life Cycle:Reuse, Recovery or Recycling potential": false,
    });

    const loadLCSInputs = () => {
        const stages: string[] = formState.selectedLCS as string[];
        if (route.location.pathname.includes("edit")) {
            if (stages && stages.length > 0) {
                (Object.keys(form.state.values) as (keyof ProductDetailsForm)[]).forEach((key) => {
                    if (key.includes(":")) {
                        form.setFieldValue(key, stages.includes(key));
                    }
                });
            } else if (productToEdit) {
                productToEdit.lifeCycleStages.forEach((lcs) => {
                    const key = lcs.stage as keyof ProductDetailsForm;
                    form.setFieldValue(key, true);
                });
            }
        } else if (stages && stages.length > 0) {
            (Object.keys(form.state.values) as (keyof ProductDetailsForm)[]).forEach((key) => {
                if (key.includes(":")) {
                    form.setFieldValue(key, stages.includes(key));
                }
            });
        }
    };

    useEffect(() => {
        loadLCSInputs();
    }, [productToEdit]);

    const handleToggleDropdown = (index: number) => {
        setOpenDropdownsStages((prev) => {
            if (prev.includes(index)) {
                return prev.filter((item) => item !== index);
            }
            return [...prev, index];
        });
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setSelectAllChecked(checked);

        const newCheckboxStates = { ...checkboxStates };
        Object.keys(newCheckboxStates).forEach((key) => {
            newCheckboxStates[key] = checked;
            form.setFieldValue(key, checked);
        });
        setCheckboxStates(newCheckboxStates);

        setSelectAllCheckedMap({
            0: checked,
            1: checked,
            2: checked,
            3: checked,
            4: checked,
        });
    };

    const handleSelectStageAllChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setSelectAllCheckedMap((prev) => ({
            ...prev,
            [index]: checked,
        }));

        const stagePrefix = index === 0 ? "Product Stage" : index === 1 ? "Construction Stage" : index === 2 ? "Use Stage" : index === 3 ? "End Of Life Stage" : "Benefits and Loads Beyond the Life Cycle";

        const newCheckboxStates = { ...checkboxStates };
        Object.keys(newCheckboxStates).forEach((key) => {
            if (key.startsWith(stagePrefix)) {
                newCheckboxStates[key] = checked;
                form.setFieldValue(key, checked);
            }
        });
        setCheckboxStates(newCheckboxStates);
    };

    const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const newCheckboxStates = { ...checkboxStates, [name]: checked };
        
        setCheckboxStates((prev) => ({
            ...prev,
            [name]: checked,
        }));
        form.setFieldValue(name, checked);
    
        // Update select all checkbox state based on individual checkboxes
        const stagePrefix = name.split(':')[0];
        const allChecked = Object.keys(newCheckboxStates).filter(key => key.startsWith(stagePrefix)).every(key => newCheckboxStates[key]);
        const stageIndex = stagePrefix === "Product Stage" ? 0 : stagePrefix === "Construction Stage" ? 1 : stagePrefix === "Use Stage" ? 2 : stagePrefix === "End Of Life Stage" ? 3 : 4;
        setSelectAllCheckedMap((prev) => ({
            ...prev,
            [stageIndex]: allChecked,
        }));
    
        // Check if all stages are selected
        const allStagesChecked = Object.values(newCheckboxStates).every(value => value);
        setSelectAllChecked(allStagesChecked);
    };
    

    return (
        <Box className="lc-stage-dropdown__content">
            <Typography component="h2" variant="h2">
                System Boundary Details - Life Cycle Stages
            </Typography>
            <Box className="lc-stage-dropdown-section">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectAllChecked}
                                onChange={handleSelectAllChange}
                            />
                        }
                        label="Select All"
                        labelPlacement="start"
                        sx={{ justifyContent: "flex-end", margin: 0 }}
                    />
                    <List sx={{ display: "flex", flexWrap: "wrap", gap: "0.7vw", justifyContent: "flex-start" }}>
                        <LCSDropdown
                            stage="Product Stage"
                            index={0}
                            handleToggleDropdown={handleToggleDropdown}
                            openDropdownStages={openDropdownsStages}
                            selectStageAllChecked={selectAllCheckedMap[0]}
                            handleSelectStageAllChange={handleSelectStageAllChange(0)}
                        >
                            <form.Field name="Product Stage:Raw Material Supply">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Raw Material Supply"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Product Stage:Transport">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Transport"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Product Stage:Manufacturing">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Manufacturing"
                                    />
                                )}
                            </form.Field>
                        </LCSDropdown>
                        <LCSDropdown
                            stage="Construction Stage"
                            index={1}
                            handleToggleDropdown={handleToggleDropdown}
                            openDropdownStages={openDropdownsStages}
                            selectStageAllChecked={selectAllCheckedMap[1]}
                            handleSelectStageAllChange={handleSelectStageAllChange(1)}
                        >
                            <form.Field name="Construction Stage:Transport">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Transport"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Construction Stage:Construction - Installation process">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Construction - Installation process"
                                    />
                                )}
                            </form.Field>
                        </LCSDropdown>
                        <LCSDropdown
                            stage="Use Stage"
                            index={2}
                            handleToggleDropdown={handleToggleDropdown}
                            openDropdownStages={openDropdownsStages}
                            selectStageAllChecked={selectAllCheckedMap[2]}
                            handleSelectStageAllChange={handleSelectStageAllChange(2)}
                        >
                            <form.Field name="Use Stage:Use">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Use"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Use Stage:Maintenance">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Maintenance"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Use Stage:Repair">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Repair"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Use Stage:Replacement">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Replacement"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Use Stage:Refurbishment">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Refurbishment"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Use Stage:Operational energy use">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Operational energy use"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="Use Stage:Operational water use">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Operational water use"
                                    />
                                )}
                            </form.Field>
                        </LCSDropdown>
                        <LCSDropdown
                            stage="End Of Life Stage"
                            index={3}
                            handleToggleDropdown={handleToggleDropdown}
                            openDropdownStages={openDropdownsStages}
                            selectStageAllChecked={selectAllCheckedMap[3]}
                            handleSelectStageAllChange={handleSelectStageAllChange(3)}
                        >
                            <form.Field name="End Of Life Stage:Deconstruction">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Deconstruction"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="End Of Life Stage:Transport to end of life">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Transport to end of life"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="End Of Life Stage:Waste processing">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Waste processing"
                                    />
                                )}
                            </form.Field>
                            <form.Field name="End Of Life Stage:Disposal">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Disposal"
                                    />
                                )}
                            </form.Field>
                        </LCSDropdown>
                        <LCSDropdown
                            stage="Benefits and Loads Beyond the Life Cycle"
                            index={4}
                            handleToggleDropdown={handleToggleDropdown}
                            openDropdownStages={openDropdownsStages}
                            selectStageAllChecked={selectAllCheckedMap[4]}
                            handleSelectStageAllChange={handleSelectStageAllChange(4)}
                        >
                            <form.Field name="Benefits and Loads Beyond the Life Cycle:Reuse, Recovery or Recycling potential">
                                {(field: Field) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={field.name}
                                                checked={field.state.value}
                                                onChange={(e) => {
                                                    field.handleChange(Boolean(e.target.checked));
                                                    handleCheckboxChange(field.name)(e);
                                                }}
                                            />
                                        }
                                        label="Reuse, Recovery or Recycling potential"
                                    />
                                )}
                            </form.Field>
                        </LCSDropdown>
                    </List>
                </FormGroup>
            </Box>
        </Box>
    );
}

export default LifeCycleStagesDropdowns;
