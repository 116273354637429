// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sunburst {
    width: 100%; 
    height: 100%; 
    display: flex;
    justify-content: center;
    align-items: center; 
    overflow: hidden; 
}

#sunburst svg {
    width: 60vw !important; 
    height: 100% !important; 
    /* max-width: 100%; 
    max-height: 100%;  */
    display: block; 
    margin: 0;
    padding: 0; 
    box-sizing: border-box; 
}
.sunburst-viz {
    width: auto;
}
.sunburst-viz text {
    font-size: 0.7vw;
    font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/components/charts/social/spa/subcategory/C4SunburstSPA/sunburst.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB;wBACoB;IACpB,cAAc;IACd,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":["#sunburst {\r\n    width: 100%; \r\n    height: 100%; \r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center; \r\n    overflow: hidden; \r\n}\r\n\r\n#sunburst svg {\r\n    width: 60vw !important; \r\n    height: 100% !important; \r\n    /* max-width: 100%; \r\n    max-height: 100%;  */\r\n    display: block; \r\n    margin: 0;\r\n    padding: 0; \r\n    box-sizing: border-box; \r\n}\r\n.sunburst-viz {\r\n    width: auto;\r\n}\r\n.sunburst-viz text {\r\n    font-size: 0.7vw;\r\n    font-weight: 900;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
