/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Button,
    styled,
    ThemeProvider,
    tableCellClasses,
    Paper,
    Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import ExitButton from '../../../../../style/components/ExitButton';
import AddButton from '../../../../../style/components/AddButton';
import TableTheme from '../../../../../style/components/TableTheme';
import Popper, { PopperProps } from '@mui/material/Popper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { SocialInputsForm } from '../../../../../../interface/Form';
import { FormApi } from '@tanstack/react-form';
import StakeholderIndicatorsForm from '../SPAStakeholders/stakeholderForms/StakeholderIndicatorsForm';

import { StakeholderIndicators } from '../SPAStakeholders';
import { ClassNames } from '@emotion/react';
import useStyles from './SPAIndicatorsTable.styles';

interface SPAIndicatorsTableProps {
    handleIndicatorPopupClick: (event: React.MouseEvent<HTMLElement>, stakeholder?: string) => void;
    form: FormApi<StakeholderIndicators>
    selectedStakeholderForm: string,
}

function SPAIndicatorsTable({ handleIndicatorPopupClick, form, selectedStakeholderForm }: SPAIndicatorsTableProps) {
    const [processPopupEnv, setProcessPopupEnv] = useState<null | HTMLElement>(
        null,
    );

    const open = Boolean(processPopupEnv);
    const processPopper = open ? 'process-popper' : undefined;

    const classes = useStyles();

    return (
        <ThemeProvider theme={TableTheme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '2rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '-webkit-fill-available',
                }}
            >
                <Paper square>
                    <Box className={classes.ProcessTopWrapper}>
                        <Typography
                            className='select-process-popup__title'
                            component='h2'
                            variant='h2'
                            sx={{ marginTop: '1rem' }}
                        >
                            Select Indicator
                        </Typography>
                        <ThemeProvider theme={ExitButton}>
                            <Button
                                onClick={handleIndicatorPopupClick}
                                sx={{ marginRight: '0rem', marginTop: '0rem' }}
                            >
                                <ClearIcon />
                            </Button>
                        </ThemeProvider>
                    </Box>
           
                    <StakeholderIndicatorsForm form={form} selectedStakeholder={selectedStakeholderForm}/>
 
                </Paper>

            </Box>
        </ThemeProvider>
    );
}

export default SPAIndicatorsTable;
