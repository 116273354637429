export const weighingMethods: WeighingMethod[] = [
    {
        name: "Distance-to-target + Risk-based",
        sets: [
            {
                name: "Weighing set A",
                values: [
                    { level: "High", text: "3/6", float: 3.0 / 6.0 },
                    { level: "Some", text: "2/6", float: 2.0 / 6.0 },
                    { level: "Low", text: "1/6", float: 1.0 / 6.0 },
                ],
            },
            {
                name: "Weighing set B",
                values: [
                    { level: "High", text: "2/3", float: 2.0 / 3.0 },
                    { level: "Some", text: "1/3", float: 1.0 / 3.0 },
                    { level: "Low", text: "0", float: 0 },
                ],
            },
        ],
    },
    { name: "Equal Weighing", sets: [] },
    { name: "Based on norms", sets: [] },
    { name: "Stakeholders' judgement", sets: [] },
];

export interface WeighingMethod {
  name: string;
  sets: WeighingSet[];
}

interface WeighingSetValue {
  level: string;
  text: string;
  float: number;
}

export interface WeighingSet {
  name: string;
  values: WeighingSetValue[];
}

export const applyWeight = (
    item: {
    priority: string;
    scores?: { spa: number; psilca: number };
    score?: number;
  },
    type: "sam" | "psilca",
    currentWeighingSet: WeighingSet
): number => {
    let weightValue = 1;
    if (item.priority.startsWith("High")) {
        weightValue =
      currentWeighingSet.values.find((setItem) => setItem.level === "High")
          ?.float ?? 1;
    } else if (item.priority.startsWith("Some")) {
        weightValue =
      currentWeighingSet.values.find((setItem) => setItem.level === "Some")
          ?.float ?? 1;
    } else if (item.priority.startsWith("Low")) {
        weightValue =
      currentWeighingSet.values.find((setItem) => setItem.level === "Low")
          ?.float ?? 1;
    }

    if (item.scores) {
        if (type === "sam") {
            return (item.scores.spa ?? 0) * weightValue;
        } else if (type === "psilca") {
            return (item.scores.psilca ?? 0) * weightValue;
        }
    }

    return (item.score ?? 0) * weightValue;
};

export const getWeighingSetValue = (
    weighingSet: WeighingSet,
    level: string
): number => {
    const set = weighingSet.values.find((item) => item.level === level);

    if (set) {
        return set.float;
    } else {
        return 1;
    }
};
