import React, { useState } from 'react';
import { Outlet, useMatchRoute } from '@tanstack/react-router';
import { Box, Collapse } from '@mui/material';
import Navbar from '../navbar';
import useStyles from './Layout.style';
import MainMenu from '../mainmenu';
import { Product } from '../../interface/Product';

function Layout() {
    const matchRoute = useMatchRoute();
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [product, setProduct] = useState<Product | null>(null);
    
    const publicRoutes = [
        '/',
        '/signup',
        '/about/whoarewe',
        '/about/ourmission',
        '/ourteam',
        '/bioprofile/ian',
        '/bioprofile/marwa',
        '/bioprofile/getachew',
        '/services',
        '/services/consultingservices',
        '/services/tossasoftware',
        '/services/training',
        '/resources/resourceswhitepp',
        '/resources/webinars',
        '/resources/faq',
        '/contact',
    ];

    const isPublicRoute = publicRoutes.some((route) => matchRoute({ to: route }));
    const isLoginPage = matchRoute({ to: '/login' });
    const isWebsite = isPublicRoute || isLoginPage; // Website includes public routes or login

    const classes = useStyles({ isLoginPage });

    return (
        <Box className={classes.headerSection}>
            {!isWebsite && (
                <>
                    <Navbar open={sidebarOpen} setOpen={setSidebarOpen} product={product} />
                    <Collapse
                        orientation="vertical"
                        in={sidebarOpen}
                        mountOnEnter
                        unmountOnExit
                        sx={{
                            marginTop: '-0.5rem',
                            position: 'fixed',
                            bgcolor: 'white',
                            paddingBottom: 0,
                            color: 'black',
                            transition: '0.3s',
                            zIndex: 10,
                        }}
                    >
                        <MainMenu />
                    </Collapse>
                </>
            )}

            <Box className={classes.bodySection} sx={{ marginTop: isLoginPage || isWebsite ? '0 !important' : '3vw' }}>
                <Box className={sidebarOpen && !isWebsite ? classes.dataSection : classes.wideSection}>
                    <Box className={classes.data} sx={{ padding: isLoginPage || isWebsite ? '0 !important' : '20px !important' }}>
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Layout;
