import { makeStyles } from '@mui/styles';
import { tableCellClasses } from '@mui/material/TableCell';

const useStyles = makeStyles(() => ({
    IAMWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    ProcessTopWrapper: {
        display: 'grid',
        width: '100%',  // Changed from -webkit-fill-available to 100%
        gridTemplateColumns: '1fr auto',
        justifyItems: 'center',
    },
    InputTableCell: {
        position: 'sticky',
        fontSize: '0.7vw !important',
        padding: '0.5vw !important',
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004021',
            color: 'white',
        },
        [`&.${tableCellClasses.body}`]: {
            borderBottom: 0,
        },
        width: '20rem',
        '&:last-of-type': {
            width: '5rem',
        },
        border: 0,

    },
    InputTableRow: {
        padding: '5px',
        fontSize: '0.7vw',
        color: '#707070',
        backgroundColor: '#F3F3F3',
        border: 0,
        // borderBottom: '0.5vw solid white',
        // '&.indicator-table': {
        //     borderBottom: 0,
        // },
        // '&:nth-of-type(odd)': {
        //     backgroundColor: '#F3F3F3',
        // },
        // '&:nth-of-type(even)': {
        //     backgroundColor: '#E6E6E6',
        // },
    },
    ProcessBottomWrapper: {
        display: 'flex',
        columnGap: '1vw',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '1rem',
    },
    TableContainerWrap: {
        maxHeight: '34vw',
        maxWidth: '84vw',
        margin: '2vw',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    scrollableBody: {
        overflowY: 'auto',
        display: 'block',
        // maxHeight: '400px',
    },
}));

export default useStyles;
