import React, { useContext, useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableTheme from '../../../../style/components/TableTheme';
import { createTheme, styled, ThemeProvider } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExitButton from '../../../../style/components/ExitButton';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import SelectEnvironmentalProcessPopup from '../SelectEnvironmentalProcessPopup';
import AddButton from '../../../../style/components/AddButton';
import Popper, { PopperProps } from '@mui/material/Popper';
import { FormApi, useForm } from '@tanstack/react-form';
import {
    EnvironmentalInputsForm,
    EnvInputProcess,
    EnvProcessInputLCS,
} from '../../../../../interface/Form';
import { EnvInputProcessListItem } from '../EnvInputProcessListItem';
import { SelectProcess } from '../../../../../interface/Process';
import { fetchUnitGroups } from '../../../../../helper/fetchUtil';
import { UnitGroup, findUnitUnitGroup } from '../../../../../interface/Unit';
import { EnvInputProcessListItemTemp } from '../EnvInputProcessListItemTemp';
import '../../EnvironmentalInputs.css';
import { useFormContext } from '../../../../forms/FormContext';
import '../../../EconomicInputs/EconomicInputs.css';

const InputTableCell = styled(TableCell)(({ theme }) => {
    return {
        width: '-webkit-fill-available',
        fontSize: '0.7vw',
        lineHeight: '1.1vw',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004021',
            color: 'white',
            height: '3.5vw',
        },
        [`&.${tableCellClasses.body}`]: {
            // fontSize: 14,
        },
        '@media (max-width: 1680px)': {
            padding: '5px',
            // fontSize: 10,
            // lineHeight: '15px',
        },
        '&:last-of-type': {
            width: '2rem',
            padding: '0',
            display: 'flex',
            justifyContent: 'center',
            color: '#004021',
            // height: '5.5rem',
            '@media (max-width: 1680px)': {
                // width: '7rem',
            },
        },
    }
});

interface EnvInputProcessListProps {
    envForm: FormApi<EnvironmentalInputsForm>;
    lifeCycleStage: string;
}

function EnvInputProcessList({
    envForm,
    lifeCycleStage,
}: EnvInputProcessListProps) {
    const [processPopupEnv, setProcessPopupEnv] = useState<null | HTMLElement>(
        null
    );
    const [loadedUnitGroups, setLoadedUnitGroups] = useState<UnitGroup[]>([]);
    const [currentProcesses, setCurrentProcesses] = useState<EnvInputProcess[]>(
        []
    );
    const { formState, setFormState } = useFormContext();

    useEffect(() => {
        fetchUnitGroups()
            .then((val) => {
                setFormState({ ...formState, unitGroups: val });
                setLoadedUnitGroups(val);
            })
            .catch((err) => {
            // Handle the error if needed
            });

    }, []);


    const addNewProcess = (process: SelectProcess) => {
        if (process) {
            const newIndex = currentProcesses.length;
            if (process.unit) {
                const unitGroup = findUnitUnitGroup(process.unit, loadedUnitGroups);
                const newProcess: EnvInputProcess = {
                    index: newIndex,
                    endProductName: '',
                    processId: process.refId || process.id,
                    processName: process.name + ' - ' + process.location,
                    processQuantity: 1,
                    processUnit: process.unit,
                    processUnitGroup: unitGroup?.id,
                    processComment: '',
                    category: process.category,
                };
                setCurrentProcesses(currentProcesses.concat(newProcess));
            }
        }
    };
    const [selectHandler, setSelectHandler] = useState<
        (process: SelectProcess) => void
            >(() => { return addNewProcess });


    useEffect(() => {
        if (currentProcesses.length > 0) {
            const stages = envForm.getFieldValue('envInputProcesses');
            if (stages) {
                const matchingStage = stages.find(
                    (stage) => { return stage.lcs === lifeCycleStage }
                );
                if (matchingStage) {
                    matchingStage.values = currentProcesses;
                } else {
                    stages.push({ lcs: lifeCycleStage, values: currentProcesses });
                }
            }
            envForm.setFieldValue('envInputProcesses', stages);
        }
    }, [currentProcesses]);

    const open = Boolean(processPopupEnv);
    const processPopper = open ? 'process-popper' : undefined;

    const openProcessSelect = (event: React.MouseEvent<HTMLElement>) => {
        setProcessPopupEnv(processPopupEnv ? null : event.currentTarget);
    };
    const selectAddedProcessHandler = (event: React.MouseEvent<HTMLElement>) => {
        setSelectHandler(() => { return addNewProcess });
        openProcessSelect(event);
    };

    //fetch stages from formstate
    const loadInputs = () => {
        let stages: EnvProcessInputLCS[] = [];
        if (formState && formState.envInputProcesses) {
            stages = formState.envInputProcesses as EnvProcessInputLCS[];
        }
        return stages;
    };

    //load processes from matching lcs from tanstack form
    const loadProcesses = () => {
        let stages: EnvProcessInputLCS[] = [];
        if (lifeCycleStage) {
            stages = envForm.getFieldValue('envInputProcesses');
            if (stages && stages.length > 0) {
                const matchingLCS = stages.find((stage) => { return stage.lcs === lifeCycleStage });

                setCurrentProcesses(matchingLCS?.values || []);
            }

        }
        return stages;
    };

    useEffect(() => {
        if (lifeCycleStage) {
            const listStages = loadProcesses();

            //if process list from form is empty, try to load it from formState
            if (listStages.length === 0) {
                const stages: EnvProcessInputLCS[] = loadInputs();
                const matchingLCS = stages.find(
                    (stage) => { return stage.lcs === lifeCycleStage }
                );
                if (matchingLCS) {
                    setCurrentProcesses(matchingLCS.values);
                } else {
                    setCurrentProcesses([]);
                }
            }
        }
    }, [lifeCycleStage]);

    const deleteHandler = (processIndex: number) => {
        setCurrentProcesses(currentProcesses.filter((item, index) => { return index != processIndex }));

    }

    return (
        <ThemeProvider theme={TableTheme}>
            <Box>
                <TableContainer
                    sx={{
                        width: '-webkit-fill-available',
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr auto',
                                }}
                            >
                                <InputTableCell
                                    align='center'
                                    sx={{
                                        '@media (max-width: 1680px)': {
                                            padding: '2px',
                                        },
                                        // fontSize: '0.55vw',
                                        // lineHeight: '0.7vw',
                                    }}
                                >
                                    Product (Materials, Resources, Energy)
                                </InputTableCell>
                                <InputTableCell align='center'>Process</InputTableCell>
                                <InputTableCell align='center' className='qty-cell'>
                                    QTY. per one unit of analysis
                                </InputTableCell>
                                <InputTableCell align='center'>Unit</InputTableCell>
                                <InputTableCell align='center'>Comment</InputTableCell>
                                <InputTableCell align='center'>test</InputTableCell>
                            </TableRow>
                            {/* <TableRow>
                                <InputTableCell sx={{ width: '20%' }} align='center'>
                                    Product (Materials, Resources, Energy)
                                </InputTableCell>
                                <InputTableCell sx={{ width: '34%' }} align='center'>Process</InputTableCell>
                                <InputTableCell sx={{ width: '15%' }} align='center'>
                                    QTY. per one unit of analysis
                                </InputTableCell>
                                <InputTableCell sx={{ width: '15%' }} align='center'>Unit</InputTableCell>
                                <InputTableCell align='center'>Comment</InputTableCell>
                                <InputTableCell sx={{ width: '20%' }} align='center'> </InputTableCell>
                            </TableRow> */}
                        </TableHead>
                        <TableBody
                            sx={{
                                display: 'flex',
                                overflowY: 'auto',
                                height: '6vw',
                                flexDirection: 'column',
                                '::-webkit-scrollbar': {
                                    width: '0.2em',
                                },
                                '::-webkit-scrollbar-track': {
                                    background: '#939598',
                                    border: 0,
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#32463e',
                                    border: 0,
                                },
                            }}
                        >
                            <Popper
                                id={processPopper}
                                open={open}
                                anchorEl={processPopupEnv}
                                disablePortal
                                style={{
                                    position: 'fixed',
                                    transform: 'none !important',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'fixed !important',
                                        transform: 'none !important',
                                        top: '0 !important',
                                        left: '0 !important',
                                        zIndex: 1300,
                                        width: '100%',
                                        height: '-webkit-fill-available',
                                        backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                >
                                    <SelectEnvironmentalProcessPopup
                                        selectHandler={selectHandler}
                                        openProcessSelect={openProcessSelect}
                                    />
                                </Box>
                            </Popper>

                            {currentProcesses.map((process, index) => {
                                return (
                                    <EnvInputProcessListItem
                                        key={process.processName + index.toString()}
                                        envForm={envForm}
                                        index={index}
                                        openProcessSelect={openProcessSelect}
                                        setSelectHandler={setSelectHandler}
                                        unitGroups={loadedUnitGroups}
                                        process={process}
                                        lifeCycleStage={lifeCycleStage}
                                        deleteHandler={deleteHandler}
                                    />
                                )
                            })}
                            {/* <EnvInputProcessListItemTemp /> */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ThemeProvider theme={AddButton}>
                    <Button
                        size='small'
                        id='process-popup'
                        onClick={(e) => {
                            selectAddedProcessHandler(e);
                        }}
                    >
                        Add
                        <AddCircleIcon />
                    </Button>
                </ThemeProvider>
            </Box>
        </ThemeProvider>
    );
}

export default EnvInputProcessList;
