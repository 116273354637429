import { makeStyles } from '@mui/styles';

interface StyleProps {
    isLoginPage: boolean;
}


const useStyles = makeStyles(() => {return {
    headerSection: {
        color: '#1C1C1C',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    // bodySection: ({ isLoginPage }: { isLoginPage: boolean }) => ({
    //     marginTop: isLoginPage ? 0 : '65px',
    //     flex: 1,
    //     display: 'flex',
    // }),
    // bodySection: {
    //     marginTop: '65px',
    //     flex: 1,
    //     display: 'flex',
    // },
    bodySection: ({ isLoginPage }: StyleProps) => ({
        marginTop: isLoginPage ? 0 : '3vw',
        flex: 1,
        display: 'flex',
    }),
    dataSection: {
        // width: 'calc(100% - 16%)',
        width: '-webkit-fill-available',
        height: 'auto',
        minHeight: '90vh',
        marginLeft: '24%',
        backgroundColor: '#F2F2F2',
        transition: '0.3s',
    },
    wideSection: {
        width: '100%',
        height: 'auto',
        // height: 'calc(100vh - 80px)',
        // marginLeft: '5%',
        marginLeft: '0%',
        backgroundColor: '#F2F2F2',
        transition: '0.3s',
        minHeight: '90vh',
    },
    data: {
        padding: '20px',
        flex: 1,
    },
}});

export default useStyles;
