import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './A4StackedBarSPA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult, SPAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { palette1, palette2, palette3, palette4, processColors, treemapRGB } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingMethod, WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import Dropdown from '../../../../../dropdowns/Dropdown';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { applyStacked, applyValueType } from '../../../../options/util/applyOptions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { getColorsForStakeholders } from '../../../../../../helper/colors';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function A4StackedBarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(deepClone(initialOptions || {}));
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedValueType, setSelectedValueType] = useState('');
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState('');
    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        console.log(calc);
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults((calcResults));
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Stakeholder Colors": treemapRGB,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["Stakeholder Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        console.log(results);

        if (!results || !currentWeighingSet)
            return;


        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const selectedPalette = findColorPaletteByName(formState.colorPalette);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Weighted Score";
        }
        
        const lifeCycle = results.lifeCycleResults.find((lcs) =>lcs.lcsName === results.SPAProcess.lifeCycleStage);
        if (!lifeCycle) {


            chartData.labels = [results.SPAProcess.name];
            results.stakeholderResults.forEach((stakeholder, index) => {
                chartData.datasets.push({
                    label: stakeholder.name,
                    data: [
                        stakeholder.SPAPriorities.reduce((val1, val2) => {
                            return val1 + applyWeight(val2, "sam",currentWeighingSet);
                        }, 0),
                    ],
                    backgroundColor: getColorsForStakeholders([stakeholder.name])[0], //["#E14243", "#DF89D6", "#925409", "#D49151",],//colors[index], //random color from array

                    //backgroundColor: "#B93B00",//colors[index], //random color from array
                    borderColor: "000000",
                });
            });


            //Stacked Bar graph regular processes
        } else {
            if (!(chartData.labels.length > 0)) {
                chartData.labels = lifeCycle.processResults.map(
                    (item) => item.processName
                );
            }
            const stakeholders  : string[]= [];
            lifeCycle.processResults.forEach((item) => {
                item.PSILCAPriorities.forEach((item2) => {
                    const stakeholder = item2.category.split(":")[0];
                    if (
                        !stakeholders.find((item3) => item3 === stakeholder)
                    ) {
                        stakeholders.push(stakeholder);
                    }
                });
            });
            stakeholders.forEach((stakeholder, index) => {
                chartData.datasets.push({
                    label: stakeholder,
                    data: lifeCycle.processResults.map((item2) => {
                        const priorities = item2.PSILCAPriorities.filter(
                            (item3) =>
                                item3.category.split(":")[0] === stakeholder
                        );
                        if (priorities.length === 0) {
                            return 0;
                        } else {
                            return priorities.reduce((val1, val2) => {
                                return val1 + applyWeight(val2, "sam", currentWeighingSet);
                            }, 0);
                        }
                    }),

                    backgroundColor: getColorsForStakeholders([stakeholder])[0],
                    //backgroundColor: ["#FF9797","#31855E","#FF9C6E","#FFCAB1","#D49151","#613000","#4F9DC9","#C467BA","#DF89D6","#0C3D26",],

                    //backgroundColor: colors[index], //random color from array
                    borderColor: "000000",
                });
            });
            chartData.datasets = chartData.datasets.sort(
                (a, b) => a.label.localeCompare(b.label)
            );

        
        }



        setChartJSData(chartData);
        setChartOptions(options);


    }, [results, product, getGraphColorIndex, formState.colorPalette, currentWeighingSet, selectedValueType,selectedLCS,selectedLCSS]);
    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) => { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        if (!chartJSData.datasets || !chartOptions) {
            return; // Exit early if datasets or options are not properly initialized
        }

        let options = deepClone(chartOptions);
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            const { options: updatedOptions } = applyGraphSettings(options, chartData);
            Object.assign(options, updatedOptions);
        }
        options = applyStacked(options, true);

        CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={0} defaultResultIndex={3} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            type='stacked'
                                            dimension='SPA'
                                            chart='A4'
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                            label='Value Type'
                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >

                                        <ChartDownload chartData={chartJSData}
                                            chartName={'Total weighted result: all stakeholders - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='treemap'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A4StackedBarSPA;
