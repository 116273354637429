// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.makeStyles-dataSection-3 .MuiBox-root.css-1l4w6pd {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
}`, "",{"version":3,"sources":["webpack://./src/screens/Product/ProductTheme.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,OAAO;IACP,MAAM;AACV","sourcesContent":[".makeStyles-dataSection-3 .MuiBox-root.css-1l4w6pd {\r\n    width: 100vw;\r\n    position: absolute;\r\n    left: 0;\r\n    top: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
