import { createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';

const ProductsSearchBoxTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    body1: {
                        fontSize: 14,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                    },
                    marginLeft: 0,
                    marginRight: 0,
                    gap: '0.2vw',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '5vw',
                    height: '1.4vw',
                    borderRadius: 0,
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: '0.7vw',
                    textTransform: 'capitalize',
                    backgroundColor: '#166d46',
                    color: 'white',
                    minWidth: '5vw',
                    padding: '0.5vw',
                    '&:hover': {
                        backgroundColor: '#D9EFDF',
                        color: '#004021',
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: 32,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 20,
            fontWeight: 'bold',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '0.7vw',
        },
    },

});

export default ProductsSearchBoxTheme;