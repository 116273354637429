/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import MiniButtonSelectsTheme from "../../../../style/components/MiniButtonSelects";
import "../../../../style/components/MiniButtonSelects.css";
import ChartAuto from 'chart.js/auto';
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { exportComponentAsPNG } from "react-component-export-image";
import html2canvas from "html2canvas";

import { ChartJSData } from "../../../../../interface/ChartData";
import { SDGResult } from "../../../sdgtable";

interface ChartDownloadProps {
    chartType?: string,
    canvasID?: string,
    chartName: string,
    chartData?: ChartJSData
    sdgLink?: boolean,
    sdgTableData?: SDGResult[]
}
function ChartDownload({ chartType, canvasID, chartName, chartData, sdgLink, sdgTableData }: ChartDownloadProps) {
    const DownloadImage = require("../../../../../uploads/images/general-icons/Download-Image.png");
    const DownloadExcel = require("../../../../../uploads/images/general-icons/Download-Excel.png");

    const chartDownloader = async () => {
        if (chartType === "SDG Table") {
            const sdgTable = document.getElementById("SDGTable")
            if (sdgTable) {
                const canvas = await html2canvas(sdgTable);
                const dataURL = canvas.toDataURL("image/png");
                FileSaver.saveAs(dataURL, chartName + ".png");
            }
        }
        // else if (chartType === "Sunburst") {

        // }
        else if (chartType === "Treemap Chart") {
            const treemap = document.getElementById("TreemapChart")
            if (treemap) {
                const canvas = await html2canvas(treemap);
                const dataURL = canvas.toDataURL("image/png");
                FileSaver.saveAs(dataURL, chartName + ".png");
            }

        }
        else {
            const chart = ChartAuto.getChart(canvasID || 'graph1Canvas');
            if (chart) {
                const chartImage = chart.toBase64Image();
                FileSaver.saveAs(chartImage, chartName + ".png");
            }
        }

        // const chartLink = document.createElement("a");
        // chartLink.download = "test.txt";
        // chartLink.href = "./test.txt";
        // chartLink.click();

        //
    };

    // Utility function to calculate percentage
    const calculatePercentage = (value: number, data: number[]): number => {
        const total = data.reduce((acc, curr) => acc + curr, 0);
        return (value / total) * 100;
    };

    // Functions for handling different chart types
    const handleTreemapChart = (): ExcelData => {
        const rawVals: Record<string, string | number> = { " ": "Raw Values" };
        const percentVals: Record<string, string | number> = { " ": "Percentages" };
        if (chartData) {
            chartData.datasets.forEach((dataset) => {
                dataset.tree?.forEach((item) => {
                    rawVals[item.stakeholder] = item.value;
                    percentVals[item.stakeholder] = calculatePercentage(item.value, dataset.data) + "%";
                });
            });
        }


        return [rawVals, percentVals];
    };

    const handleSunburstGraph = (): ExcelData => {
        const val: Record<string, string | number> = {};

        if (chartData) {
            if (sdgLink) {
                chartData.datasets.forEach((dataset) => {
                    dataset.tree?.forEach((category) => {
                        val["Stakeholder:Subcategory"] = category.stakeholder;
                        val["Score"] = category.value;
                        val[dataset.label] = dataset.label;
                    });
                });
            } else {
                val[" "] = chartData.labels[0];
                chartData.datasets.forEach((dataset) => {
                    dataset.tree?.forEach((subCategory) => {
                        val[`${dataset.label}:${subCategory.stakeholder}`] = subCategory.value;
                    });
                });
            }
        }


        return [val];
    };

    const handleTable = (): ExcelData => {
        if (sdgTableData) {
            return sdgTableData.map((sdgResult) => {
                const val: Record<string, string | number> = {
                    "SDG Name": sdgResult.name,
                };

                sdgResult.categories.forEach((category) => {
                    val[category.name] = category.val;
                });

                return val;
            });
        }
        return []
    };

    const handleDefaultChart = (): ExcelData => {
        if (chartData) {
            return chartData.datasets.map((dataset) => {
                const val: Record<string, string | number> = { " ": dataset.label };

                chartData.labels.forEach((label, index) => {
                    val[label] = dataset.data[index];
                });

                return val;
            });
        }
        return [];

    };



    // Function to calculate the maximum column widths for Excel export
    const calculateColumnWidths = (excelData: ExcelData): number[] => {
        const objectMaxLength: number[] = [];

        excelData.forEach((row) => {
            Object.values(row).forEach((value, index) => {
                const length = typeof value === "number" ? 10 : value.length;
                objectMaxLength[index] = Math.max(objectMaxLength[index] || 0, length);
            });

            Object.keys(row).forEach((key, index) => {
                objectMaxLength[index] = Math.max(objectMaxLength[index] || 0, key.length);
            });
        });

        return objectMaxLength;
    };


    // Function to handle the export to Excel
    const exportToExcel = (excelData: ExcelData): void => {
        const objectMaxLength = calculateColumnWidths(excelData);
        const wsCols = objectMaxLength.map((length) => ({ width: length / 2 }));

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = "xlsx";

        const ws = XLSX.utils.json_to_sheet(excelData);
        ws["!cols"] = wsCols;

        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, chartName + '.' + fileExtension);
    };

    const excelDownloader = () => {
        let excelData: ExcelData = [];

        switch (chartType) {
        case "Treemap Chart":
            excelData = handleTreemapChart();
            break;

        case "Sunburst Graph":
            excelData = handleSunburstGraph();
            break;

        case "SDG Table":
            if (sdgTableData) {
                excelData = handleTable();

            }
            break;

        default:
            if (chartData && chartData.datasets) {
                excelData = handleDefaultChart();
            }
            break;
        }

        if (excelData.length > 0) {
            exportToExcel(excelData);
        }
    };
    return (
        <ThemeProvider theme={MiniButtonSelectsTheme}>
            <Box className="mini-button-wrapper">
                <Button onClick={chartDownloader} variant="outlined" color="primary">
                    <img
                        src={DownloadImage}
                        alt="Icon of graph download"
                        style={{
                            width: '1.2vw',
                            /* height: 100%; */
                            objectFit: 'contain',
                        }}
                    />
                </Button>
                <Button onClick={excelDownloader} variant="outlined" color="primary">
                    <img src={DownloadExcel} alt="Icon of graph download"
                        style={{
                            width: '1vw',
                            /* height: 100%; */
                            objectFit: 'contain',
                        }}
                    />
                </Button>
            </Box>
        </ThemeProvider>
    );
}



interface CorrelationCategory {
    name: string;
    value: number;
    children: SubCategory[];
}

interface SubCategory {
    name: string;
    value: number;
}


interface Dataset {
    label: string;
    data: number[];
}


interface CategoryItem {
    name: string;
    val: number;
    correlation?: number;
}


type ExcelData = Record<string, string | number>[];
export default ChartDownload;
