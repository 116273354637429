import { FormState } from "../components/forms/FormContext";

/* eslint-disable max-len */
export interface SocialStakeholder{
        name:string,
        subcategories:StakeholderSubcategory[],
}
export interface StakeholderSubcategory{
        name:string,
        cutoffIndicator:string,
        source:string,
        practiceIndicators:string[],
}

export const stakeholders : SocialStakeholder[] = [
    {
        name: "Consumers",
        subcategories: [
            {
                name: "Health and safety",
                cutoffIndicator: "Presence of management measures to assess consumer health and safety",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Absence of consumer complaints",
                    "Absence of defects detected per production batch",
                    "Presence of labels of health and safety requirements",
                    "Presence of a Quality and/or Product Safety Management System such as ISO 9001:2015, British Retail Consortium (BRC), Halal, International Food Standard (IFS), ISO 10377:2013, etc",
                    "Absence of incidents of non-compliance with regulations and voluntary codes concerning the health and safety impacts of products and services during their life cycle",
                    "Absence of incidents of non-compliance with regulations and voluntary codes concerning product and service information and labelling",
                    "Absence of incidents related to the social impact of the products on customers",
                ],
            },
            {
                name: "Feedback mechanism",
                cutoffIndicator: "Presence of customer’s feedback mechanism",
                source: "Ramirez et al. (2014) and UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Practices related to customer satisfaction, including results of surveys measuring customer satisfaction",
                    "Management measures to improve feedback mechanisms",
                ],
            },
            {
                name: "Transparency",
                cutoffIndicator: "Commitment to transparency as part of the company’s strategy and policy",
                source: "New indicator created (Hannouf and Assefa, 2018)",
                practiceIndicators: [
                    "Compliance with regulations regarding transparency",
                    "Absence of consumer complaints regarding transparency",
                    "Publication of a sustainability report",
                    "Comprehensive information available in the sustainability report or other documents regarding the social and environmental performance of the organization",
                    "Communication of the results of social and environmental life cycle impact assessment",
                    "Organization obtained certification/label for the product/site",
                ],
            },
            {
                name: "End of life responsibility",
                cutoffIndicator: "Presence of management systems that ensure clear information to consumers on end-of-life options",
                source: "Ramirez et al. (2014) and UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Absence of annual incidents of non-compliance with regulatory labeling requirements",
                    "Presence of a strong hazardous waste management system",
                ],
            },
            {
                name: "Consumer privacy",
                cutoffIndicator: "Presence of internal management system to protect consumer privacy",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Absence of consumer complaints related to breach of privacy or loss of data within the last year",
                    "Absence of complaints by regulatory bodies related to breach of consumer privacy or loss of data within the last year",
                ],
            },
        ],
    },
    {
        name: "Local Community",
        subcategories: [
            {
                name: "Access to material resources",
                cutoffIndicator: "Presence of a certified environment management system",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Has the organization developed project-related infrastructure with mutual community access and benefit",
                    "Presence of effective organizational risk assessment with regard to potential for material resource conflict",
                    "Absence of any potential impact of organization’s activities on the biodiversity of protected areas",
                    "None of the company’s sites are adjacent to protected areas",
                ],
            },
            {
                name: "Access to immaterial resources",
                cutoffIndicator: "Presence of internal management systems that promote the access to immaterial resources",
                source: "Ramirez et al. (2014)",
                practiceIndicators: [
                    "Absence of annual arrests connected to protests of organization actions",
                    "Do policies related to intellectual property respect moral and economic rights of the community?",
                    "Presence of community education initiatives",
                    "Investments in programs that address community sustainability",
                ],
            },
            {
                name: "Migration",
                cutoffIndicator: "Presence of internal management systems or organizational policies that prevent involuntary resettlement",
                source: "Ramirez et al. (2014) and UNEP/SETAC Methodological sheets (2013)",
                practiceIndicators: [
                    "Presence of individuals who resettle (voluntarily and involuntarily) that can be attributed to the organization",
                    "Presence of organizational procedures for integrating migrant workers into the community",
                ],
            },
            {
                name: "Cultural heritage",
                cutoffIndicator: "Presence of policies in place to protect cultural heritage",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Presence of organizational program to include cultural heritage expression in product design/ production",
                    "Presence of relevant organizational information to community members in their spoken language(s)",
                    "Presence of documented initiatives and activities oriented to support and promote cultural heritage (e.g., funding of cultural activities and events)",
                    "Presence of networks that preserve the rights for employees to protect and bring their culture inside the company",
                ],
            },
            {
                name: "Safe and healthy living conditions",
                cutoffIndicator: "Management effort to minimize use of hazardous substances",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Management oversight of structural integrity",
                    "Organization efforts to strengthen community health (e.g. through shared community access to organization health resources)",
                    "Absence of use of hazardous substances",
                    "Absence of community health incidents caused by company's companies",
                ],
            },
            {
                name: "Respect of indigenous rights",
                cutoffIndicator: "Presence of an indigenous rights policy",
                source: "Ramirez et al. (2014)",
                practiceIndicators: [
                    "Annual meetings held with indigenous community members",
                    "Absence of reported and/or documented illegal activities",
                    "The organization committed to accepting indigenous land rights",
                    "Absence of charges of discrimination against indigenous community members",
                    "Diversity of community stakeholder groups that engage with the organization",
                    "Meetings with community stakeholders",
                    "Organizational support (volunteer-hours or financial) for community initiatives",
                    "Existence of programs to get feedback from the community",
                ],
            },
            {
                name: "Community engagement",
                cutoffIndicator: "Presence of written policies or guidelines on community engagement at organization level",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Diversity of community stakeholder groups that engage with the organization",
                    "Meetings with community stakeholders",
                    "Organizational support (volunteer-hours or financial) for community initiatives",
                    "Existence of programs to get feedback from the community",
                ],
            },
            {
                name: "Local employment",
                cutoffIndicator: "Presence of a policy of local hiring preferences",
                source: "Ramirez et al. (2014) and UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Presence of workforce hired locally",
                    "Presence of available strategies or policies to support and work with local suppliers to improve their conditions on the different dimensions",
                ],
            },
            {
                name: "Secure living conditions",
                cutoffIndicator: "Management policies related to private security personnel",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Absence of legal complaints per year against the organization with regard to security concerns",
                    "Absence of casualties and injuries per year ascribed to the organization",
                    "Consultation with community about their concerns and issues for secure living caused by the company",
                    "Consultation with groups that may be affected with regard to security concerns caused by the company",
                    "Presence of formal initiatives to allow stakeholders to engage in effective consultation about any concern regarding secure living conditions caused by the company",
                    "Absence of obligations for environmental remediation to the community",
                ],
            },
        ],
    },
    {
        name: "Society",
        subcategories: [
            {
                name: "Public commitment to sustainability issues",
                cutoffIndicator: "Managing sustainability issues as part of the company’s policy, strategy and goals",
                source: "New indicator created (Hannouf and Assefa, 2018)",
                practiceIndicators: [
                    "Presence of publicly available documents as promises or agreements on sustainability issues",
                    "Absence of complaints issued related to the non-fulfilment of promises or agreements by the organization by the local community or other stakeholders at OECD contact points or Global Reporting Initiative",
                    "Presence of mechanisms to follow-up the realization of promises",
                    "The organization has pledged to comply with the global compact principles and has engaged itself to present yearly communication on progress",
                    "Implementation/signing of principles or other codes of conduct (Sullivan Principles, Caux Round Table, UN principles, etc.)",
                    "Presence of a board committee responsible for sustainability issues",
                    "Absence of fines and penalties for non-compliance with environmental regulations and laws",
                ],
            },
            {
                name: "Prevention and mitigation of conflicts",
                cutoffIndicator: "Evidence of any promise or agreement relating to prevention and mitigation of armed conflicts",
                source: "Ramirez et al. (2014)",
                practiceIndicators: [
                    "Organization has no role in the development of conflicts",
                    "Organization is not involved in business related activities of disputed products",
                ],
            },
            {
                name: "Contribution to economic development",
                cutoffIndicator: "Direct and indirect economic growth is part of the company’s strategy and policy",
                source: "New indicator",
                practiceIndicators: [
                    "The product/service/organization contributes to economic progress (e.g., annual growth rate of real GDP per employed person)",
                ],
            },
            {
                name: "Technology development",
                cutoffIndicator: "Using technology development to drive innovation is part of the company’s strategy",
                source: "New indicator created (Hannouf and Assefa, 2018)",
                practiceIndicators: [
                    "Involvement in technology transfer program or projects",
                    "Partnerships in research and development",
                    "Investments in technology development/technology transfer",
                    "Awards related to R&D",
                ],
            },
            {
                name: "Ethical treatment of animals",
                cutoffIndicator: "Presence of any policy, regulation or label certifying the fair treatment of animals",
                source: "Reformulated based on UNEP Methodological Sheets (2021)",
                practiceIndicators: [
                    "Presence of regular check-ups and frequency of animals welfare conducted by specialists (veterinaries, animal biologists, or others)",
                    "Absence of serious injuries, illnesses, and unforeseen fatal casualties reported by workers and animal specialists",
                    "Absence of behavioral disorders or occupational diseases reported by workers, animal specialists, and/or civil society members",
                    "No complaints from consumers or civil society organizations representing animal welfare issues",
                ],
            },
            {
                name: "Poverty alleviation",
                cutoffIndicator: "Formalized commitment of the organization to reduce poverty",
                source: "UNEP Methodological Sheets (2021)",
                practiceIndicators: [
                    "The organization carries out a poverty alleviation program",
                    "Contingency planning measures, disaster, emergency management plan, training programs, and recovery/restoration plans",
                ],
            },
        ],
    },
    {
        name: "Workers",
        subcategories: [
            {
                name: "Freedom of association and collective bargaining",
                cutoffIndicator: "Presence of policy on freedom of association",
                source: "Ramirez et al. (2014)",
                practiceIndicators: [
                    "Employment is not conditioned by any restrictions on the right to collective bargaining",
                    "Presence of unions within the organization is adequately supported",
                    "Availability of collective bargaining agreement",
                    "Workers are free to join unions of their choosing",
                    "Presence of minimum notice period(s) regarding significant operational changes, and its specification in collective agreements",
                    "Workers have access to a neutral, binding, and independent dispute resolution procedure",
                    "Employee/union representatives are invited to contribute to planning of larger changes in the company, which will affect the working conditions",
                ],
            },
            {
                name: "Child labour",
                cutoffIndicator: "Presence of policy related to child labor or compliance with all child labour laws",
                source: "Ramirez et al. (2014) + New indicator",
                practiceIndicators: [
                    "Absence of working children under the legal age or 15 years old (14 years old for developing economies)",
                    "Children are not performing work during the night (an example of unauthorized work by the ILO conventions C138 and C182)",
                    "Records on all workers stating names and ages or dates of birth are kept on file",
                    "Working children younger than 15 and under the local compulsory age are attending school",
                ],
            },
            {
                name: "Forced labour",
                cutoffIndicator: "Presence of policy on forced labor or presence of no forced labour as part of the company’s code of business conduct",
                source: "Ramirez et al. (2014) + New indicator",
                practiceIndicators: [
                    "Workers voluntarily agree upon employment terms. Employment contracts stipulate wage, working time, holidays and terms of resignation. Employment contracts are comprehensible to the workers and are kept on file",
                    "Birth certificate, passport, identity card, work permit or other original documents belonging to the worker are not retained or kept for safety reasons by the organization neither upon hiring nor during employment",
                    "Workers are free to terminate their employment within the prevailing limits",
                    "Workers are not bonded by debts exceeding legal limits to the employer",
                    "Absence of any forced labour incident",
                ],
            },
            {
                name: "Discrimination",
                cutoffIndicator: "Presence of formal policies on equal opportunities",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Announcement of open positions happen through national/regional newspapers, public job databases on the internet, employment services or other publicly available media ensuring a broad announcement",
                    "Governance bodies and employees are diverse representing all genders, age group, minority, group membership, and other indicators of diversity",
                    "No difference in basic salary of men to women by employee category",
                    "Absence of incidents of discrimination",
                    "Presence of board level responsibility for diversity initiatives",
                    "Presence of initiatives supporting a diverse workshop",
                    "Presence of women in leadership roles",
                ],
            },
            {
                name: "Health and safety",
                cutoffIndicator: "Presence of formal policy concerning health and safety",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Number/percentage of injuries or fatal accidents in the organization by job qualification inside the company",
                    "Adequate general occupational safety measures are taken",
                    "Preventive measures and emergency protocols exist regarding accidents & injuries",
                    "Preventive measures and emergency protocols exist regarding pesticide & chemical exposure",
                    "Absence of (serious/non-serious) Occupational Safety and Health Administration (OSHA) violations within the past 3 years and status of violations",
                    "Education, training, counselling, prevention and risk control programs in place to assist workforce members, their families, or community members regarding serious diseases",
                    "Appropriate protective gear required in all applicable situations",
                    "Non-involvement of the company in significant health and safety incidents",
                    "Absence of leaks of hazardous materials events during transportation",
                ],
            },
            {
                name: "Social benefits, legal issues",
                cutoffIndicator: "Presence of social benefits as part of company’s policy",
                source: "New indicator created (Hannouf and Assefa, 2018)",
                practiceIndicators: [
                    "Company provides social benefits to the workers (e.g., health insurance, pension fund, child care, education, accommodation, etc.) and all other social benefits required by law",
                    "No evidence of violations of obligations to workers under labour or social security laws and employment regulations",
                    "Absence of unlawful employment termination",
                ],
            },
            {
                name: "Fair salary",
                cutoffIndicator: "Presence of benchmark against minimum wages in locations",
                source: "New indicator [reformulated based on UNEP/SETAC methodological sheets (2013)]",
                practiceIndicators: [
                    "Lowest paid worker higher than minimum wage",
                    "Absence of employees earning wages below poverty line",
                    "Absence of suspicious deductions on wages",
                    "Regular and documented payment of workers (weekly, bi-weekly)",
                    "Mechanism used to determine salary based on salary survey, benchmarking compensation at peer companies",
                ],
            },
            {
                name: "Working time",
                cutoffIndicator: "Presence of policy that provides clear communication of working hours and overtime arrangements",
                source: "New indicator [reformulated based on UNEP/SETAC methodological sheets (2013)]",
                practiceIndicators: [
                    "Number of hours effectively worked by employees (at each level of employment) comply with applicable laws, industry standards and ILO standards",
                    "Number of holidays effectively used by employees (at each level of employment) comply with applicable laws and industry standards",
                    "Respect of contractual agreements concerning overtime",
                    "Clear communication of working hours and overtime arrangements",
                    "The organization provides flexibility",
                ],
            },
            {
                name: "Employment relationship",
                cutoffIndicator: "Presence of a written contract which defines the relationship between the employers and workers (rights and responsibilities of each)",
                source: "UNEP Methodological Sheets (2021)",
                practiceIndicators: [
                    "Presence of contracts’ essential elements",
                    "Workers have a copy of the signed contract",
                ],
            },
            {
                name: "Sexual harassment",
                cutoffIndicator: "Presence of policy related to preventing sexual harassment",
                source: "New indicator",
                practiceIndicators: [
                    "Absence of sexual harassment incidents reported on a grievance helpline",
                    "Existence of clear responsibilities for matters of sexual harassment within the organization",
                    "Presence of efforts by the organization to reduce the risk of sexual harassment",
                ],
            },
        ],
    },
    {
        name: "Children",
        subcategories: [
            {
                name: "Education provided in the local community",
                cutoffIndicator: "Presence of policy, leadership, and budget for early learning",
                source: "UNEP Methodological Sheets (2021)",
                practiceIndicators: [
                    "Community involvement programs and opportunities as a consistent goal for schools",
                    "Presence of systems promoting human and financial resources",
                    "Presence of strategies addressing demand-side gender-related and disability barriers to education",
                    "Presence of equitable access to education",
                    "Presence of systems promoting community and student participation",
                    "Presence of education systems promoting accountability to communities",
                    "Presence of provisions of local community involvement in monitoring of school activities",
                ],
            },
            {
                name: "Health issues for children as consumers",
                cutoffIndicator: "Formalized commitment of the organization to improve the health of children",
                source: "UNEP Methodological Sheets (2021)",
                practiceIndicators: [
                    "The organization carries out programs that provide an understanding or information about the impact of products on children’s health, physical and psychological development",
                    "The organization carries out programs to promote leisure and family time for the children",
                    "The organization carries out programs to promote health impact to children",
                ],
            },
            {
                name: "Children concerns regarding marketing practices",
                cutoffIndicator: "The organization has a policy on responsible marketing",
                source: "UNEP Methodological Sheets (2021)",
                practiceIndicators: [
                    "The organization performs audit on the implementation of responsible marketing",
                    "The organization receives monitoring and evaluation from the governing body on the implementation of responsible marketing",
                    "No incidents of non-compliance with regulations and/or voluntary codes concerning product and service information/marketing/advertising and labeling",
                    "No incidents of non-compliance with regulations resulting in a fine or penalty",
                    "No incidents of non-compliance with regulations resulting in a warning",
                    "No incidents of non-compliance with voluntary codes",
                ],
            },
        ],
    },
    {
        name: "Value Chain Actors",
        subcategories: [
            {
                name: "Fair competition",
                cutoffIndicator: "Documented statement or procedures (policy, strategy, etc.) to prevent engaging in or being complicit in anticompetitive behavior",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Absence of legal actions pending or completed during the reporting period regarding anti-competitive behavior and violations of anti-trust and monopoly legislation",
                    "Membership in alliances that behave in an anticompetitive way",
                    "Employee awareness of the importance of compliance with competition legislation and fair competition",
                ],
            },
            {
                name: "Supplier relationships",
                cutoffIndicator: "The organization has a code of conduct",
                source: "Ramirez et al. (2014)",
                practiceIndicators: [
                    "Absence of coercive communication with suppliers",
                    "Sufficient lead time",
                    "Reasonable volume fluctuations",
                ],
            },
            {
                name: "Respect of intellectual properties",
                cutoffIndicator: "The organization displays respect for intellectual property systems in its policy or strategy",
                source: "Ramirez et al. (2014)",
                practiceIndicators: [
                    "Use of local intellectual property",
                    "Organization respects intellectual property rights in its practices",
                    "Absence of intellectual property rights issues in the last three years",
                ],
            },
            {
                name: "Promoting social responsibility",
                cutoffIndicator: "Presence of explicit code of conduct that protects human rights of workers among suppliers",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "Suppliers of the company are audited with regard to social responsibility in the last year",
                    "Membership in an initiative that promotes social responsibility along the supply chain",
                    "Integration of ethical, social, environmental, and gender equality criteria in purchasing policy, distribution policy, and contract signatures",
                    "Support to suppliers in terms of consciousness-raising and counselling concerning the social responsibility issues",
                    "Presence of suppliers having environmental certifications",
                ],
            },
            {
                name: "Wealth distribution",
                cutoffIndicator: "Presence of contractual instruments within the supply/value chain that ensure the distribution of the value among the actors",
                source: "UNEP Methodological Sheets (2021)",
                practiceIndicators: [
                    "Presence of interbranch/professional organizations that represent the interest of segments of the value chains",
                    "Definition of a fair price, i.e., a price that covers all the production costs and returns an acceptable profit margin",
                ],
            },
            {
                name: "Corruption",
                cutoffIndicator: "Formalized commitment of the organization to prevent corruption, referring to recognized standards",
                source: "UNEP/SETAC Methodological Sheets (2013)",
                practiceIndicators: [
                    "The organization carries out an anti-corruption program",
                    "The organization installs or cooperates with internal and external controls to prevent corruption",
                    "Absence of written documents on active involvement of the organization in corruption and bribery; or convictions related to corruption and bribery",
                    "Absence of business ethics incidents",
                    "The company has not received any penalty for tax shelter transactions",
                    "The company is not involved in lobbying criticized activities and 'dark money' organizations",
                ],
            },
        ],
    },
];
export type StakeholderIndicators = Record<string, string>;

export const getStakeholderFormValues = (formState?:FormState) : StakeholderIndicators =>{
    const defaultFormValues : StakeholderIndicators = {

    }
    stakeholders.forEach((stakeholder) => {
        stakeholder.subcategories.forEach((subcategory) => {
            // Populate the cutoff indicator for the subcategory
            defaultFormValues[`${stakeholder.name}:${subcategory.name}:Cutoff`] = formState && formState.SPAIndicators ? formState.SPAIndicators[`${stakeholder.name}:${subcategory.name}:Cutoff`]:"NoData";

            // Populate practice indicators for the subcategory
            subcategory.practiceIndicators.forEach((indicator) => {
                defaultFormValues[`${stakeholder.name}:${subcategory.name}:${indicator}:Indicator`] = formState && formState.SPAIndicators ? formState.SPAIndicators[`${stakeholder.name}:${subcategory.name}:${indicator}:Indicator`]: "NoData";
            });
        });
    });

    return defaultFormValues;
}

