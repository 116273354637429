import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import useStyles from './B4BarPSILCA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import { initialOptions } from '../../../../options/InitialOptionValues';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { ThemeProvider } from '@emotion/react';
import { FormControl } from '@mui/base';
import { Paper, Divider } from '@mui/material';
import ChartDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import GraphType from '../../../../options/components/GraphTypeOptions';
import ResultsTheme from '../../../../style/ResultsTheme';
import { palette1, palette2, palette3, palette4, processColors, socialProcessColors, treemapRGB } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product,
    selectedLCS: string,
    selectedLCSS: string,
    selectedValueType: string,
}

export interface ColorIndexData {
    label: string;
    identifier?: string;
}

function B4BarPSILCA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const {
        product, selectedLCS, selectedLCSS, selectedValueType,
    } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [results, setResults] = useState<PSILCAResult[]>([]);
    const [graphColors, setGraphColors] = useState<ColorIndexData[]>(formState.processColorIndexes || []);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults((calcResults));
    }, [product]);


    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": processColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        };
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
        // Adjust the color by changing its brightness
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);
    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chart2Data: ChartJSData = { datasets: [], labels: [] };
        const options2: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }

        let processes: string[] = [];
        const matchingLCS = results[0].subcategories[0].indicators[0]
            .indicatorResults.find((item) => {return item.lcsName === `${selectedLCS}:${selectedLCSS}`});
        if (matchingLCS) {
            processes = matchingLCS.processResults.processes.map((item) => {return item.process});
        }

        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        // const totalProcesses = matchingLCS.processResults[0].values.length;
        const extendedPalette = generateExtendedPalette(selectedPalette);

        chartData.datasets = processes.map((process, index) => {
            
            const colorIndex = getGraphColorIndex({
                label: process,
            }, extendedPalette);
                
            return {
                label: process,
                data: results.map((stakeholder) => {return stakeholder.subcategories
                    .map((subcategory) => {return subcategory.indicators
                        .map((indicator) => {return indicator.indicatorResults
                            .find(
                                (lcs) => {return lcs.lcsName === `${selectedLCS}:${selectedLCSS}`},
                            )?.processResults.processes.find(
                                (item) => {return item.process === process},
                            )?.processTotalSum})
                        .reduce((a: number, b) => {return a + (b || 0)}, 0)})
                    .reduce((a, b) => {return a + b}, 0)}),
                // backgroundColor:
                //     currentPalette[
                //     getGraphColorIndex({
                //         label: process,
                //         // identifier: lifeCycle.lcsName,
                //     })
                //     ], //colors[index], //random color from array
                // borderColor: "000000",
                backgroundColor: extendedPalette[colorIndex],
            }});

        chartData.labels = results.map(
            (stakeholder) => {return stakeholder.stakeholder},
        );
        console.log(chartData);

        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedLCS, selectedLCSS, getGraphColorIndex, results, formState.colorPalette]);

    const [applyGraphSettings, setApplyGraphSettings] =
    useState<(options: ChartJSOptions, chartData: ChartJSData) =>
         { options: ChartJSOptions; chartData: ChartJSData; }>();    
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if(applyGraphSettings){
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);
    return (
        // <Box className={classes.chartSec}>
        //     <Paper
        //         square
        //         sx={{
        //             display: 'flex',
        //             justifyContent: 'center',
        //         }}
        //     >
        <Box className={classes.chartSec}>
            <div className='settings-two-graph'>
                <ChartDownload chartData = {chartJSData} chartName={'Stakeholder level result - Process'} chartType='Bar Graph' />
                <GraphSetting
                    chartOptions={chartOptions}
                    setApplyGraphSettings={setApplyGraphSettings}
                    triggerChartUpdate={triggerChartUpdate}
                    chartData = {chartJSData}
                    chartColorType = 'treemap'
                />
            </div>
            <canvas id='graph1Canvas' className='graph-canvas' />
        </Box>
        //     </Paper>
        // </Box>

    );
}

export default B4BarPSILCA;
