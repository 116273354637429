import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    product: {
        width: '100%',
        height: '400px',
    },
    productHeader: {
        position: 'fixed',
        zIndex: 2000,
        top: '0.5vw',
        // left: '27vw',
        width: 'auto',
        fontSize: '1.4vw !important',
        color: 'white',
    },
}});

export default useStyles;
