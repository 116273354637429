/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import GeneralTheme from '../../../style/GeneralTheme';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import SelectSocialProcessPopup from '../components/SelectSocialProcessPopup';
import SocialInputProcessList from './SocialInputProcessList';
import Dropdown from '../../../dropdowns/Dropdown';
import { useNavigate } from '@tanstack/react-router';
import { FormApi, useForm } from '@tanstack/react-form';
import { SocialInputsForm } from '../../../../interface/Form';
import { useFormContext } from '../../../forms/FormContext';
import SocialInfoPopup from '../components/SocialInfoPopup';
import useStyles from '../SocialInputs.styles';
import { SocialInputsLCSDropdowns } from '../components/SocialInputsLCSDropdowns';
import { CreateProductNavButtons } from '../../components/CreateProductNavButtons';

const SocialPSILCAInputs = () => {
    const navigate = useNavigate();

    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');

    const form = useForm<SocialInputsForm>({
        defaultValues: {
            socialInputProcesses: formState.socialInputProcesses ||[] ,
            currentLCS: formState.selectedLCS ? (formState.selectedLCS as string[])[0].split(':')[0] : 'Product Stage',
            currentLCSS: formState.selectedLCSS ? (formState.selectedLCSS as string[])[0].split(':')[1] : 'Raw Material Supply', // TODO use this way of getting default LCS in social as well
            socialAssessmentMethod: 'PSILCA',
            socialSPAWeighingMethod: 'Distance-to-target + Risk-based',
            socialSPAWeighingSet: '',
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            setFormState({
                ...formState,
                socialInputProcesses: value.socialInputProcesses,
                socialAssessmentMethod: value.socialAssessmentMethod,
                socialSPAWeighingMethod: '',
                socialSPAWeighingSet: '',
            });

        },
    });


    return (
        <ThemeProvider theme={GeneralTheme}>
            <Box
                sx={{
                    display: 'flex',
                    gap: '1vw',
                    width: '-webkit-fill-available',
                }}
                className='psilca-box'
            >
   
                <SocialInputsLCSDropdowns setSelectedLCS={setSelectedLCS} setSelectedLCSS={setSelectedLCSS}/>
    
            </Box>
            <Box
                sx={{
                    width: '-webkit-fill-available',
                    height: '56vh',
                    gap: '1vw',
                }}
            >
                <Paper
                    sx={{
                        height: '100%',
                    }}
                >

                    <Typography component="h2" variant="h2" onClick={(() => {
                        console.log(form.state.values);
                    })}>
                    Inputs
                    </Typography>
                    <SocialInputProcessList socialForm={form} lifeCycleStage={`${selectedLCS}:${selectedLCSS}`}
                    />
                </Paper>
            </Box>
            <CreateProductNavButtons form={form} formState={formState} currentPage='social' />

        </ThemeProvider>
    );
};

export default SocialPSILCAInputs;
