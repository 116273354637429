import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Divider,
    FormControl,
    Paper,
    ThemeProvider,
} from '@mui/material';
import useStyles from './B1TreeSPAstyles';
import autoRound from '../../../../../../helper/autoRound';
import { treemapRGB, getColorsForStakeholders } from '../../../../../../helper/colors';
import {
    TreemapData, TreemapLegend,
} from '../../../../../../interface/ChartData';
import { Product, SPAResult } from '../../../../../../interface/Product';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { RechartTreemap } from '../../../../Treemap';
import ChartDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import ResultsTheme from '../../../../style/ResultsTheme';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';

interface ComponentProps {
    product: Product,
}

function B1TreeSPA(props: ComponentProps) {
    const classes = useStyles();
    const { product } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [treemapData, setTreemapData] = useState<TreemapData[]>([]);
    const [results, setResults] = useState<SPAResult>();
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'SOCIAL-SPA',
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults(calcResults);
    }, [product]);

    useEffect(() => {
        if (!results) return;

        // Prepare legends and treemap data
        let legends: TreemapLegend[] = [];
        const chartData: TreemapData[] = [{ name: product.productName, children: [] }];

        console.log(results);
        results.stakeholderResults.forEach((stakeholder) => {
            const val = Math.round(
                stakeholder.SPAPriorities.reduce((val1, val2) => {
                    return val1 + applyWeight(val2, "sam",currentWeighingSet);
                }, 0) * 100
            ) / 100;

            legends.push({ text: stakeholder.name, value: val });
            chartData[0].children.push({ name: stakeholder.name, size: val });
        });

        chartData[0].children.sort((a, b) => b.size - a.size);

        const total = legends.reduce((a, b) => a + b.value, 0);
        legends = legends.map((legend) => ({
            ...legend,
            percent: Math.round((legend.value / total) * 100 * 100) / 100,
        }));

        const treemapColors = [treemapRGB[0]].concat(
            getColorsForStakeholders(chartData[0].children.map((item) => item.name))
        );

        setTreemapData(chartData);
    }, [results, currentWeighingSet]);

    const triggerChartUpdate = () => {};

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={0} />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType type='tree' dimension='SPA' chart='B1' />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData}
                                            chartName={'Stakeholder level result - Product System'} chartType='Treemap Chart' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            // setApplyGraphSettings={setApplyGraphSettings}
                                            // triggerChartUpdate={triggerChartUpdate}
                                            chartData = {chartJSData}
                                            chartColorType = 'process'
                                            noColors
                                            graphType='treemap'
                                        />                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec} id="TreemapChart">
                            <RechartTreemap
                                data={treemapData}
                                triggerChartUpdate={triggerChartUpdate}
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B1TreeSPA;
