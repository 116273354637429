/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';
import {
    ClickAwayListener,
    Container,
    FormControlLabel,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    tableCellClasses,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ThemeProvider } from '@emotion/react';
import TableTheme from '../../../../style/components/TableTheme';
import TabContext from '@mui/lab/TabContext';
import ExitButton from '../../../../style/components/ExitButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Exchange, Process } from '../../../../../interface/Process';
import { fetchProcessFlowDetails } from '../../../../../helper/fetchUtil';
import { useForm } from '@tanstack/react-form';
import ProductsSearchBoxTheme from '../../../../mainmenu/components/ProductsSearchBox/ProductsSearchBoxTheme';
import SearchIcon from '@mui/icons-material/Search';
import EnvProcessFlowListSearch from '../EnvProcessFlowListSearch';
import InfiniteScroll from "react-infinite-scroller";
import LoadingSpinner from '../../../../../shared/LoadingSpinner';

interface ProcessFlowDetailsProps {
    processId: string | undefined
    handleEnvProcessFlowClick: () => void
    handleEnvProcessFlowClickOff: () => void
    dimension: string,
}

function ProcessFlowDetails(
    { processId, handleEnvProcessFlowClick, handleEnvProcessFlowClickOff, dimension }: ProcessFlowDetailsProps,
) {
    const [detailsFlowValue, setDetailsFlowValue] = React.useState('details-process');
    const [loadedProcess, setLoadedProcess] = useState<Process>();
    const handleDetailsTabChange = (
        event: React.SyntheticEvent,
        newFlowValue: string,
    ) => {
        setDetailsFlowValue(newFlowValue);
    };

    const [filteredExchanges, setFilteredExchanges] = useState<Exchange[]>([]);


    useEffect(() => {
        if (processId) {
            fetchProcessFlowDetails(processId, dimension).then((val) => {
                setLoadedProcess(val)
                if (val) {
                    setFilteredExchanges(val.exchanges);

                }
            });

        }
    }, [processId])

    useEffect(() => { console.log(loadedProcess) }, [loadedProcess])

    const InputTableCell = styled(TableCell)(({ theme }) => {
        return {
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: '#004021',
                color: 'white',
                fontSize: '0.9vw',
                padding: '1vw',
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: '0.7vw',
                padding: '1vw',
            },
        }
    });

    const InputTableRow = styled(TableRow)(({ theme }) => {
        return {
            // padding: '5px',
            fontSize: '0.8vw',
            color: '#707070',
            '&:nth-of-type(odd)': {
                backgroundColor: '#F3F3F3',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#E6E6E6',
            },
        }
    });

    const ProcessTopWrapper = styled(Box)(({ theme }) => {
        return {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            height: '5vh',
        }
    });

    const SelectProcessPopupParent = styled(Box)(({ theme }) => {
        return {
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            margin: '5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    });

    const SelectProcessPopupContent = styled(Box)(({ theme }) => {
        return {
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
            display: "flex",
            flexDirection: "column",
            gap: "1vw",
        }
    });

    const TabEditProcess = styled(Tab)(({ theme }) => {
        return {
            width: '5vw',
            height: 'fit-content',
            fontSize: '0.8vw',
            padding: '2px',
            boxShadow: '0 0 0',
            textTransform: 'capitalize',
            fontWeight: 'bold',
            fontFamily: '"Montserrat", sans-serif',
            backgroundColor: '#166d46',
            color: 'white',
            margin: '0.5vw',
            minHeight: '3vh',
            minWidth: '5vw',
            '&:hover': {
                backgroundColor: '#D9EFDF',
                color: '#004021',
                transition: '0.3s',
            },
            '&.MuiTab-root.Mui-selected': {
                backgroundColor: '#D9EFDF',
                color: '#004021',
                transition: '0.3s',
            },
        }
    });

    const TabListStyled = styled(TabList)(({ theme }) => {
        return {
            margin: '1rem',
            marginBottom: '0',
            display: 'flex',
            gap: '1vw',
            '& .MuiTabs-indicator': { display: 'none' },
        }
    });


    const [itemsPerPage, setItemsPerPage] = useState(40);

    const [hasMore, setHasMore] = useState(true);
    const [records, setRecords] = useState(itemsPerPage);
    const loadMore = () => {
        console.log("TESTTT");

        if (
            records >=
            filteredExchanges.length
        ) {
            setHasMore(false);
        } else {
            console.log("LOADING MORE");
            setTimeout(() => {
                setRecords(records + itemsPerPage);
            }, 0);
        }
    };

    const showItems = (filteredExchanges: Exchange[]) => {
        return filteredExchanges.filter((exchange, index) => index < records).map((exchange, index) => { // needs to be paged
            return (
                <InputTableRow key={exchange.flowName + "-" + index}>
                    <InputTableCell
                        sx={{
                            width: '12vw',
                        }}
                        component='th' scope='row'>
                        {exchange.flowName + " - " + index}
                    </InputTableCell>
                    {/* <InputTableCell align='center'>
                        {exchange.description}
                    </InputTableCell> */}
                    <InputTableCell
                        sx={{
                            width: '30vw',
                        }}
                        align='center'>
                        {exchange.flowCategory}
                    </InputTableCell>
                    <InputTableCell
                        sx={{
                            width: '10vw',
                        }}
                        align='center'>
                        {exchange.flowType}
                    </InputTableCell>
                    <InputTableCell
                        sx={{
                            width: '10vw',
                        }}
                        align='center'>
                        {exchange.costValue}
                    </InputTableCell>
                    <InputTableCell
                        sx={{
                            width: '10vw',
                        }}
                        align='center'>
                        {exchange.unit.name}
                    </InputTableCell>
                </InputTableRow>
            )
        })

    };
    return (
        <ThemeProvider theme={TableTheme}>
            {/* <ClickAwayListener onClickAway={handleEnvProcessFlowClickOff}> */}
            {loadedProcess && <FormGroup>
                <SelectProcessPopupParent
                    className='select-process-popup__parent'
                    sx={{
                        position: 'fixed',
                    }}
                >
                    <Paper square
                        sx={{
                            maxHeight: '40vw',
                            height: '-webkit-fill-available',
                        }}
                    >
                        <SelectProcessPopupContent className='select-process-popup__content'>
                            <TabContext value={detailsFlowValue}>
                                <Box>
                                    <ProcessTopWrapper>
                                        <ThemeProvider theme={ExitButton}>
                                            <Button
                                                onClick={handleEnvProcessFlowClick}
                                                sx={{ marginRight: '0rem', marginTop: '0rem' }}
                                            >
                                                <ClearIcon />
                                            </Button>
                                        </ThemeProvider>
                                        <TabListStyled
                                            onChange={handleDetailsTabChange}
                                            aria-label='Process and Flow Tabs'
                                        >
                                            <TabEditProcess label='Process' value='details-process' />
                                            <TabEditProcess label='Flow' value='details-flow' />
                                        </TabListStyled>
                                    </ProcessTopWrapper>
                                </Box>
                                <TabPanel value='details-process' sx={{ paddingTop: 0 }}>
                                    <Typography
                                        className='select-process-popup__title'
                                        component='h2'
                                        variant='h2'
                                        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                                    >
                                        Process Details
                                    </Typography>
                                    <TableContainer component={Box}>
                                        <Table sx={{ maxWidth: '70vw', maxHeight: '40vh' }} aria-label='flow table'>
                                            <TableHead>
                                                <TableRow>
                                                    <InputTableCell>Process Name</InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Description
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Cost Value
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Category
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Location
                                                    </InputTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <InputTableRow key={loadedProcess.id}>
                                                    <InputTableCell component='th' scope='row'>
                                                        {loadedProcess.name}
                                                    </InputTableCell>
                                                    <InputTableCell align='center' sx={{ width: '50vw'}}>
                                                        {loadedProcess.description}
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        {loadedProcess.costValue}
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        {loadedProcess.category}

                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        {loadedProcess.location}

                                                    </InputTableCell>
                                                </InputTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel value='details-flow' sx={{ paddingTop: 0 }}>
                                    <Typography
                                        className='select-process-popup__title'
                                        component='h2'
                                        variant='h2'
                                        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                                    >
                                        Flow Details
                                    </Typography>
                                    <ThemeProvider theme={ProductsSearchBoxTheme}>

                                        <EnvProcessFlowListSearch
                                            itemsPerPage = {itemsPerPage}
                                            loadedProcess={loadedProcess}
                                            filteredExchanges={filteredExchanges}
                                            setFilteredExchanges={setFilteredExchanges}
                                            setRecords = {setRecords}
                                        />
                                    </ThemeProvider>

                                    <TableContainer component={Box}>
                                        <Table sx={{ minWidth: '40vw', maxHeight: '40vh' }} aria-label='flow table'>
                                            <TableHead
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <TableRow>
                                                    <InputTableCell
                                                        sx={{
                                                            width: '12vw',
                                                        }}
                                                    >Flow Name</InputTableCell>
                                                    {/* <InputTableCell align='center'>
                                                        Description
                                                    </InputTableCell> */}
                                                    <InputTableCell
                                                        sx={{
                                                            width: '30vw',
                                                        }}
                                                        align='center'>
                                                        Category
                                                    </InputTableCell>
                                                    <InputTableCell
                                                        sx={{
                                                            width: '10vw',
                                                        }}
                                                        align='center'>
                                                        Flow Type
                                                    </InputTableCell>
                                                    <InputTableCell
                                                        sx={{
                                                            width: '10vw',
                                                        }}
                                                        align='center'>
                                                        Cost Value
                                                    </InputTableCell>

                                                    <InputTableCell
                                                        sx={{
                                                            width: '10vw',
                                                        }}
                                                        align='center'>
                                                        Unit
                                                    </InputTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                sx={{
                                                    maxHeight: '48vh',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    height:'48vh',
                                                    overflowY: 'scroll',
                                                }}
                                            >
                                                <InfiniteScroll
                                                    pageStart={0}
                                                    loadMore={loadMore}
                                                    hasMore={hasMore}
                                                    loader={<LoadingSpinner />}
                                                    useWindow={false}
                                                    height='48vh'

                                                >
                                                    {showItems(filteredExchanges)}
                                                </InfiniteScroll>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </TabContext>
                        </SelectProcessPopupContent>
                    </Paper>
                </SelectProcessPopupParent>
            </FormGroup>}
            {/* </ClickAwayListener> */}
        </ThemeProvider >
    );
}

export default ProcessFlowDetails;
