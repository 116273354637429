import React from "react";
import {
    FormControlLabel,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { FormApi } from "@tanstack/react-form";
import { StakeholderIndicators, stakeholders } from "../../../../../../../../interface/Stakeholder";
import useStyles from "./StakeholderIndicatorsForm.styles";

interface StakeholderProps {
  form: FormApi<StakeholderIndicators>;
  selectedStakeholder: string;
}

const StakeholderIndicatorsForm: React.FC<StakeholderProps> = ({ form, selectedStakeholder }) => {
    // Find the selected stakeholder data
    const stakeholder = stakeholders.find(
        (stakeholder) => stakeholder.name === selectedStakeholder
    );
    const classes = useStyles();
    let groupCounter = 0;

    return (
        <div>
            <TableContainer className={classes.TableContainerWrap}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className={classes.InputTableCell}>Subcategory</TableCell>
                            <TableCell align="center" className={classes.InputTableCell}>Cutoff Indicator</TableCell>
                            <TableCell align="center" className={classes.InputTableCell}>Source</TableCell>
                            <TableCell align="center" className={classes.InputTableCell}>Yes</TableCell>
                            <TableCell align="center" className={classes.InputTableCell}>No</TableCell>
                            <TableCell align="center" className={classes.InputTableCell}>No Data</TableCell>
                            <TableCell align="center" className={`${classes.InputTableCell} indicator-head`}
                                sx={{
                                    borderLeft: '0.5vw solid white',
                                }}
                            >Practice Indicators</TableCell>
                            <TableCell align="center" className={`${classes.InputTableCell} indicator-head`}
                            >Practice Yes</TableCell>
                            <TableCell align="center" className={`${classes.InputTableCell} indicator-head`}
                            >Practice No</TableCell>
                            <TableCell align="center" className={`${classes.InputTableCell} indicator-head`}
                            >No Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollableBody}>
                        {stakeholder &&
                            stakeholder.subcategories.map((subcategory, index) => {
                                groupCounter++;

                                // Determine the background color based on the group (odd/even)
                                const isOdd = groupCounter % 2 !== 0;
                                const backgroundColor = isOdd ? '#F3F3F3' : '#E6E6E6';

                                return (
                                    <React.Fragment key={subcategory.name}>
                                        {/* Subcategory Row */}
                                        <TableRow
                                            className={`${classes.InputTableRow} indicator-table`}
                                            style={{ backgroundColor }}
                                        >
                                            <TableCell align="center" rowSpan={subcategory.practiceIndicators.length + 1}
                                                className={classes.InputTableCell}
                                            >
                                                {subcategory.name}
                                            </TableCell>
                                            <TableCell align="center" rowSpan={subcategory.practiceIndicators.length + 1}
                                                className={classes.InputTableCell}
                                            >
                                                {subcategory.cutoffIndicator}
                                            </TableCell>
                                            <TableCell align="center" rowSpan={subcategory.practiceIndicators.length + 1}
                                                className={classes.InputTableCell}
                                            >
                                                {subcategory.source}
                                            </TableCell>

                                            {/* Radio Button for Yes */}
                                            <TableCell align="center" rowSpan={subcategory.practiceIndicators.length + 1}
                                                className={classes.InputTableCell}
                                            >
                                                <form.Field
                                                    name={`${stakeholder.name}:${subcategory.name}:Cutoff`}
                                                    children={(field) => (
                                                        <FormControlLabel
                                                            className="radio-indicator"
                                                            control={
                                                                <Radio
                                                                    id={`${field.name}:Yes`}
                                                                    checked={field.state.value === "Yes"}
                                                                    value={"Yes"}
                                                                    onChange={(e) => {
                                                                        field.handleChange(e.target.value);
                                                                        form.handleSubmit();
                                                                    }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            {/* Radio Button for No */}
                                            <TableCell align="center" rowSpan={subcategory.practiceIndicators.length + 1}
                                                className={classes.InputTableCell}
                                            >
                                                <form.Field
                                                    name={`${stakeholder.name}:${subcategory.name}:Cutoff`}
                                                    children={(field) => (
                                                        <FormControlLabel
                                                            className="radio-indicator"
                                                            control={
                                                                <Radio
                                                                    id={`${field.name}:No`}
                                                                    checked={field.state.value === "No"}
                                                                    value={"No"}
                                                                    onChange={(e) => {
                                                                        field.handleChange(e.target.value);
                                                                        form.handleSubmit();
                                                                    }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            {/* Radio Button for NoData */}
                                            <TableCell align="center" rowSpan={subcategory.practiceIndicators.length + 1}
                                                className={classes.InputTableCell}
                                            >
                                                <form.Field
                                                    name={`${stakeholder.name}:${subcategory.name}:Cutoff`}
                                                    children={(field) => (
                                                        <FormControlLabel
                                                            className="radio-indicator"
                                                            control={
                                                                <Radio
                                                                    id={`${field.name}:NoData`}
                                                                    checked={field.state.value === "NoData"}
                                                                    value={"NoData"}
                                                                    onChange={(e) => {
                                                                        field.handleChange(e.target.value);
                                                                        form.handleSubmit();
                                                                    }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        {/* Practice Indicators */}
                                        {subcategory.practiceIndicators.map((indicator, idx) => (
                                            <TableRow
                                                key={`${stakeholder.name}:${subcategory.name}:${indicator}:Indicator-${idx}`}
                                                className={`${classes.InputTableRow}`}
                                                style={{ backgroundColor }}
                                            >
                                                <TableCell align="center"
                                                    className={classes.InputTableCell}
                                                    sx={{
                                                        borderLeft: '0.5vw solid white',
                                                    }}
                                                >
                                                    {indicator}
                                                </TableCell>

                                                {/* Radio Button for Yes */}
                                                <TableCell align="center"
                                                    className={classes.InputTableCell}
                                                >
                                                    <form.Field
                                                        name={`${stakeholder.name}:${subcategory.name}:${indicator}:Indicator`}
                                                        children={(field) => (
                                                            <FormControlLabel
                                                                className="radio-indicator"
                                                                control={
                                                                    <Radio
                                                                        id={`${field.name}:Yes`}
                                                                        checked={field.state.value === "Yes"}
                                                                        value={"Yes"}
                                                                        onChange={(e) => {
                                                                            field.handleChange(e.target.value);
                                                                            form.handleSubmit();
                                                                        }}
                                                                    />
                                                                }
                                                                label="Yes"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>

                                                {/* Radio Button for No */}
                                                <TableCell align="center"
                                                    className={classes.InputTableCell}
                                                >
                                                    <form.Field
                                                        name={`${stakeholder.name}:${subcategory.name}:${indicator}:Indicator`}
                                                        children={(field) => (
                                                            <FormControlLabel
                                                                className="radio-indicator"
                                                                control={
                                                                    <Radio
                                                                        id={`${field.name}:No`}
                                                                        checked={field.state.value === "No"}
                                                                        value={"No"}
                                                                        onChange={(e) => {
                                                                            field.handleChange(e.target.value);
                                                                            form.handleSubmit();
                                                                        }}
                                                                    />
                                                                }
                                                                label="No"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>

                                                {/* Radio Button for NoData */}
                                                <TableCell align="center"
                                                    className={classes.InputTableCell}
                                                    sx={{
                                                        width: '20rem !important',
                                                    }}
                                                >
                                                    <form.Field
                                                        name={`${stakeholder.name}:${subcategory.name}:${indicator}:Indicator`}
                                                        children={(field) => (
                                                            <FormControlLabel
                                                                className="radio-indicator"
                                                                control={
                                                                    <Radio
                                                                        id={`${field.name}:NoData`}
                                                                        checked={field.state.value === "NoData"}
                                                                        value={"NoData"}
                                                                        onChange={(e) => {
                                                                            field.handleChange(e.target.value);
                                                                            form.handleSubmit();
                                                                        }}
                                                                    />
                                                                }
                                                                label="No Data"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default StakeholderIndicatorsForm;
