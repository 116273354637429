import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useStyles from './D2BarPSILCA.styles';
import { ThemeProvider } from '@emotion/react';
import Dropdown from '../../../../../dropdowns/Dropdown';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { lifeCycleColors, palette1, palette2, palette3, palette4 } from '../../../../../../helper/colors';
import { lifeCycleStages } from '../../../../../../helper/lifeCycleStage';
import { useFormContext } from '../../../../../forms/FormContext';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function D2BarPSILCA(props: ChartData) {
    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [selectedFilterOption, setSelectedFilterOption] = useState<string>('');

    const [results, setResults] = useState<PSILCAResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];

        setResults((calcResults));
        setStakeholderOptions(['All Stakeholders'].concat(calcResults.map((item) => {return item.stakeholder})));
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "LCS Colors": lifeCycleColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["LCS Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
     
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        if (!results.length) return;

        const chartData = { datasets: [], labels: [] };
        const options = deepClone(initialOptions);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        
        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        const extendedPalette = generateExtendedPalette(selectedPalette);

        let stakeholders = [];

        if (selectedStakeholder === 'All Stakeholders') {
            stakeholders = results;
        } else {
            stakeholders = results.filter((item) => item.stakeholder === selectedStakeholder);
        }

        const lifeCycleStages = results[0]?.subcategories[0]?.indicators[0]?.indicatorResults.reduce((stages, lcss) => {
            if (!stages.includes(lcss.lcsName.split(':')[0])) {
                stages.push(lcss.lcsName.split(':')[0]);
            }
            return stages;
        }, []);

        chartData.datasets = lifeCycleStages.map((lcs, index) => {
            let data = [];

            stakeholders.forEach((stakeholder) => stakeholder.subcategories.forEach((subcategory) => subcategory.indicators.forEach((indicator) => {
                indicator.indicatorResults.forEach((lcs2) => {
                    if (lcs2.lcsName.startsWith(lcs)) {
                        lcs2.processResults.processes.forEach((process) => {
                            process.flowResults.forEach((item) => {
                                const flow = data.find((item2) => item2.label === item.flowName);
                                if (flow) {
                                    flow.value += item.sum;
                                } else {
                                    data.push({ label: item.flowName, value: item.sum });
                                }
                            });
                        });
                    }
                });
            })));

            const max = Math.max(...data.map(item => item.value));
            const percentVal = selectedFilterOption === '5% of Maximum Value' ? 5 : selectedFilterOption === '3% of Maximum Value' ? 3 : 1;

            data = data.filter((item) => (item.value / max) * 100 > percentVal);

            chartData.labels = data.map((item) => item.label);

            return {
                label: lcs,
                data: data.map((item) => item.value),
                backgroundColor: extendedPalette[index],
                barPercentage: 1,
                categoryPercentage: 0.85,
            };
        });

        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedFilterOption, selectedStakeholder, results, getGraphColorIndex, formState.colorPalette]);

    const [applyGraphSettings, setApplyGraphSettings] =
    useState<(options: ChartJSOptions, chartData: ChartJSData) =>
         { options: ChartJSOptions; chartData: ChartJSData; }>();    
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if(applyGraphSettings){
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={3} defaultResultIndex={1} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >

                                        <Dropdown
                                            options={stakeholderOptions}
                                            valueSetter={setSelectedStakeholder}
                                            value={selectedStakeholder}

                                        />
                                        <Dropdown
                                            options={['5% of Maximum Value', '3% of Maximum Value', '1% of Maximum Value']}
                                            value={selectedFilterOption}
                                            valueSetter={setSelectedFilterOption}
                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData = {chartJSData} chartName={'Indicator level result - Lifecycle'} chartType='Bar Graph' />
                                        <GraphSetting 
                                            chartOptions={chartOptions} 
                                            setApplyGraphSettings={setApplyGraphSettings} 
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData = {chartJSData}
                                            chartColorType = 'lcs'
                                            graphType='barindicator'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default D2BarPSILCA;
