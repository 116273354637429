import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    '@global': {
        '@keyframes deleteSpinner': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
        },
    },
    ProductListItem: {
        padding: '0.4vw !important',
        height: '4vh !important',
        justifyContent: 'space-between !important',
    },
    deleteLoadingSpinner: {
        animation: 'deleteSpinner 2s linear infinite',
    },
}));

export default useStyles;
