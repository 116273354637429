/* eslint-disable @typescript-eslint/no-throw-literal */
import React from 'react';
import { getAccessTokenFromCookies, getAccessTokenFromSessionStorage } from '../helper/StorageHelper';
import {
    createRoute,
    redirect,
} from '@tanstack/react-router';
import { generateIndexRoute } from './CreateRoute';
import EnvironmentalInputs from '../components/createproduct/EnvironmentalInputs';
import CreateProduct from '../screens/Inputs';
import ProjectDetailsInputs from '../components/createproduct/ProductDetails';
import SocialInputs from '../components/createproduct/SocialInputs';
import EconomicInputs from '../components/createproduct/EconomicInputs';
import SocialSPAInputs from '../components/createproduct/SocialInputs/SocialSPAInputs';
import SocialPSILCAInputs from '../components/createproduct/SocialInputs/SocialPSILCAInputs';


function inputRoutes() {
    const createProductIndexRoute = generateIndexRoute('createproduct');

    const createProductRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromSessionStorage() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return createProductIndexRoute},
        path: '/',
        loader: (context) => {return context},
        component: (context) => {return <CreateProduct />},
    });
    let editProductId : string;
    const editProductRoute = createRoute({
        beforeLoad: async () => {
            if (getAccessTokenFromSessionStorage() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return createProductIndexRoute},
        path: '/edit/$productId',
        loader: (context) => {return context},
        component: (context) => {
            console.log(context);
            return <CreateProduct key = {Math.random()} />},
    });
    const productDetailsEditRoute = createRoute({
        beforeLoad: ({params}) => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
            editProductId = params.productId;
        },
        getParentRoute: () => {return editProductRoute},
        path: '/details',
        component: () => {return <ProjectDetailsInputs editProductId = {editProductId}/>},
    });
    const environmentalInputsEditRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return editProductRoute},
        path: '/environmental',
        component: () => {return <EnvironmentalInputs />},
    });
    const socialInputsEditRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return editProductRoute},
        path: '/social',
        component: () => {return <SocialInputs />},
    });
    const economicInputsEditRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return editProductRoute},
        path: '/economic',
        component: () => {return <EconomicInputs />},
    });

    const productDetailsRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return createProductRoute},
        path: '/details',
        component: () => {return <ProjectDetailsInputs />},
    });
    const environmentalInputsRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return createProductRoute},
        path: '/environmental',
        component: () => {return <EnvironmentalInputs />},
    });
    const socialInputsRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return createProductRoute},
        path: '/social',
        component: () => {return <SocialInputs />},
    });
    const socialSPAInputsRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return socialInputsRoute},
        path: '/spa',
        component: () => {return <SocialSPAInputs />},
    });
    const socialPSILCAInputsRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return socialInputsRoute},
        path: '/psilca',
        component: () => {return <SocialPSILCAInputs />},
    });
    const economicInputsRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return createProductRoute},
        path: '/economic',
        component: () => {return <EconomicInputs />},
    });
    socialInputsRoute.addChildren([socialSPAInputsRoute,socialPSILCAInputsRoute])
    editProductRoute.addChildren([productDetailsEditRoute, environmentalInputsEditRoute, socialInputsEditRoute,economicInputsEditRoute]);
    createProductRoute.addChildren([productDetailsRoute, environmentalInputsRoute, socialInputsRoute,economicInputsRoute]);
    createProductIndexRoute.addChildren([createProductRoute, editProductRoute]);
    return createProductIndexRoute;
}

export default inputRoutes;
